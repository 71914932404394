import * as React from "react";
import { SVGProps } from "react";
const CalendarDayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <path
      fill="#CAC4D0"
      d="M1.563 22.656A2.344 2.344 0 0 0 3.905 25h17.188a2.344 2.344 0 0 0 2.343-2.344V9.375H1.563v13.281Zm3.125-9.375c0-.43.351-.781.78-.781h4.688c.43 0 .781.352.781.781v4.688c0 .43-.351.781-.78.781H5.468a.784.784 0 0 1-.782-.781V13.28ZM21.093 3.125H18.75V.781A.784.784 0 0 0 17.969 0h-1.563a.784.784 0 0 0-.781.781v2.344h-6.25V.781A.784.784 0 0 0 8.594 0H7.03a.784.784 0 0 0-.781.781v2.344H3.906a2.344 2.344 0 0 0-2.344 2.344v2.343h21.875V5.47a2.344 2.344 0 0 0-2.343-2.344Z"
    />
  </svg>
);
export default CalendarDayIcon;
