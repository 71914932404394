import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, BarElement, LinearScale, CategoryScale } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(BarElement);
Chart.register(LinearScale);
Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

interface IBarChartData {
  labels?: string[];
  values: number[];
  colors?: string[];
}

const BarChart = ({labels, values, colors=["#0263ff", "#f97223", "#8e30ff"]} : IBarChartData) => {
  return (
    <Bar
      style={{minHeight: "300px"}}
      data={{
        labels: labels, 
        datasets: [
          {
            label: "",
            data: values,
            backgroundColor: colors,
            barPercentage: 0.35
          }
        ]
      }}
      options={{
        maintainAspectRatio: false,
        layout: { padding: { right: 5 } },
        plugins: {
          datalabels: {
            color: "white",
            anchor: "end",
            align: "end"
          },
          legend: { display: false }
        },
        scales: {
          x: {
            title: { color: "white" },
            border:{dash: [4, 4]},
            grid: {
              color: "white",
              drawTicks: false 
            },
            ticks: {
              color: "white",
              padding: 25,
              autoSkip: false
            }
          },
          y: {
            beginAtZero: true,
            title: { color: "white" },
            border:{ dash: [4, 4] },
            grid: {
              color: "white",
              drawTicks: false
            },
            ticks: {
              color: "white",
              padding: 15,
              maxTicksLimit: 5
            },
            min: 0,
            grace: Math.max(...values) * 0.2
          }
        },
      }}
    />
  );
};

export default BarChart;
