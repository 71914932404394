import React, { ReactNode } from "react";
import Sidebar from "../Sidebar";
import useBoundsUI from "../../../hooks/useBoundsUI";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

const Layout = ({ children }: { children: ReactNode }) => {
  const {
    dashboard: { isMortgageTableLoading },
  } = useBoundsUI();

  const location = useLocation();
  const pathname = location.pathname;
  const tabFromPathname = pathname.split("/dashboard/")[1] || "overview";

  return (
    <main
      className={clsx(
        "w-full flex text-boundsGray-100 bg-boundsPurple-400 font-roboto",
        isMortgageTableLoading || tabFromPathname !== "overview"
          ? "h-screen"
          : "h-full"
      )}
    >
      <Sidebar />
      {children}
    </main>
  );
};

export default Layout;
