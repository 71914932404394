import React from "react";
import BoundsUIProvider from "./BoundsUI.provider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./AuthProvider";

interface ProvidersProps {
  children: React.ReactNode;
}

const Providers: React.FC<ProvidersProps> = ({ children }) => {
  return (
    <AuthProvider>
      <BoundsUIProvider>
        {children}
        <ToastContainer />
      </BoundsUIProvider>
    </AuthProvider>
  );
};

export default Providers;
