import * as React from "react"
import { SVGProps } from "react"
const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#CAC4D0"
      d="m25.158 21.616-4.868-4.868a1.17 1.17 0 0 0-.83-.342h-.796a10.107 10.107 0 0 0 2.148-6.25C20.813 4.546 16.267 0 10.656 0 5.046 0 .5 4.546.5 10.156s4.546 10.156 10.156 10.156c2.359 0 4.527-.8 6.25-2.148v.796c0 .313.122.61.342.83l4.868 4.868a1.167 1.167 0 0 0 1.655 0l1.382-1.382a1.177 1.177 0 0 0 .005-1.66Zm-14.502-5.21a6.246 6.246 0 0 1-6.25-6.25 6.246 6.246 0 0 1 6.25-6.25 6.246 6.246 0 0 1 6.25 6.25 6.246 6.246 0 0 1-6.25 6.25Z"
    />
  </svg>
)
export default SearchIcon
