import clsx from "clsx";
import React from "react";
import SpinnerIcon from "../../icons/Spinner.icon";

const Button = ({
  label,
  onClick,
  variant = "primary",
  type = "button",
  className,
  isLoading,
  ...props
}: {
  type?: "button" | "submit" | "reset" | undefined;
  label: string;
  onClick?: () => void;
  variant?: "primary" | "secondary";
  isLoading?: boolean;
  className?: string;
}) => {
  return (
    <button
      type={type}
      className={clsx(
        variant === "primary" && "bg-boundsGreen-50",
        variant === "secondary" && "bg-boundsGray-500",
        "py-[10px] px-6 min-w-[90px] rounded  justify-center ",
        className
      )}
      disabled={isLoading}
      onClick={onClick}
      {...props}
    >
      {isLoading ? <SpinnerIcon className="animate-spin m-auto" /> : label}
    </button>
  );
};

export default Button;
