import React from 'react';
import Layout from '../../components/molecules/Layout';
import MapView from '../../components/molecules/Map/Map';

const Home = () => {
  return (
    <Layout>
      <MapView />
    </Layout>
  );
};

export default Home;
