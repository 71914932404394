import * as React from "react"
import { SVGProps } from "react"
const UserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#CAC4D0"
        d="M12.5.39C5.81.39.39 5.81.39 12.5c0 6.69 5.42 12.11 12.11 12.11 6.69 0 12.11-5.42 12.11-12.11C24.61 5.81 19.19.39 12.5.39Zm0 4.688a4.297 4.297 0 1 1 0 8.594 4.297 4.297 0 0 1 0-8.594Zm0 16.797a9.357 9.357 0 0 1-7.153-3.33c.918-1.729 2.715-2.92 4.81-2.92.116 0 .234.02.346.054a6.465 6.465 0 0 0 1.997.337 6.44 6.44 0 0 0 1.997-.337c.112-.034.23-.054.347-.054 2.095 0 3.891 1.191 4.81 2.92a9.357 9.357 0 0 1-7.154 3.33Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h25v25H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default UserIcon
