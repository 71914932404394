import React, { ReactNode } from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import CheckIcon from "../../icons/Check.Icon";
import SquareIcon from "../../icons/Square.icon";
import clsx from "clsx";

interface IOption {
  id: number;
  value: string;
  label: ReactNode;
}

interface ICheckboxGroup {
  options: IOption[];
  control: Control<any>;
  name: string;
  setValue: UseFormSetValue<any>;
  showAll?: boolean;
  className?: string;
}

const CheckboxGroup: React.FC<ICheckboxGroup> = ({
  options,
  control,
  name,
  setValue,
  showAll,
  className,
}) => {
  const selectedValues = useWatch({ control, name });

  const allSelected = selectedValues?.length === options.length;

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue(
      name,
      event.target.checked ? options.map((option) => option.value) : []
    );
  };

  return (
    <div className={clsx(className, "flex flex-col gap-6")}>
      {showAll && (
        <label className="flex gap-3 items-center">
          <input
            className="hidden"
            type="checkbox"
            checked={allSelected}
            onChange={handleSelectAllChange}
          />
          <div className="w-[18px]">
            {allSelected ? (
              <CheckIcon width={18} height={18} />
            ) : (
              <SquareIcon width={18} height={18} />
            )}
          </div>
          Filters
        </label>
      )}
      {options.map((option) => (
        <Controller
          key={option.id}
          name={name}
          control={control}
          render={({ field }) => (
            <label className="flex gap-3 items-center">
              <input
                {...field}
                type="checkbox"
                className="hidden"
                value={option.value}
                checked={selectedValues.includes(option.value)}
                onChange={() => {
                  const newValue = selectedValues.includes(option.value)
                    ? selectedValues.filter((v: string) => v !== option.value)
                    : [...selectedValues, option.value];

                  field.onChange(newValue);
                }}
              />
              <div className="w-[18px]">
                {selectedValues.includes(option.value) ? (
                  <CheckIcon width={18} height={18} />
                ) : (
                  <SquareIcon width={18} height={18} />
                )}
              </div>
              {option.label}
            </label>
          )}
        />
      ))}
    </div>
  );
};

export default CheckboxGroup;
