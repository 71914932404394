import React, {Dispatch, SetStateAction, useState} from "react";
import DatePicker from "react-datepicker";
import './DateRangePicker.scss';

interface IDateRangePicker {
  setStartDate: Dispatch<SetStateAction<Date | null >>;
  setEndDate: Dispatch<SetStateAction<Date | null >>;
  minDays?: number;
  errorMsg: string | null;
  startDate?: Date | null;
  endDate?: Date | null;
}

const DateRangePicker: React.FC<IDateRangePicker> = ({setStartDate, setEndDate, minDays = 0, errorMsg, startDate, endDate}) => {
  const today = new Date()

  const [localStartDate, setLocalStartDate] = useState<Date | null>(startDate || null);
  const [localEndDate, setLocalEndDate] = useState<Date | null>(endDate || null);
  const [error, setError] = useState<string | null>();

  const onSearch = () => {
    if(localStartDate && localEndDate){
      if (localEndDate.getTime() <= localStartDate.getTime()) {
        setError("End date must be after start date");
      } else if(localEndDate.getTime() - localStartDate.getTime() < minDays * 24 * 60 * 60 * 1000){
        setError(errorMsg);
      } else {
        setError(null);
        setStartDate(localStartDate);
        setEndDate(localEndDate);
      }
    }
  };
  
  return (
    <div className="left-0 px-4 mt-2 flex flex-col gap-1 w-[160px] xl:w-[210px] 3xl:w-[210px]">
      <p>Start Date</p>
      <DatePicker
        className="px-2 py-1 w-[125px] xl:w-[175px] 3xl:w-[175px] text-black"
        calendarClassName="bg-boundsPurple-500"
        selected={localStartDate}
        onChange={(date) => {
          setLocalStartDate(date);
          setError(null);
        }}
        maxDate={today}
      />
      <p>End Date</p>
      <DatePicker
        className="px-2 py-1 w-[125px] xl:w-[175px] 3xl:w-[175px] text-black"
        calendarClassName="bg-boundsPurple-500"
        selected={localEndDate}
        onChange={(date) => {
          setLocalEndDate(date);
          setError(null);
        }}
        maxDate={today}
      />
      <button
        className="mt-2 mb-4 bg-boundsYellow-50 text-black px-2 rounded-lg min-w-8"
        onClick={onSearch}
      >
        Search
      </button>
      {error && (<div className="px-2 py-1 mb-4 bg-boundsYellow-50 text-black">{error}</div>)}
    </div>
  );
};

export default DateRangePicker;
