import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, LineElement, PointElement, LinearScale, CategoryScale, Filler } from "chart.js";

Chart.register(LineElement);
Chart.register(LinearScale);
Chart.register(CategoryScale);
Chart.register(PointElement);
Chart.register(Filler);

interface IBarChartData {
  labels?: string[];
  values: number[];
}

const LineChart = ({labels, values} : IBarChartData) => {
  return (
    <Line
      style={{minHeight: "400px"}}
      data={{
        labels: labels, 
        datasets: [
          {
            label: "",
            data: values,
            borderColor: "#1278c9",
            backgroundColor: "rgba(18, 120, 201, 0.2)",
            pointBackgroundColor: "white",
            fill: "origin",
            pointStyle: "circle",
            pointRadius: 4,
            pointHoverRadius: 6
          }
        ]
      }}
      options={{
        maintainAspectRatio: false,
        layout: { padding: { right: 5 } },
        plugins: {
          legend: { display: false },
          datalabels: {
            display: false
          }
        },
        scales: {
          x: {
            title: { color: "white" },
            border:{dash: [4, 4]},
            grid: {
              color: "white",
              drawTicks: false 
            },
            ticks: {
              color: "white",
              padding: 25,
              autoSkip: false
            }
          },
          y: {
            beginAtZero: true,
            title: { color: "white" },
            border:{ dash: [4, 4] },
            grid: {
              color: "white",
              drawTicks: false
            },
            ticks: {
              color: "white",
              padding: 15,
              count: 5,
              autoSkip: false
            },
            min: 0,
            grace: Math.max(...values) * 0.2
          }
        },
      }}
    />
  );
};

export default LineChart;
