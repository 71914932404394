import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Point from '@arcgis/core/geometry/Point';
import Query from '@arcgis/core/rest/support/Query';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';

import { useSearchForm } from '../../../../hooks/useSearchForm';
import useBoundsUI from '../../../../hooks/useBoundsUI';
import { ISearchFormValues, ParcelResult } from '../../../../types/search';
import { searchFormDefaultValues, searchInputs } from './SearchInputs';
import { NavigationGroupLayer } from '../../../../layers/Layers';
import Button from '../../../atoms/Button';
import Results from '../../../features/Results';
import SearchSelect from './SearchSelect';
import SearchParcelSelect from './SearchParcelSelect';

const PARCEL_RESULTS_RESPONSE_COUNT = 50;

const targetPoint = new Point({
  longitude: -97.336111,
  latitude: 37.688889,
  spatialReference: { wkid: 4326 },
});

const Search = ({ closeWidget }: { closeWidget: () => void }) => {
  const [parcelResults, setParcelResults] = useState<ParcelResult[]>([])
  const [isLoadingResults, setIsLoadingResults] = useState(false)
  const [hasMaxResults, setHasMaxResults] = useState(false)
  const [queryOffset, setQueryOffset] = useState(0);

  const {
    sidebarMenu: { setIsSubmenuOpen, isSubmenuOpen },
  } = useBoundsUI();
  const { control, handleSubmit, reset: resetForm, setValue } = useForm<ISearchFormValues>({defaultValues: searchFormDefaultValues});
  const { buildWhereClause } = useSearchForm({ control });

  useEffect(() => {
    setIsSubmenuOpen(false);
  }, []);

  const cancelSearch = () => {
    resetForm()
    closeWidget()
  }

  const clearFilters = () => {
    resetForm({...searchFormDefaultValues, county: []})
  }

  const queryParcel = async (newQueryOffset: number, existingResults: ParcelResult[]) => {
    const whereClause = buildWhereClause('', '')

    const parcelQuery = new Query({
      returnGeometry: true,
      returnCentroid: true,
      where: whereClause,
      outFields: [
        'OWNER_NAME',
        'PARCEL_LID',
        'COUNTY',
        'Mortgage',
        'UCC',
        'Acreage',
        'OBJECTID',
        'OWNER_NAME_1',
        'Grantor',
        'SECTION',
        'Township',
        'Range',
        "Min_",
        "Max_"
      ],
      num: PARCEL_RESULTS_RESPONSE_COUNT,
      start: newQueryOffset * PARCEL_RESULTS_RESPONSE_COUNT,
    });

    const parcelLayer = NavigationGroupLayer.findLayerById('Parcellightboxlayer') as FeatureLayer;

    try {
      setIsLoadingResults(true)
      const featureSet = await parcelLayer.queryFeatures(parcelQuery);
      const queryResponse = featureSet.features.map((feature, index) => {
        const fields = feature.attributes;
        const id = existingResults.length + index + 1

        return {
          id,
          parcelLid: fields.PARCEL_LID,
          from: fields.Grantor,
          to: fields.OWNER_NAME,
          description: `STR: ${fields.SECTION || fields.TOWNSHIP || fields.RANGE ?
            `${fields.SECTION ?? ''} ${fields.TOWNSHIP ?? ''} ${fields.RANGE ?? ''}`
            : 'N/A'}`,
          county: fields.COUNTY,
          mortgage: fields.Mortgage ?? null,
          UCC: fields.UCC,
          parcelFeature: feature,
        };
      });

      setHasMaxResults(queryResponse.length < PARCEL_RESULTS_RESPONSE_COUNT)
      setParcelResults([...existingResults, ...queryResponse]);
      setQueryOffset(newQueryOffset);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }

    setIsLoadingResults(false)
  };

  const loadMoreData = () => {
    if (!isLoadingResults && !hasMaxResults) {
      queryParcel(queryOffset + 1, parcelResults)
    }
  }

  const onSubmit = () => {
    setParcelResults([])
    queryParcel(0, []);
    setIsSubmenuOpen(true);
  }

  return (
    <>
      {isSubmenuOpen ? (
        <Results
          data={parcelResults}
          loadMoreData={loadMoreData}
          isLoading={isLoadingResults}
          point={targetPoint}
        />
      ) : (
        <>
          <h1 className='text-[32px]'>Search</h1>
          <span onClick={clearFilters} className='ml-auto text-sm text-boundsYellow-50 cursor-pointer'>
            clear filters
          </span>

          <form className='mt-3 flex flex-col gap-6' onSubmit={handleSubmit(onSubmit)}>
            {searchInputs.slice(0,2).map((searchInput, index) => {
              const showLocationTitle = index === 2
              return (
              <SearchSelect
                key={searchInput.name}
                searchInput={searchInput}
                control={control}
                isSubmitting={isLoadingResults}
                title={showLocationTitle ? 'Location' : undefined}
              />
            )})}
            <SearchParcelSelect
              control={control}
              setValue={setValue}
              isSubmitting={isLoadingResults}
            />
            {searchInputs.slice(2).map((searchInput, index) => {
              const showLocationTitle = index === 0
              return (
              <SearchSelect
                key={searchInput.name}
                searchInput={searchInput}
                control={control}
                isSubmitting={isLoadingResults}
                title={showLocationTitle ? 'Location' : undefined}
              />
            )})}
            <div className='flex gap-7'>
              <Button label={'Cancel'} variant='secondary' onClick={cancelSearch} />
              {isLoadingResults
                ? <Button label={'Results'} onClick={() => setIsSubmenuOpen(true)} />
                : <Button label={'Search'} type={'submit'} isLoading={isLoadingResults} />}
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default Search;
