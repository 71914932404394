import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import GroupLayer from "@arcgis/core/layers/GroupLayer.js";
import TileLayer from "@arcgis/core/layers/TileLayer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import { CityDonutLayer } from "./CityDonutLayer";
import PictureFillSymbol from "@arcgis/core/symbols/PictureFillSymbol";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
import uccPattern from "../assets/images/ucc-pattern.png";
import mortgageReleasePattern from "../assets/images/mortgage-release-pattern.png";
const baseImageryScale = 333500;

const ElevationDEMLayer = new TileLayer({
  id: "ElevationDEMLayer",
  portalItem: {
    id: "3a06a66c270042dc97160bb215fc8ba6",
  },
  visible: false,
});

export const ElevationGroupLayer = new GroupLayer({
  title: "ElevationGroupLayer",
});
ElevationGroupLayer.visibilityMode = "independent";
ElevationGroupLayer.add(ElevationDEMLayer);

const Counties = new FeatureLayer({
  id: "Counties",
  portalItem: {
    id: "31260b24eb1642a29f03e2e118b03921",
  },
  popupEnabled: false,
  maxScale: 333500,
  labelingInfo: [
    {
      symbol: {
        type: "text",
        color: "white",
        haloColor: "black",
        haloSize: 1,
        font: {
          family: "Noto Sans",
          size: 14,
          weight: "bold",
        },
      },
      labelExpressionInfo: {
        expression: "$feature.county_nam",
      },
    },
  ],
});

const PLSSFirstDivision = new FeatureLayer({
  id: "PLSSFirstDivision",
  portalItem: {
    id: "30475e008003462786067e7284334b81",
  },
  popupEnabled: false,
  maxScale: 29122,
  minScale: 120828,
  labelingInfo: [
    {
      symbol: {
        type: "text",
        color: "white",
        haloColor: "black",
        haloSize: 1,
        font: {
          family: "Noto Sans",
          size: 14,
          weight: "bold",
        },
      },
      labelExpressionInfo: {
        expression: "$feature.Label",
      },
    },
  ],
});

const Civil_Divisions = new FeatureLayer({
  id: "Civil_Divisions",
  portalItem: {
    id: "5d65d7c559ca499ab625ba8a7867a974",
  },
  popupEnabled: false,
  maxScale: 120828,
  minScale: 333500,
  labelingInfo: [
    {
      symbol: {
        type: "text",
        color: "white",
        haloColor: "black",
        haloSize: 1,
        font: {
          family: "Noto Sans",
          size: 14,
          weight: "bold",
        },
      },
      labelExpressionInfo: {
        expression: "$feature.minorciv_1",
      },
    },
  ],
});

const ParcelSymbol = new SimpleFillSymbol({
  color: "rgba(0,0,0,0)",
  outline: {
    color: "#F2C94C",
    width: 2,
  },
});

const ParcelRenderer = new SimpleRenderer({
  symbol: ParcelSymbol,
});

export const Parcellightboxlayer = new FeatureLayer({
  id: "Parcellightboxlayer",
  renderer: ParcelRenderer,
  portalItem: {
    id: "3a42499d11314a5aaa43a002746450c0",
  },
  popupEnabled: false,
  minScale: 29122,

  labelingInfo: [
    {
      symbol: {
        type: "text",
        color: "white",
        haloColor: "black",
        haloSize: 1,
        font: {
          family: "Noto Sans",
          size: 14,
          weight: "bold",
        },
      },
      labelExpressionInfo: {
        expression: "$feature.Owner_Name",
      },
    },
  ],
});

export const NavigationGroupLayer = new GroupLayer({
  title: "NavigationGroupLayer",
});
NavigationGroupLayer.visibilityMode = "independent";
NavigationGroupLayer.add(Parcellightboxlayer);
NavigationGroupLayer.add(Civil_Divisions);
NavigationGroupLayer.add(PLSSFirstDivision);
NavigationGroupLayer.add(Counties);

const WetlandsLayer = new FeatureLayer({
  id: "WetlandsLayer",
  minScale: baseImageryScale,
  portalItem: {
    id: "c929e778fef040c8b4afaeb257d6f564",
  },
  visible: false,
});

const FloodwayLayer = new FeatureLayer({
  id: "FloodwayLayer",
  minScale: baseImageryScale,
  portalItem: {
    id: "eda7a398600e43aeb1734a4b80ec4c6a",
  },
  visible: false,
});

const FutureLayer = new FeatureLayer({
  id: "FutureLayer",
  minScale: baseImageryScale,
  portalItem: {
    id: "67ae96f34efe4d4fb48320e9128a7860",
  },
  visible: false,
});

const ReducedRiskLayer = new FeatureLayer({
  id: "ReducedRiskLayer",
  minScale: baseImageryScale,
  portalItem: {
    id: "454bb9a0120f43c487c7734e1e683d57",
  },
  visible: false,
});

const OneAnnualLayer = new FeatureLayer({
  id: "OneAnnualLayer",
  minScale: baseImageryScale,
  portalItem: {
    id: "e0692d9851ab4bd0ab7d80ea542b0ecf",
  },
  visible: false,
});

const PTTwoLayer = new FeatureLayer({
  id: "PTTwoLayer",
  minScale: baseImageryScale,
  portalItem: {
    id: "6a39e8aacbc74447bbcf6f566908b00c",
  },
  visible: false,
});

const ZoneALayer = new FeatureLayer({
  id: "ZoneALayer",
  minScale: baseImageryScale,
  portalItem: {
    id: "69d15d1d70884d76aebd8a9f256db8a7",
  },
  visible: false,
});

const UndeterminedFloodLayer = new FeatureLayer({
  id: "UndeterminedFloodLayer",
  minScale: baseImageryScale,
  portalItem: {
    id: "5aaa1aee96894702bf97abc1d3efd7ae",
  },
  visible: false,
});

export const WaterGroupLayer = new GroupLayer({ title: "WaterGroupLayer" });
WaterGroupLayer.visibilityMode = "independent";
WaterGroupLayer.add(WetlandsLayer);
WaterGroupLayer.add(FloodwayLayer);
WaterGroupLayer.add(FutureLayer);
WaterGroupLayer.add(ReducedRiskLayer);
WaterGroupLayer.add(OneAnnualLayer);
WaterGroupLayer.add(PTTwoLayer);
WaterGroupLayer.add(ZoneALayer);
WaterGroupLayer.add(UndeterminedFloodLayer);

const CDL2023Layer = new TileLayer({
  id: "CDL2023Layer",
  portalItem: {
    id: "bf99eb8a400f4b26947f21221a037613",
  },
  visible: false,
  opacity: 0.5,
});

export const CDLGroupLayer = new GroupLayer({ title: "CDLGroupLayer" });
CDLGroupLayer.visibilityMode = "independent";
CDLGroupLayer.add(CDL2023Layer);

export const CityGroupLayer = new GroupLayer({ title: "CityGroupLayer" });
CityGroupLayer.visibilityMode = "independent";
CityGroupLayer.add(CityDonutLayer);

const WaterWellsLayer = new FeatureLayer({
  id: "WaterWellsLayer",
  minScale: baseImageryScale,
  portalItem: {
    id: "921cf865410e4530becbd5aa6079bad1",
  },
  visible: false,
});
export const WaterWellsGroupLayer = new GroupLayer({
  title: "WaterWellsGroupLayer",
});
WaterWellsGroupLayer.visibilityMode = "independent";
WaterWellsGroupLayer.add(WaterWellsLayer);

const SoilsLayer = new FeatureLayer({
  id: "SoilsLayer",
  minScale: baseImageryScale,
  portalItem: {
    id: "06e5fd61bdb6453fb16534c676e1c9b9",
  },
  visible: false,
});
export const SoilsGroupLayer = new GroupLayer({ title: "SoilsGroupLayer" });
SoilsGroupLayer.visibilityMode = "independent";
SoilsGroupLayer.add(SoilsLayer);

/* unique types */
export const baseSymbol = new SimpleFillSymbol({
  color: "transparent",
  outline: {
    color: "transparent",
    width: 0,
  },
});

const uccSymbol = new PictureFillSymbol({
  url: uccPattern,
  width: 10,
  height: 10,
  outline: {
    color: [0, 0, 0, 0],
    width: 0,
  },
});

export const uccRenderer = new UniqueValueRenderer({
  field: "UCC",
  defaultSymbol: baseSymbol,
  uniqueValueInfos: [
    {
      value: "not-null",
      symbol: uccSymbol,
    },
  ],
  valueExpression: `
  // Expression to check if the UCC value is greater than 1
  // If true, applies 'not-null' and shows the uccSymbol
  // If false, applies 'null' and the feature will not display
  When($feature.UCC > 1, 'not-null', 'null')
`,
});

const mortgageSymbol = new SimpleFillSymbol({
  color: [99, 0, 235, 0.5],
  style: "solid",
  outline: baseSymbol.outline,
});

export const mortgageRenderer = new UniqueValueRenderer({
  field: "Mortgage",
  defaultSymbol: baseSymbol,
  uniqueValueInfos: [
    {
      value: "not-null",
      symbol: mortgageSymbol,
    },
  ],
  valueExpression: `
    When(IsEmpty($feature.Mortgage), 'null', 'not-null')
  `,
});

const mortgageReleaseSymbol = new PictureFillSymbol({
  url: mortgageReleasePattern,
  width: 10,
  height: 10,
  outline: {
    color: [0, 0, 0, 0],
    width: 0,
  },
});

export const mortgageReleaseRenderer = new UniqueValueRenderer({
  field: "MortgageRelease",
  defaultSymbol: baseSymbol,
  uniqueValueInfos: [
    {
      value: "not-null",
      symbol: mortgageReleaseSymbol,
    },
  ],
  valueExpression: `
    When(IsEmpty($feature.MortgageRelease), 'null', 'not-null')
  `,
});

export const mortgageLayer = new FeatureLayer({
  portalItem: {
    id: "3a42499d11314a5aaa43a002746450c0",
  },
  popupEnabled: false,
  minScale: 29122,
  renderer: mortgageRenderer,
  blendMode: "normal",
});

export const uccLayer = new FeatureLayer({
  portalItem: {
    id: "3a42499d11314a5aaa43a002746450c0",
  },
  popupEnabled: false,
  minScale: 29122,
  renderer: uccRenderer,
  blendMode: "normal",
});

export const mortgageReleaseLayer = new FeatureLayer({
  portalItem: {
    id: "3a42499d11314a5aaa43a002746450c0",
  },
  popupEnabled: false,
  minScale: 29122,
  renderer: mortgageReleaseRenderer,
  blendMode: "normal",
});

export const FinanceGroupLayer = new GroupLayer({ title: "FinanceGroupLayer" });
FinanceGroupLayer.visibilityMode = "independent";
FinanceGroupLayer.add(mortgageReleaseLayer);
FinanceGroupLayer.add(mortgageLayer);
FinanceGroupLayer.add(uccLayer);
