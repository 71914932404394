import React, { useEffect, useState, useCallback } from "react";
import CenterIcon from "../../../icons/Center.icon";
import MapView from "@arcgis/core/views/MapView";
import Point from "@arcgis/core/geometry/Point";
import { ToastOptions, toast } from "react-toastify";
import { ToastSettings } from "../../../../utils/toast";

const CenterMapControl = ({ view }: { view: MapView }) => {
  const [locationPermission, setLocationPermission] = useState<PermissionState | null>(null);
  const [userLocation, setUserLocation] = useState<Point | null>(null);

  useEffect(() => {
    checkLocationPermission();
  }, []);

  const checkLocationPermission = useCallback(async () => {
    if (!navigator.geolocation) return;

    try {
      const result = await navigator.permissions.query({ name: "geolocation" });
      setLocationPermission(result.state);

      if (result.state === "granted" || result.state === "prompt") {
        getUserLocation();
      }
    } catch (error) {
      console.error("Error checking location permission:", error);
    }
  }, []);

  const getUserLocation = useCallback(() => {
    if (!navigator.geolocation) return;

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const point = new Point({ latitude, longitude });
        setUserLocation(point);
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          setLocationPermission("denied");
        } else {
          console.error("Error getting user location:", error);
        }
      }
    );
  }, []);

  const centerMap = () => {
    if (locationPermission === "denied") {
      toast.error(
        "Please enable location services in your browser to center map on your location.",
        ToastSettings as ToastOptions
      );
    } else if (view) {
      if (!userLocation) {
        getUserLocation();
      }
      view.goTo({ target: userLocation, zoom: 15 }).catch((err) => console.error("Error centering map:", err));
    }
  };

  if (!navigator.geolocation) return null;

  return (
    <div className="flex items-center bg-boundsPurple-700 p-3 rounded-[5px] mx-auto shadow-xl">
      <button className="flex m-auto" onClick={centerMap} title="Center map on user location">
        <CenterIcon width={24} />
      </button>
    </div>
  );
};

export default CenterMapControl;
