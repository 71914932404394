import React from "react";

type PercentageTextProps = React.PropsWithChildren<{
	color: string;
	percentage: number | string;
}>;

const PercentageText = ({
	color,
	percentage,
	children,
}: PercentageTextProps) => {
	return (
		<section className="flex justify-between m-1 p-1">
			<div className="flex items-center gap-2 pr-2">
				<div
					className={`h-[10px] w-[10px] border border-white`}
					style={{ backgroundColor: color }}
				/>
				<p className="text-white">{children}</p>
			</div>
			<p className="text-white">{percentage}%</p>
		</section>
	);
};

export default PercentageText;
