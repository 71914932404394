import React, { FC, useState } from "react";
import { BoundsUIContext, IGroup, IParcel } from "../contexts/BoundsUI.context";
import Point from "@arcgis/core/geometry/Point";
import MapView from "@arcgis/core/views/MapView";
import { DataOption } from "../types/insights";

interface IBoundsUIProvider {
  children: React.ReactNode;
}

const BoundsUIProvider: FC<IBoundsUIProvider> = ({ children }) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [group, setGroup] = useState<IGroup | null>(null);
  const [widgetType, setWidgetType] = useState("");
  const [point, setPoint] = useState<Point | null>(null);
  const [view, setView] = useState<MapView | null>(null);
  const [open, setOpen] = useState(false);

  const [parcels, setParcels] = useState<IParcel[]>([]);
  const [insightType, setInsightType] = useState<DataOption | null>(null);
  const [data, setData] = useState<any[] | null>(null);
  const [insightError, setInsightError] = useState();

  /* UI */
  const [isMortgageTableLoading, setIsMortgageTableLoading] =
    useState<boolean>(true);

  return (
    <BoundsUIContext.Provider
      value={{
        sidebarMenu: {
          isSubmenuOpen,
          setIsSubmenuOpen,
          widgetType,
          setWidgetType,
          open,
          setOpen,
        },
        featureLayer: { group, setGroup },
        searchResults: {
          setPoint,
          point,
        },

        mainMapView: {
          view,
          setView,
        },

        selection: {
          parcels,
          setParcels,
        },
        insightData: {
          insightType,
          setInsightType,
          data,
          setData,
          insightError,
          setInsightError,
        },

        /* Dashboard */
        dashboard: {
          isMortgageTableLoading,
          setIsMortgageTableLoading,
        },
      }}
    >
      {children}
    </BoundsUIContext.Provider>
  );
};

export default BoundsUIProvider;
