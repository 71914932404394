import React, { useEffect, useState } from "react";
import Card from "../../../atoms/Card";
import { IDashboardFilters } from "../../../../types/financialOverview";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'; 

const DashboardCard = ({
  title, icon, fetchFunction, fetchParams, valueField, percentField, timePeriod, valueFormat, callback, tooltipcontent
} : {
  title: string;
  icon?: any;
  fetchFunction: (params: IDashboardFilters, abortSignal: AbortSignal) => Promise<any>;
  fetchParams: any;
  valueField: string;
  percentField: string;
  timePeriod?: string;
  valueFormat?: (val: number) => string;
  callback?: (val: number) => void;
  tooltipcontent?: (val: number) => string;
}) => {
  const abortController = new AbortController();
  const signal = abortController.signal;

  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState<number>(0);
  const [percentage, setPercentage] = useState<number>(0);
  const [error, setError] = useState("");

  useEffect(() => {
    setIsLoading(true);
    const loadData = (params: any) => {
      if(params && params.start_date && params.end_date){
        fetchFunction(params, signal)
        .then((result: any) => {
          if(!signal.aborted){
            if(result){
              try {
                setValue(result[valueField]);
                setPercentage(result[percentField]);
                setError("");
                if(callback){
                  callback(result[valueField]);
                }
              } catch {
                setError("Could not retrieve data.");
              } finally {
                setIsLoading(false);
              }
            } else {
              setIsLoading(false);
              setError("Could not retrieve data.");
            }
          }
        })
      }
    }
    loadData(fetchParams)
    return () => { abortController.abort(); }
  }, [fetchParams])
  
  return (
    <div style={{ position: 'relative'}}>
      <Card
        title={title}
        value={valueFormat ? valueFormat(value) : value}
        Icon={icon}
        percentage={Math.round(percentage)}
        comparisonPeriod={timePeriod?.toLowerCase() || "period"}
        isLoading={isLoading}
        errorMessage={error}
        tooltipContent={tooltipcontent ? tooltipcontent(value):''}
      />
    </div>
  );
};

export default DashboardCard;
