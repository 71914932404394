import * as React from "react";
import { SVGProps } from "react";
const LandMarkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#CAC4D0"
        d="M24.493 4.498 13.05.1a1.56 1.56 0 0 0-1.097 0L.507 4.498A.781.781 0 0 0 0 5.229v1.802c0 .432.35.782.781.782H24.22c.431 0 .781-.35.781-.782V5.23a.781.781 0 0 0-.507-.731ZM3.125 9.375v7.813h-.781a.781.781 0 0 0-.781.78v2.345h21.875v-2.344a.781.781 0 0 0-.782-.782h-.781V9.375H18.75v7.813h-4.688V9.375h-3.124v7.813H6.25V9.375H3.125Zm21.094 12.5H.78a.781.781 0 0 0-.781.781v1.563c0 .431.35.781.781.781H24.22c.431 0 .781-.35.781-.781v-1.563a.781.781 0 0 0-.781-.781Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h25v25H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default LandMarkIcon;
