import * as React from "react";
import { SVGProps } from "react";
const ChartLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="#CAC4D0"
      d="M23.25 18H3V3.75A.75.75 0 0 0 2.25 3H.75a.75.75 0 0 0-.75.75V19.5A1.5 1.5 0 0 0 1.5 21h21.75a.75.75 0 0 0 .75-.75v-1.5a.75.75 0 0 0-.75-.75Zm-1.5-13.5h-5.534c-1.002 0-1.504 1.212-.796 1.92l1.52 1.52-3.44 3.439-3.44-3.44a1.5 1.5 0 0 0-2.12 0l-3.22 3.22a.75.75 0 0 0 0 1.061l1.06 1.06a.75.75 0 0 0 1.06 0L9 11.122l3.44 3.44a1.5 1.5 0 0 0 2.12 0l4.5-4.5 1.52 1.518c.708.709 1.92.207 1.92-.795V5.25a.749.749 0 0 0-.75-.75Z"
    />
  </svg>
);
export default ChartLine;
