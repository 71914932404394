import { useEffect, RefObject } from "react";

const useTableInfiniteScroll = (tableRef?: RefObject<HTMLTableElement> | null, onInfiniteScroll?: () => void) => {
  const observer = new IntersectionObserver((trackedEntries) => {
    const [trackedEntry] = trackedEntries;
    if (trackedEntry.isIntersecting && onInfiniteScroll) {
      onInfiniteScroll();
    }
  }, {
    threshold: 1,
  })

  useEffect(() => {
    const lastTableElement = tableRef?.current?.lastElementChild;
    const lastTableRowElement = lastTableElement?.lastElementChild;
    if (lastTableRowElement) observer.observe(lastTableRowElement)

    return () => {
      if (lastTableRowElement) observer.unobserve(lastTableRowElement)
    }
  }, [tableRef, onInfiniteScroll]);
}

export default useTableInfiniteScroll;