import React, { ReactElement } from "react";
import { Control, Controller } from "react-hook-form";
import clsx from "clsx";
import Select, { Props as SelectProps } from "react-select";
import { GroupBase } from "react-select";
import { IOption } from "../../../types/search";
import CheckboxOption from "./CheckboxOption";
import getStylesConfig from "./SelectStyles";

interface CustomProps
  extends SelectProps<IOption, boolean, GroupBase<IOption>> {
  control: Control<any, any, any>;
  label: string;
  name: string;
  variant?: "primary" | "secondary";
  errorMessage?: string;
}

const SelectComponent = React.forwardRef<Select, CustomProps>(
  (props, ref): ReactElement => {
    const {
      control,
      label,
      name,
      variant = "primary",
      errorMessage,
      ...rest
    } = props;

    return (
      <>
        {" "}
        <div
          className={clsx(
            "min-w-32 xl:min-w-40",
            "rounded-t-md py-2 px-4 border-b relative",
            variant === "primary" &&
              "bg-boundsPurple-150 border-boundsYellow-50",
            variant === "secondary" && "bg-boundsGray-100 border-white",
            props.isDisabled && "opacity-50",
            props.className
          )}
        >
          <label
            htmlFor={name}
            className={clsx("text-xs", variant === "secondary" && "text-black")}
          >
            {label}
          </label>
          <Controller
            name={name}
            control={control}
            defaultValue={props.defaultValue}
            render={({ field }) => (
              <Select
                className="select-container"
                components={{ Option: CheckboxOption }}
                styles={getStylesConfig(variant)}
                {...field}
                {...rest}
                ref={field.ref}
                onChange={(value) => field.onChange(value)}
                onBlur={field.onBlur}
              />
            )}
          />
        </div>
        {errorMessage && (
          <span className="font-light text-[12px] text-red-500">
            {errorMessage}
          </span>
        )}
      </>
    );
  }
);

SelectComponent.displayName = "SelectComponent";

export default SelectComponent;
