import * as React from "react";
import { SVGProps } from "react";
const PieChartIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		fill="none"
		{...props}
	>
		<g clipPath="url(#a)">
			<path
				fill="#CAC4D0"
				d="M11 2v20c-5.07-.5-9-4.79-9-10s3.93-9.5 9-10Zm2.03 0v8.99H22c-.47-4.74-4.24-8.52-8.97-8.99Zm0 11.01V22c4.74-.47 8.5-4.25 8.97-8.99h-8.97Z"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h24v24H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default PieChartIcon;
