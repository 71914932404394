import clsx from "clsx";
import React, { useEffect, useState } from "react";
import Skeleton from "../../atoms/Skeleton";

const Pagination = ({
  totalCount,
  pageSize,
  actualPage = 1,
  onPageChange,
  isLoading = false,
}: {
  actualPage?: number;
  totalCount: number;
  pageSize: number;
  onPageChange: (pageNumber: number) => void;
  isLoading?: boolean;
}) => {
  const [currentPage, setCurrentPage] = useState(actualPage);

  const totalPages = Math.ceil(totalCount / pageSize);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    onPageChange(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [totalCount])

  const getPageNumbers = () => {
    const pageNumbers = [];
    const visiblePages = 2; // Number of visible pages before and after the current page
    const showEllipsis = totalPages > visiblePages * 2 + 3;

    if (showEllipsis) {
      pageNumbers.push(1);
      if (currentPage > visiblePages + 2) {
        pageNumbers.push("...");
      }
      for (
        let i = Math.max(2, currentPage - visiblePages);
        i <= Math.min(totalPages - 1, currentPage + visiblePages);
        i++
      ) {
        pageNumbers.push(i);
      }
      if (currentPage < totalPages - visiblePages - 1) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages);
    } else {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };

  const renderPageNumbers = () => {
    return getPageNumbers().map((pageNumber, index) =>
      pageNumber === "..." ? (
        <span key={index} className="ellipsis">
          ...
        </span>
      ) : (
        <button
          className={clsx(
            " py-1 px-2 rounded-lg min-w-8 ",
            currentPage === pageNumber
              ? "bg-boundsYellow-50 text-black"
              : "bg-boundsPurple-400 text-white"
          )}
          key={index}
          onClick={() => handlePageChange(pageNumber as number)}
        >
          {pageNumber}
        </button>
      )
    );
  };

  return (
    <div className="mx-auto w-fit my-5 bg-boundsPurple-500 px-3 py-2 rounded-md font-bold text-sm ">
      <div className="flex gap-2">
        <button
          className="bg-boundsPurple-400 py-1 px-2 rounded-lg text-white "
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1 || isLoading}
        >
          &lt;
        </button>
        {isLoading
          ? Array.from({ length: 3 }).map((_, index) => (
              <Skeleton
                key={`logo-skeleton-${index}`}
                width={32}
                height={28}
                borderRadius={6}
                className="!bg-zinc-500"
              />
            ))
          : renderPageNumbers()}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages || isLoading}
          className="bg-boundsPurple-400 py-1 px-2 rounded-lg text-white"
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Pagination;
