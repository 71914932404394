import * as React from "react"
import { SVGProps } from "react"
const DashboardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#CAC4D0"
      d="M10.266.723H2.308c-.599 0-1.085.486-1.085 1.085v7.958c0 .599.486 1.085 1.085 1.085h7.958c.599 0 1.085-.486 1.085-1.085V1.808c0-.599-.486-1.085-1.085-1.085ZM23.692.723h-7.958c-.599 0-1.085.486-1.085 1.085v7.958c0 .599.486 1.085 1.085 1.085h7.958c.599 0 1.085-.486 1.085-1.085V1.808c0-.599-.486-1.085-1.085-1.085ZM23.692 14.15h-7.958c-.599 0-1.085.485-1.085 1.085v7.957c0 .599.486 1.085 1.085 1.085h7.958c.599 0 1.085-.486 1.085-1.085v-7.957c0-.6-.486-1.086-1.085-1.086ZM10.266 14.15H2.308c-.599 0-1.085.485-1.085 1.085v7.957c0 .599.486 1.085 1.085 1.085h7.958c.599 0 1.085-.486 1.085-1.085v-7.957c0-.6-.486-1.086-1.085-1.086Z"
    />
  </svg>
)
export default DashboardIcon
