import { useContext } from "react";
import { BoundsUIContext } from "../contexts/BoundsUI.context";

// Custom hook to use the BoundsUIContext
const useBoundsUI = () => {
	const context = useContext(BoundsUIContext);

	if (context === undefined) {
		throw new Error("useBoundsUI must be used within a BoundsUIProvider");
	}

	// Destructuring to get search object from the context
	//const { sidebarMenu } = context;

	// Returning the search object directly, which contains isOpen and setIsOpen
	return context;
};

export default useBoundsUI;
