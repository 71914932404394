import * as yup from "yup";
export const mortgageFormSchema = yup.object().shape({
  isRelease: yup.boolean(),
  grantor_1: yup.string().required(),
  grantor_2: yup.string(),
  grantee_1: yup.string().required(),
  grantee_2: yup.string(),
  grantee_3: yup.string(),
  dataType: yup.string(),
  date: yup.string().required(),
  region: yup.string(),
  county: yup.object().shape({
    label: yup.string().nullable(),
    value: yup.string().nullable(),
  }),
  parcelDescription: yup.string(),
  quarterDescription: yup.string(),
  section: yup.string(),
  township: yup.string(),
  range: yup.string(),
  mortgageAmount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable(),
  pageNumber: yup.string(),
  ownerAddressLine1: yup.string(),
  ownerAddressLine2: yup.string(),
  ownerZip: yup.string(),
  mortgageCity: yup.string(),
  notes: yup.string(),
  responsible: yup.string(),
});
