import * as React from "react";
import { SVGProps } from "react";
const ArrowDropDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={5}
    fill="none"
    {...props}
  >
    <path fill="#CAC4D0" d="m0 0 5 5 5-5H0Z" />
  </svg>
);
export default ArrowDropDownIcon;
