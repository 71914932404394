import React from "react";
import LogoIcon from "../../icons/Logo.icon";
import SearchIcon from "../../icons/Search.icon";
import { Link, useNavigate } from "react-router-dom";
import ChartIcon from "../../icons/Chart.icon";
import DashboardIcon from "../../icons/Dashboard.icon";
import UserIcon from "../../icons/User.icon";
import CloseIcon from "../../icons/Close.icon";
import Search from "./Search";
import Insights from "./Insights";
import ArrowLeft from "../../icons/ArrowLeft.icon";
import useBoundsUI from "../../../hooks/useBoundsUI";
import clsx from "clsx";
import { useAuth } from "../../../hooks/useAuth";
import Button from "../../atoms/Button";

const menu = [
  { label: "Search", widget: "search", Icon: SearchIcon },
  { label: "Insights", widget: "insights", Icon: ChartIcon },
  { label: "Dashboard", widget: "dashboard", Icon: DashboardIcon },
];

const Sidebar = () => {
  const navigate = useNavigate();
  const {
    sidebarMenu: {
      setIsSubmenuOpen,
      isSubmenuOpen,
      setWidgetType,
      widgetType,
      open,
      setOpen,
    },
  } = useBoundsUI();
  const { isAuthenticated, logout } = useAuth();

  const handleDashboard = () => {
    setOpen(false);
    navigate("/dashboard/overview");
    setWidgetType("dashboard");
  };

  const handleWidget = (newWidgetType: string) => {
    if (widgetType === newWidgetType) {
      setOpen((oldState) => !oldState);
    }
    setWidgetType(newWidgetType);
    navigate(`/`);
  };

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  return (
    <nav className="h-screen fixed z-10">
      <div className="w-[160px] bg-boundsPurple-400 px-6 py-7 flex flex-col justify-between h-full overflow-y-auto">
        <div className="flex flex-col ">
          <a href="/"> 
            <LogoIcon className="mb-5" />
          </a>
          {menu.map(({ label, widget, Icon }, index) => (
            <div key={`menu-item_${index}`}>
              <div
                className="flex flex-col justify-center items-center gap-4 py-7 cursor-pointer rounded-[5px] hover:bg-boundsPurple-300"
                onClick={() =>
                  label === "Dashboard"
                    ? handleDashboard()
                    : handleWidget(widget)
                }
              >
                <Icon width={25} height={25} />
                <span>{label}</span>
              </div>
              <hr className="my-5  border-[#F2C94C]" />
            </div>
          ))}
        </div>
        {!isAuthenticated ? (
          <Link
            to="/login"
            className="flex flex-col justify-center items-center gap-4 py-7 cursor-pointer rounded-[5px] hover:bg-boundsPurple-300"
          >
            <UserIcon />
            <span>Login</span>
          </Link>
        ) : (
          <Button
            label="Logout"
            variant="secondary"
            className="w-full"
            type="button"
            onClick={handleLogout}
          />
        )}

        {(!!open || widgetType.includes("dashboard")) && (
          <hr className="absolute z-20 left-[160px] top-6 border-r-[1px] border-boundsYellow-50 h-[95%]" />
        )}
      </div>

      <div
        className={clsx(
          "bg-boundsPurple-400 w-auto flex flex-col py-7 px-6 absolute h-full overflow-y-auto",
          open
            ? "fixed top-0 h-screen transition-transform duration-300 transform left-full z-[-1]"
            : "absolute transition-transform duration-300 -translate-x-full "
        )}
      >
        <div className="flex flex-col">
          <div
            className={clsx(
              "flex  items-center mb-6",
              isSubmenuOpen ? "justify-between" : "justify-end"
            )}
          >
            {isSubmenuOpen && (
              <ArrowLeft
                onClick={() => setIsSubmenuOpen(false)}
                className="cursor-pointer"
              />
            )}
            <CloseIcon
              width={24}
              height={24}
              className={"cursor-pointer"}
              onClick={() => setOpen(false)}
            />
          </div>
          {widgetType === "search" && (
            <Search closeWidget={() => setOpen(false)} />
          )}
          {widgetType === "insights" && <Insights />}
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
