import { IOption } from "../types/search";

export const getDateOnly = (date: Date) => {
	const userTimezoneOffset = date.getTimezoneOffset() * 60000;
	const dateWithoutTZ = new Date(date.getTime() + userTimezoneOffset);
	return dateWithoutTZ.toISOString().split("T")[0];
}

export const generateTimeOptions = (): IOption[] => {
	const today = new Date();
	const userTimezoneOffset = today.getTimezoneOffset() * 60000;
	today.setTime(today.getTime() - userTimezoneOffset);
	const monthDate = new Date(today.getFullYear(), today.getMonth(), 1);
	const yearDate = new Date(today.getFullYear(), 0, 1);
	const quarterDate = new Date(today.getFullYear(), Math.floor(today.getMonth()/3)*3, 1);
	return [
		{
			label: "This Month",
			value: monthDate.toISOString()
		},
		{
			label: "This Year",
			value: yearDate.toISOString()
		},
		{
			label: "This Quarter",
			value: quarterDate.toISOString()
		},
	];
};

export const generateMortgageHolderOptions = (institutions: string[]): IOption[] => {
	const result: IOption[] = [];
	institutions.forEach((institution) => {
		if(institution && institution !== "null"){
			result.push({
				label: institution,
				value: institution,
			})
		}
	});
	return result.sort((a, b) => {return a.label.localeCompare(b.label)});
};

export const generateCountyOptions = (counties: string[]): IOption[] => {
	const result: IOption[] = [];
	counties.forEach((county) => {
		if(county && county !== "null"){
			result.push({
				label: county.charAt(0).toUpperCase() + county.slice(1).toLowerCase() + " County",
				value: county,
			})
		}
	});
	return result.sort((a, b) => {return a.label.localeCompare(b.label)});
};
