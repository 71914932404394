import * as React from "react";
import { SVGProps } from "react";
const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="#CAC4D0"
      d="m23.666 2.683-2.35-2.35L12 9.65 2.683.333l-2.35 2.35L9.65 12 .333 21.317l2.35 2.35L12 14.35l9.316 9.317 2.35-2.35L14.35 12l9.316-9.317Z"
    />
  </svg>
);
export default CloseIcon;
