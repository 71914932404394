import React, { useCallback, useState } from "react";
import CloseIcon from "../../../icons/Close.icon";
import ArrowDropDownIcon from "../../../icons/ArrowDropDown";
import useBoundsUI from "../../../../hooks/useBoundsUI";
import { IParcel } from "../../../../contexts/BoundsUI.context";
import MapView from "@arcgis/core/views/MapView";
import { updateLabelGraphics } from "../../../../utils/map";
import { zoomToParcel, zoomToCounty } from "../../../../utils/map";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";
import Modal from "../../../molecules/Modal";
import MortgageForm from "../../MortgageForm";
import { setSelectedParcelLId } from "../../../../redux/actions";
import { useDispatch } from "react-redux";
import { formatDate } from "../../../../utils/date";

interface ICardSelection extends IParcel {
  index: number;
  view: MapView;
}

const CardSelection = ({
  index,
  attributes,
  graphic,
  view,
  label,
}: ICardSelection) => {
  const [seeMore, setSeeMore] = useState(false);
  /* Modal */
  const [isOpen, setIsOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const dispatch = useDispatch();

  const {
    selection: { setParcels },
  } = useBoundsUI();

  const { isMarketIntelAdmin, isMarketIntelOps } = useAuth();

  const deleteSection = useCallback(() => {
    setParcels((prevParcels) => {
      const updatedParcels = prevParcels.filter(
        (parcel) => parcel.attributes.OBJECTID !== attributes.OBJECTID
      );

      if (view && graphic && label.graphic) {
        view.graphics.remove(graphic);
        view.graphics.remove(label.graphic);
      }

      updateLabelGraphics(updatedParcels, view);

      return updatedParcels;
    });

    dispatch(setSelectedParcelLId(null));
  }, [view, graphic, label.graphic, attributes.OBJECTID, setParcels]);

  const handleParcelClick = useCallback(() => {
    if (view && attributes.PARCEL_LID) {
      zoomToParcel(attributes.PARCEL_LID, view);
    }
  }, [view, attributes.PARCEL_APN]);

  const handleCountyClick = useCallback(() => {
    if (view && attributes.PARCEL_LID) {
      zoomToCounty(attributes.PARCEL_LID, view);
    }
  }, [view, attributes.COUNTY]);

  const handleIntelButtonClick = () => {
    setIsOpen(true);
  };

  return (
    <div className="rounded-md bg-boundsPurple-100 p-3 font-roboto font-medium">
      <div className="flex  justify-between">
        <p className="text-sm flex items-center gap-3 mb-3">
          <span className="rounded-full w-6 h-6 bg-boundsPurple-200 flex justify-center items-center font-medium">
            {index + 1}
          </span>
          {attributes.UCC ? (
            <Link
              to={
                "/dashboard/ucc?category=debtors&search=" +
                attributes.OWNER_NAME_1
              }
              className="rounded-full w-6 h-6 bg-boundsOrange-50 flex justify-center items-center font-medium"
            >
              {attributes.UCC || 0}
            </Link>
          ) : (
            <span className="rounded-full w-6 h-6 bg-boundsOrange-50 flex justify-center items-center font-medium">
              {0}
            </span>
          )}
          {attributes?.ACREAGE?.toFixed(2)} acres
        </p>
        <CloseIcon
          width={14}
          height={14}
          cursor={"pointer"}
          onClick={deleteSection}
        />
      </div>
      <p className="font-thin">
        Owner:{" "}
        <span className="font-medium">
          {attributes.OWNER_NAME_1 ? attributes.OWNER_NAME_1 : "No Data"}
        </span>
        <span className="block w-4/5 border-t border-boundsYellow-50 my-1 m-auto "></span>
      </p>
      <p className="font-thin">
        Parcel APN:{" "}
        <span
          className="font-medium cursor-pointer text-boundsYellow-50"
          onClick={handleParcelClick}
        >
          {attributes.PARCEL_APN || "No Data"}
        </span>
        <span className="block w-4/5 border-t border-boundsYellow-50 my-1 m-auto"></span>
      </p>
      <p className="font-thin">
        County:{" "}
        <span
          className="font-medium cursor-pointer text-boundsYellow-50"
          onClick={handleCountyClick}
        >
          {attributes.COUNTY || "No Data"}
        </span>
        <span className="block w-4/5 border-t border-boundsYellow-50 my-1 m-auto"></span>
      </p>
      {attributes.Mortgage ? (
        <p className="font-thin">
          Mortgage:{" "}
          <span className="font-medium">
            {"$" + attributes.mortgage_amount + ".00"}
          </span>
          <span className="block w-4/5 border-t border-boundsYellow-50 my-1 m-auto"></span>
        </p>
      ) : (
        ""
      )}
      {attributes.loan_institution ? (
        <p className="font-thin">
          Grantor: <span className="font-medium">{attributes.loan_institution}</span>
          <span className="block w-4/5 border-t border-boundsYellow-50 my-1 m-auto"></span>
        </p>
      ) : (
        ""
      )}

      {(isMarketIntelAdmin() || isMarketIntelOps()) && (
        <>
          <p className="font-thin">
            Parcel Lid:{" "}
            <span className="font-medium">
              {attributes.PARCEL_LID || "No Data"}
            </span>
          </p>
          <p className="font-thin">
            County:{" "}
            <span className="font-medium">
              {attributes.COUNTY || "No Data"}
            </span>
          </p>
          <p className="font-thin">
            Section:{" "}
            <span className="font-medium">
              {attributes.SECTION || "No Data"}
            </span>
          </p>
          <p className="font-thin">
            Township:{" "}
            <span className="font-medium">
              {attributes.TOWNSHIP || "No Data"}
            </span>
          </p>
          <p className="font-thin">
            Range:{" "}
            <span className="font-medium">{attributes.RANGE || "No Data"}</span>
          </p>
          {attributes.Mortgage && (
            <p className="font-thin">
              Mortgage Date:{" "}
              <span className="font-medium">
                {formatDate(attributes.mortgage_date as string) || "No Data"}
              </span>
            </p>
          )}

          <p className="font-thin">
            Full Description:{" "}
            <span className="font-medium">
              {attributes.Legal_Full || "No Data"}
            </span>
          </p>
        </>
      )}

      {seeMore ? (
        <div>
          {attributes.total_mortgage_amount === 0 ? (
            ""
          ) : attributes.total_mortgage_amount ? (
            <p className="font-thin">
              Mortgages Total:{" "}
              <span className="font-medium">
                ${attributes.total_mortgage_amount}.00
              </span>
              <span className="block w-4/5 border-t border-boundsYellow-50 my-1 m-auto"></span>
            </p>
          ) : (
            <p className="flex flex-row animate-pulse font-thin">
              Mortgages Total:{" "}
              <span className="flex ml-1 gap-1 items-center">
                <span className="w-2 h-2 bg-gray-500 rounded-full animate-dot-pulse"></span>
                <span className="w-2 h-2 bg-gray-500 rounded-full animate-dot-pulse delay-200"></span>
                <span className="w-2 h-2 bg-gray-500 rounded-full animate-dot-pulse delay-400"></span>
              </span>
            </p>
          )}
          {attributes.total_mortgage_acreage === 0 ? (
            ""
          ) : attributes.total_mortgage_acreage ? (
            <p className="font-thin">
              Acres Mortgaged:{" "}
              <span className="font-medium">
                {attributes.total_mortgage_acreage}
              </span>
              <span className="block w-4/5 border-t border-boundsYellow-50 my-1 m-auto"></span>
            </p>
          ) : (
            <p className="flex flex-row animate-pulse font-thin">
              Acres Mortgaged:{" "}
              <span className="flex ml-1 gap-1 items-center">
                <span className="w-2 h-2 bg-gray-500 rounded-full animate-dot-pulse"></span>
                <span className="w-2 h-2 bg-gray-500 rounded-full animate-dot-pulse delay-200"></span>
                <span className="w-2 h-2 bg-gray-500 rounded-full animate-dot-pulse delay-400"></span>
              </span>
            </p>
          )}

          {attributes.total_owned_acreage === 0 ? (
            ""
          ) : attributes.total_owned_acreage ? (
            <p className="font-thin">
              Total Acres:{" "}
              <span className="font-medium">
                {attributes.total_owned_acreage}
              </span>
              <span className="block w-4/5 border-t border-boundsYellow-50 my-1 m-auto"></span>
            </p>
          ) : (
            <p className="flex flex-row nimate-pulse font-thin">
              Total Acres:{" "}
              <span className="flex ml-1 gap-1 items-center">
                <span className="w-2 h-2 bg-gray-500 rounded-full animate-dot-pulse"></span>
                <span className="w-2 h-2 bg-gray-500 rounded-full animate-dot-pulse delay-200"></span>
                <span className="w-2 h-2 bg-gray-500 rounded-full animate-dot-pulse delay-400"></span>
              </span>
            </p>
          )}
          <Link
            to={"/dashboard/mortgage?parcel_lid=" + attributes.PARCEL_LID}
            className="text-boundsYellow-50 dark:text-blue-500 hover:underline"
          >
            Mortgage Release History
          </Link>
          {(isMarketIntelAdmin() || isMarketIntelOps()) && (
            <button
              onClick={handleIntelButtonClick}
              disabled={attributes.Mortgage != null && !isMarketIntelAdmin()}
              className="mt-2 bg-boundsYellow-50 text-boundsPurple-700 px-4 py-2 rounded-md hover:bg-boundsYellow-100 transition-colors"
            >
              {attributes.Mortgage ? "Update Intel" : "Add Intel"}
            </button>
          )}
          <button
            className="flex ml-auto items-center gap-2 font-inter text-[12px] mt-3"
            onClick={() => setSeeMore(false)}
          >
            See less <ArrowDropDownIcon className="rotate-180" />{" "}
          </button>
        </div>
      ) : (
        <button
          className="flex ml-auto items-center gap-2 font-inter text-[12px] mt-3"
          onClick={() => setSeeMore(true)}
        >
          See more <ArrowDropDownIcon />{" "}
        </button>
      )}
      <Modal isOpen={isOpen} onClose={() => setIsAlertOpen(true)}>
        <MortgageForm
          title="Mortgage"
          onCancel={() => setIsOpen(false)}
          setIsAlertOpen={setIsAlertOpen}
          isAlertOpen={isAlertOpen}
          attributes={attributes}
        />
      </Modal>
    </div>
  );
};

export default CardSelection;
