import React from 'react';
import { Control, Controller } from 'react-hook-form';
import clsx from 'clsx';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import { CSSObjectWithLabel, GroupBase } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { IOption } from '../../../types/search';
import getStylesConfig from './SelectStyles';
import './Select.scss'

interface CustomProps extends CreatableProps<IOption, boolean, GroupBase<IOption>> {
  control: Control<any, any, any>;
  label: string;
  name: string;
  isSearch?: boolean;
  isSearching?: boolean;
  variant?: 'primary' | 'secondary';
}

const selectStyles = {
  ...getStylesConfig('primary'),
  multiValue: (styles: CSSObjectWithLabel) => ({
    ...styles,
    background: '#6d5a68',
    paddingLeft: '2px'
  }),
  multiValueRemove: (styles: CSSObjectWithLabel) => ({
    ...styles,
    display: 'inherit',
  }),
}

const CreatableSelectComponent = React.forwardRef<CreatableSelect, CustomProps>(
  (props, ref) => {
    const { control, label, name, variant = 'primary', isSearch, isSearching, ...otherProps } = props;

    return (
      <div
      className={clsx(
        'min-w-32 xl:min-w-40',
        'rounded-t-md py-2 px-4 border-b relative',
        variant === 'primary' && 'bg-boundsPurple-150 border-boundsYellow-50',
        variant === 'secondary' && 'bg-boundsGray-100 border-white',
        props.isDisabled && 'opacity-50',
        props.className
      )}
    >
      {isSearching && <FontAwesomeIcon className="fa-ellipsis absolute bottom-30 right-2 fa-beat" icon={faEllipsisH} />}
      <label htmlFor={name} className={clsx('text-xs', variant === 'secondary' && 'text-black')}>{label}</label>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <CreatableSelect
              className="select-container"
              styles={selectStyles}
              formatCreateLabel={userInput => isSearch ? `Search for ${userInput}` : `Create ${userInput}`}
              {...field}
              {...otherProps}
              ref={field.ref}
              onChange={(value) => field.onChange(value)}
            />
          )}
        />
      </div>
    );
  }
);

CreatableSelectComponent.displayName = 'CreatableSelectComponent';

export default CreatableSelectComponent;
