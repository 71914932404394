import * as React from "react";
import { SVGProps } from "react";
const ZoomOutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#CAC4D0"
        d="M14.25 9v1.5c0 .31-.253.563-.563.563H5.814a.564.564 0 0 1-.563-.563V9c0-.31.253-.563.563-.563h7.875c.309 0 .562.254.562.563Zm9.422 13.345-1.327 1.327a1.12 1.12 0 0 1-1.589 0l-4.678-4.674a1.124 1.124 0 0 1-.328-.796v-.765a9.702 9.702 0 0 1-6 2.063A9.749 9.749 0 0 1 0 9.75 9.749 9.749 0 0 1 9.75 0a9.749 9.749 0 0 1 9.75 9.75 9.702 9.702 0 0 1-2.063 6h.765c.3 0 .585.117.796.328l4.674 4.674c.436.44.436 1.153 0 1.593ZM16.125 9.75A6.37 6.37 0 0 0 9.75 3.375 6.37 6.37 0 0 0 3.375 9.75a6.37 6.37 0 0 0 6.375 6.375 6.37 6.37 0 0 0 6.375-6.375Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default ZoomOutIcon;
