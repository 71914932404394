import * as React from "react";
import { SVGProps } from "react";
const ChartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 25"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#CAC4D0"
        d="M16.75 15.625h1.875c.313 0 .625-.313.625-.625V8.437c0-.312-.313-.624-.625-.624H16.75c-.313 0-.625.312-.625.625V15c0 .313.313.625.625.625Zm4.688 0h1.875c.312 0 .625-.313.625-.625V3.75c0-.313-.313-.625-.625-.625h-1.875c-.313 0-.625.313-.625.625V15c0 .313.312.625.625.625Zm-14.063 0H9.25c.313 0 .625-.313.625-.625v-3.438c0-.312-.313-.624-.625-.624H7.375c-.313 0-.625.312-.625.624V15c0 .313.313.625.625.625Zm4.688 0h1.874c.313 0 .626-.313.626-.625V5.312c0-.312-.313-.625-.626-.625h-1.874c-.313 0-.626.313-.626.625V15c0 .313.313.625.626.625Zm12.656 3.125H3.625V3.906a.781.781 0 0 0-.781-.781H1.28a.781.781 0 0 0-.781.781v16.407c0 .862.7 1.562 1.563 1.562h22.656c.431 0 .781-.35.781-.781V19.53a.781.781 0 0 0-.781-.781Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.5 0h25v25H.5z" />
      </clipPath>
    </defs>
  </svg>
);
export default ChartIcon;
