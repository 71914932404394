import { Control, useWatch } from 'react-hook-form';

import { ISearchFormValues, SearchFieldIdName, SearchFieldLocationName } from '../types/search';
import { searchIdInputs, searchInputs, validArcGisParamFieldNames } from '../components/molecules/Sidebar/Search/SearchInputs';

interface UseSearchFormProps {
  control: Control<ISearchFormValues>;
}

export const useSearchForm = ({ control }: UseSearchFormProps) => {
  const formValues = useWatch({ control }) as Partial<ISearchFormValues>;

  const getQueryFieldName = (fieldName: string): string => {
    const fieldFromSearchInput = searchInputs.find((field) => field.name === fieldName)?.queryFieldName || '';
    const fieldFromSearchIdInput = searchIdInputs.find((field) => field.name === fieldName)?.queryFieldName || '';
    return fieldFromSearchInput || fieldFromSearchIdInput;
  };

  const splitQueryFieldName = (queryFieldName: string): string[] => {
    return queryFieldName
      .split(',')
      .map((name) => name.trim())
      .filter(Boolean);
  };

  const getSplitQueryFieldName = (fieldName: string): string[] => {
    return splitQueryFieldName(getQueryFieldName(fieldName));
  }

  const buildCondition = (fieldName: string, value: string | string[]): string => {
    const fieldFromSearchInput = searchInputs.find((field) => field.name === fieldName);
    const values = Array.isArray(value) ? value : value.split(' ');
    const condition = getSplitQueryFieldName(fieldName)
      .map((name) => {
        return Array.isArray(value)
          ? values.map((val) => {
            const splitVal = val.split(' ')
            return fieldFromSearchInput && fieldFromSearchInput.searchSubstrings ? splitVal.map((partialVal) => (`UPPER(${name}) LIKE UPPER('%${partialVal}%')`)).join(' AND ')
            : `UPPER(${name}) LIKE UPPER('%${val}%')`
          }).join(' OR ')
          : `UPPER(${name}) LIKE UPPER('%${value}%')`
      })
      .join(' OR ')
    return condition
  };

  const buildWhereClause = (fieldName: string, input: string | string[]): string => {
    const baseCondition = buildCondition(fieldName, input);

    const additionalConditions = Object.entries(formValues)
      .map(([key, value]) => {
        const invalidValue = !value || !Array.isArray(value) || !validArcGisParamFieldNames.includes(key as SearchFieldIdName | SearchFieldLocationName);
        if (invalidValue || key === fieldName) return '';
        return buildCondition(
          key,
          value.map(({ value }) => value)
        );
      })
      .filter(Boolean);

    return [baseCondition, ...additionalConditions]
      .filter(Boolean)
      .join(' AND ');
  };

  return { buildWhereClause, getSplitQueryFieldName };
};
