import * as yup from 'yup';

export const resetPasswordSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  token: yup.string().required('Token is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[^a-zA-Z0-9]/, 'Password must contain at least one special character')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .test('not-contain-password', "Password cannot contain the word 'password'", function (value) {
      return !/password/i.test(value);
    })
    .test(
      'not-contain-email',
      'Password cannot contain part of the email before @',
      function (value) {
        const { email } = this.parent;
        if (!email) return true;
        const emailPrefix = email.split('@')[0];
        return !value.includes(emailPrefix);
      }
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'Passwords must match.')
    .required('Confirm Password is required.'),
});
