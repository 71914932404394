import React from "react";

type BoxHistoryProps = React.PropsWithChildren<{ year: number; color: string }>;

const BoxHistory = ({ year, color, children }: BoxHistoryProps) => {
	return (
		<div className="border border-[#F2C94C] p-3 pb-6">
			<p className="text text-white text-center">{year}</p>
			<div className="flex gap-2">
				<div
					className={`h-[70px] w-[70px]`}
					style={{ backgroundColor: color }}
				/>
				<div className="flex-1">{children}</div>
			</div>
		</div>
	);
};

export default BoxHistory;
