import * as React from "react";
import { SVGProps } from "react";
const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={18}
		height={18}
		fill="none"
		{...props}
	>
		<path
			fill="#CAC4D0"
			fillRule="evenodd"
			d="M2 0h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0Zm11.6 4.6L15 6l-8 8-4-4 1.4-1.4L7 11.2l6.6-6.6Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default CheckIcon;
