import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ToastOptions, toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { InferType } from 'yup';
import logo from '../../assets/images/Logo.png';
import unsplash from '../../assets/images/unsplash.jpg';
import Button from '../../components/atoms/Button';
import Input from '../../components/atoms/Input';
import { useAuth } from '../../hooks/useAuth';
import { ToastSettings } from '../../utils/toast';
import { resetPasswordSchema } from './reset-password.schema';

const ResetPassword = () => {
  const { checkPasswordResetToken, resetPassword } = useAuth();
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState<boolean | null>(null);

  const [queryParams, setQueryParams] = useSearchParams();
  const email = queryParams.get('email') ?? '';
  const token = queryParams.get('token') ?? '';

  useEffect(() => {
    if (!token) {
      navigate('/forgot-password');
    } else {
      const validateQueryParams = async () => {
        try {
          await resetPasswordSchema.validateAt('email', { email });
          await resetPasswordSchema.validateAt('token', { token });
          return true;
        } catch {
          return false;
        }
      };

      const validateFields = async () => {
        setIsValid((await validateQueryParams()) && (await checkPasswordResetToken(email, token)));
      };

      validateFields();
    }
  }, [token, navigate]);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });

  const onSubmit = async (values: InferType<typeof resetPasswordSchema>) => {
    const { email, password } = values;
    resetPassword(email, token, password)
      .then((success) => {
        if (success) {
          navigate('/login');
        }
      })
      .catch(() => {
        toast.error('An unexpected error occurred', ToastSettings as ToastOptions);
      });
  };

  return (
    <div
      className="bg-boundsPurple-150 h-screen relative bg-cover bg-center"
      style={{ backgroundImage: `url(${unsplash})` }}
    >
      <div className="absolute inset-0 bg-boundsPurple-500 bg-opacity-75">
        <section className="w-full h-full flex justify-center items-center">
          <div className="border border-boundsYellow-50 bg-boundsPurple-700 py-5 px-8 rounded-2xl shadow-xl flex flex-col gap-4 min-w-[310px] lg:w-[390px] lg:py-10">
            <img className="mx-auto h-113 w-257 cursor-pointer" src={logo} onClick={() => navigate('/')}></img>
            {/* If isTokenValid is null, show a loading spinner */}
            {isValid === null && (
              <div className="flex justify-center items-center">
                <div className="w-16 h-16 border-4 border-boundsYellow-50 rounded-full text-center animate-spin"></div>
              </div>
            )}
            {/* If isTokenValid is false, show a button to navigate to the forgot password page */}
            {isValid === false && (
              <>
                <span className="text-amber-100 text-center text-[16px]">Invalid Token</span>
                <span className="text-amber-100 text-center text-[12px]">
                  Click the link below to get a new password reset link
                </span>
                <Button className="w-full mt-2" label={'Reset Password'} onClick={() => navigate('/forgot-password')} />
              </>
            )}
            {/* If isTokenValid is true, show the reset password form */}
            {isValid && (
              <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
                <span className="text-amber-100 text-center text-[16px]">Create Password</span>
                <span className="text-amber-100 text-center text-[12px]">Enter your new password below</span>
                <div>
                  <input type="hidden" {...register('email')} value={email} />
                  <input type="hidden" {...register('token')} value={token} />
                  <Input label={'Password'} type="password" {...register('password')} />
                  {errors.password && (
                    <span className="font-bold text-red-500 text-[12px]">{errors.password.message}</span>
                  )}
                </div>
                <div>
                  <Input label={'Confirm Password'} type="password" {...register('confirmPassword')} />
                  {errors.confirmPassword && (
                    <span className="font-bold text-red-500 text-[12px]">{errors.confirmPassword.message}</span>
                  )}
                </div>
                <div className="text-amber-100 text-[12px]">
                  <ul className="list-disc list-inside">
                    <li>Minimum 8 characters</li>
                    <li>Minimum 1 special character</li>
                    <li>Minimum 1 capital letter</li>
                    <li>Minimum 1 lowercase letter</li>
                    <li>Minimum 1 number</li>
                    <li>Doesn&apos;t contain user name</li>
                    <li>Doesn&apos;t contain the word &quot;password&quot;</li>
                  </ul>
                </div>
                <Button className="w-full mt-2" label={'Submit'} type="submit" />
              </form>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default ResetPassword;
