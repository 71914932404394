import React, { useState } from "react";
import Skeleton from "../../Skeleton";
import ExpandIcon from "../../../icons/Expand.icon";


interface IChart {
  isLoading: boolean;
  isEmpty: boolean;
  emptyMessage: string;
  icon: JSX.Element;
  chart: JSX.Element;
}

const ChartCollection = ({chartList} : {chartList: IChart[]}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div className="w-full">
        <div className="pt-4 px-20 flex justify-end gap-2">
          {chartList.map((item: IChart, index: number) => {
            return (
              <div 
                key={"icon-" + index}
                onClick={() => setSelectedIndex(index)}
              >
                {item.icon}
              </div>
            )
          })}
          <ExpandIcon
            width={24}
            height={24}
            cursor={"pointer"}
            onClick={() => {
              if(!chartList[selectedIndex].isLoading){
                setShowModal(true);
              }
            }}
          />
        </div>
        <div className="px-20 w-full">
          {chartList[selectedIndex].isLoading ? 
            <Skeleton width={"100%"} height={300} borderRadius={30} /> 
          : chartList[selectedIndex].isEmpty ?
            <p className="flex align-center justify-center border-y-[1px] border-boundsYellow-50 py-5 mt-6">
              {chartList[selectedIndex].emptyMessage}
            </p>
          :
            chartList[selectedIndex].chart
          }
      </div>
      </div>
      {showModal && (
        <div
          className="fixed inset-0 flex justify-center items-center z-50 w-100 h-100 bg-black bg-opacity-75" 
          onClick={() => setShowModal(false)}
        >
          <div className="relative p-6 bg-black w-3/4 h-3/4">
            {chartList[selectedIndex].chart}
          </div>
        </div>
      )}
    </>
  );
};

export default ChartCollection;
