import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastOptions, toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { InferType } from 'yup';
import logo from '../../assets/images/Logo.png';
import unsplash from '../../assets/images/unsplash.jpg';
import Button from '../../components/atoms/Button';
import Input from '../../components/atoms/Input';
import { useAuth } from '../../hooks/useAuth';
import { ToastSettings } from '../../utils/toast';
import { forgotPasswordSchema } from './forgot-password.schema';

const ForgotPassword = () => {
  const { forgotPassword } = useAuth();
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });

  const onSubmit = async (values: InferType<typeof forgotPasswordSchema>) => {
    const { email } = values;
    setIsSubmitted(true);
    forgotPassword(email).catch(() => {
      toast.error('An unexpected error occurred', ToastSettings as ToastOptions);
    });
  };

  return (
    <div
      className="bg-boundsPurple-150 h-screen relative bg-cover bg-center"
      style={{ backgroundImage: `url(${unsplash})` }}
    >
      <div className="absolute inset-0 bg-boundsPurple-500 bg-opacity-75">
        <section className="w-full h-full flex justify-center items-center">
          <div className="border border-boundsYellow-50 bg-boundsPurple-700 py-5 px-8 rounded-2xl shadow-xl flex flex-col gap-4 min-w-[310px] lg:w-[390px] lg:py-10">
            <img className="mx-auto h-113 w-257 cursor-pointer" src={logo} onClick={() => navigate('/')}></img>
            {isSubmitted ? (
              <>
                <span className="text-amber-100 text-center text-[16px]">Check Your Email</span>
                <span className="text-amber-100 text-center text-[12px]">
                  We have sent a password reset link to your email.
                </span>
                <Button className="w-full mt-2" label="Back to Login" onClick={() => navigate('/login')} />
              </>
            ) : (
              <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
                <span className="text-amber-100 text-center text-[16px]">Forgot Password</span>
                <span className="text-amber-100 text-center text-[12px]">
                  Please enter your email address to reset your password
                </span>
                <div>
                  <Input label={'Email'} type="text" {...register('email')} />
                  {errors.email && <span className="font-bold text-red-500 text-[12px]">{errors.email.message}</span>}
                </div>
                <Button className="w-full mt-2" label={'Submit'} type="submit" />
              </form>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default ForgotPassword;
