import axios from "../api";
import { IMortgage } from "../types/mortgage";

export const getMortgageData = async (parcel_lid: string): Promise<any> => {
  try {
    const response = await axios.get(`/api/mortgage?parcel_lid=${parcel_lid}`);
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return {
      error: true,
      message: error.message || "An unexpected error occurred",
    };
  }
};

export const postMortgageData = async (
  mortgageData: IMortgage
): Promise<any> => {
  try {
    const response = await axios.post("/api/mortgage", mortgageData);
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 400) {
      return {
        error: true,
        message: error.response.data || "Invalid arguments",
      };
    } else if (error.response?.status === 401) {
      return {
        error: true,
        message: "Unauthorized access",
      };
    }
    return {
      error: true,
      message: error.message || "An unexpected error occurred",
    };
  }
};
