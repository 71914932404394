import * as React from "react";
import { SVGProps } from "react";
const ArrowLeft = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={13}
		height={20}
		fill="none"
		{...props}
	>
		<path
			fill="#CAC4D0"
			d="M12.684 17.65 5.05 10l7.634-7.65L10.334 0l-10 10 10 10 2.35-2.35Z"
		/>
	</svg>
);
export default ArrowLeft;
