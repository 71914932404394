import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/molecules/Layout";
import DateRangeSelect from "../../components/features/Dashboard/DateRangeSelect";
import MortgageAnalysis from "../../components/features/Dashboard/MortgageAnalysis";
import FinancialOverview from "../../components/features/Dashboard/FinancialOverview";
import clsx from "clsx";
import { SelectComponent } from "../../components/atoms/Select";
import { useForm } from "react-hook-form";
import { IDashboardFilters, IParameters } from "../../types/financialOverview";
import {
  generateCountyOptions,
  generateMortgageHolderOptions,
  getDateOnly,
} from "../../utils/financialOverview";
import { IOption } from "../../types/search";
import { getDropdownData } from "../../services/dashboard";
import UccSearch from "../../components/features/Dashboard/UccSearch";

interface DropdownDataResult {
  counties: string[];
  loan_institutions: string[];
}

const Dashboard = () => {
  const params = useParams();
  const navigate = useNavigate();

  if (!params.tab || !(["ucc", "mortgage", "overview"].includes(params.tab))){
    navigate(`/dashboard/overview`);
  }

  const handleTab = (section: "ucc" | "mortgage" | "overview") => {
    navigate(`/dashboard/${section}`);
  };

  const { control, watch, getValues, setValue } = useForm<IParameters>();
  const [mortgageOptions, setMortgageOptions] = useState<IOption[]>([]);
  const [countyOptions, setCountyOptions] = useState<IOption[]>([]);
  const [isLoadingDropdowns, setIsLoadingDropdowns] = useState(true);

  const DEFAULT_SELECTED = "This Year";
  const today = new Date();
  const userTimezoneOffset = today.getTimezoneOffset() * 60000;
  today.setTime(today.getTime() - userTimezoneOffset);
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(today.getFullYear(), 0, 1)
  );
  const [endDate, setEndDate] = useState<Date | null>(
    new Date(today.getFullYear() + 1, 0, 1)
  );
  const [timePeriod, setTimePeriod] = useState<string>("year");

  const updateDropdowns = useCallback((params: IDashboardFilters) => {
    setIsLoadingDropdowns(true);
    getDropdownData(params).then((result: DropdownDataResult) => {
      if(result){
        setMortgageOptions(generateMortgageHolderOptions(result?.loan_institutions || []));
        setCountyOptions(generateCountyOptions(result?.counties || []));
        setIsLoadingDropdowns(false);
      }
    });
  }, []);

  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      if (type === "change" && startDate && endDate) {
        updateDropdowns({
          start_date: getDateOnly(startDate),
          end_date: getDateOnly(endDate),
          loan_institution: data.mortgageHolder?.value,
          county: data.county?.value,
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if(startDate && endDate && params.tab === "overview"){
      updateDropdowns({
        start_date: getDateOnly(startDate),
        end_date: getDateOnly(endDate),
        loan_institution: getValues("mortgageHolder")?.value,
        county: getValues("county")?.value,
      });
    }
  }, [startDate, endDate]);

  return (
    <Layout>
      <main
        className={clsx(
          "w-full h-full p-8 bg-boundsPurple-400",
          "lg:pl-48  xl:ml-40 xl:p-8"
        )}
      >
        <div
          className={clsx(
            "flex items-start gap-2",
            params.tab === "overview" ? "justify-between" : "justify-end"
          )}
        >
          {params.tab === "overview" && (
            <div className="flex gap-6">
              <div
                key={`search-select_date`}
                className="w-[160px] xl:w-[210px] 3xl:w-[210px]"
              >
                <DateRangeSelect
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  setTimePeriod={setTimePeriod}
                  defaultSelected={DEFAULT_SELECTED}
                />
              </div>
              <div
                key={`search-select_mortgage`}
                className="w-[160px] xl:w-[210px] 3xl:w-[210px]"
              >
                <SelectComponent
                  name={"mortgageHolder"}
                  control={control}
                  key={`input-parameter_mortgageHolder`}
                  label={"Mortgage Holder"}
                  options={mortgageOptions}
                  placeholder={"--Select one--"}
                  closeMenuOnSelect={true}
                  isDisabled={isLoadingDropdowns}
                  noOptionsMessage={() =>
                    "No options found for selected filters"
                  }
                  isClearable={true}
                />
              </div>
              <div
                key={`search-select_county`}
                className="w-[160px] xl:w-[210px] 3xl:w-[210px]"
              >
                <SelectComponent
                  name={"county"}
                  control={control}
                  key={`input-parameter_county`}
                  label={"County"}
                  options={countyOptions}
                  placeholder={"--Select one--"}
                  closeMenuOnSelect={true}
                  isDisabled={isLoadingDropdowns}
                  noOptionsMessage={() =>
                    "No options found for selected filters"
                  }
                  isClearable={true}
                />
              </div>
            </div>
          )}
          <div className="flex justify-end gap-6 h-fit">
            <button
              className={clsx(
                "font-medium rounded  px-3 xl:w-[140px] xl:px-6 text-sm font-roboto",
                params.tab === "ucc"
                  ? "!bg-boundsYellow-50 text-black "
                  : "!bg-boundsGray-500 text-white"
              )}
              onClick={() => handleTab("ucc")}
            >
              UCC Search
            </button>
            <button
              className={clsx(
                "font-medium rounded  px-3 xl:w-[200px] xl:px-6 text-sm font-roboto",
                params.tab === "mortgage"
                  ? "!bg-boundsYellow-50 text-black "
                  : "!bg-boundsGray-500 text-white"
              )}
              onClick={() => handleTab("mortgage")}
            >
              Mortgage Rate Analysis
            </button>
            <button
              className={clsx(
                " px-3 xl:w-[168px] rounded leading-5 font-medium text-sm xl:px-6 py-2 font-roboto",
                params.tab === "overview"
                  ? "!bg-boundsYellow-50 text-black "
                  : "!bg-boundsGray-500 text-white"
              )}
              onClick={() => handleTab("overview")}
            >
              Financial Overview
            </button>
          </div>
        </div>
        <div className="my-7">
          {params.tab === "mortgage" ? (
            <MortgageAnalysis />
          ) : params.tab === "overview" ? (
            <FinancialOverview
              time={timePeriod}
              startDate={startDate}
              endDate={endDate}
              loan_institution={getValues("mortgageHolder")?.value}
              county={getValues("county")?.value}
            />
          ) : (
            <UccSearch />
          )}
        </div>
      </main>
    </Layout>
  );
};

export default Dashboard;
