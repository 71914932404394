import * as React from "react";
import { SVGProps } from "react";
const MoneyBillWaveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#CAC4D0"
        d="M24.264 4.627c-1.515-.635-3.032-.877-4.547-.877-4.811 0-9.622 2.435-14.434 2.435-1.206 0-2.412-.153-3.619-.536a1.336 1.336 0 0 0-.404-.063c-.672 0-1.26.52-1.26 1.243V19.22c0 .494.282.961.736 1.151 1.515.636 3.032.878 4.547.878 4.811 0 9.623-2.436 14.434-2.436 1.207 0 2.413.154 3.62.536.135.043.27.064.404.064.672 0 1.26-.52 1.26-1.243V5.78c-.001-.494-.283-.961-.737-1.152ZM1.875 7.665c.786.197 1.606.296 2.45.349a2.503 2.503 0 0 1-2.45 2.013V7.665Zm0 11.133V16.93a2.5 2.5 0 0 1 2.489 2.399 9.438 9.438 0 0 1-2.489-.532ZM12.5 16.25c-1.726 0-3.125-1.68-3.125-3.75 0-2.071 1.4-3.75 3.125-3.75 1.726 0 3.125 1.679 3.125 3.75 0 2.072-1.4 3.75-3.125 3.75Zm10.625 1.085a13.428 13.428 0 0 0-2.122-.33 2.495 2.495 0 0 1 2.122-1.924v2.254Zm0-9.223a2.495 2.495 0 0 1-2.18-2.404 9.39 9.39 0 0 1 2.18.494v1.91Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h25v25H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default MoneyBillWaveIcon;
