import Graphic from '@arcgis/core/Graphic.js';

export interface IOption {
  value: string;
  label: string;
  isDisabled?: boolean;
}

export interface ISearchFormValues {
  [SearchFieldIdName.Parcel]: IOption[];
  [SearchFieldIdName.UniqueTax]: IOption[];
  [SearchFieldIdName.AlternateTax]: IOption[];
  [SearchFieldLocationName.OwnerName]: IOption[];
  [SearchFieldLocationName.OwnerAddress]: IOption[];
  [SearchFieldLocationName.State]: IOption[];
  [SearchFieldLocationName.County]: IOption[];
  [SearchFieldLocationName.Township]: IOption[];
  [SearchFieldLocationName.Range]: IOption[];
  [SearchFieldLocationName.Section]: IOption[];
}

export enum SearchFieldIdName {
  Parcel = 'parcel',
  UniqueTax = 'uniqueTax',
  AlternateTax = 'alternateTax',
  ParcelLid = 'parcelLid'
}

export enum SearchFieldLocationName {
  OwnerName = 'ownerName',
  OwnerAddress = 'ownerAddress',
  State = 'state',
  County = 'county',
  Township = 'township',
  Range='range',
  Section = 'section',
}

export interface ISearchFields {
  label: string;
  name: SearchFieldIdName | SearchFieldLocationName;
  placeholder?: string;
  options?: IOption[];
  isMulti: boolean;
  prevOption?: SearchFieldIdName | SearchFieldLocationName;
  queryFieldName?: string;
  defaultValue?: IOption;
  searchSubstrings?: boolean;
}

export interface IDataResult {
  id: number;
  parcelLid: string;
  from: string;
  to: string;
  description: string;
  county: string;
  mortgage?: number;
  UCC?: number;
}

interface Parcel {
  parcelFeature: Graphic;
}

export type ParcelResult = IDataResult & Parcel;
