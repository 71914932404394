export const PAGE_SIZE = 10;

export const MORTGAGE_RATE_OPTIONS = [
  { label: "Loan Institution", value: "loan_institution" },
  { label: "Borrower", value: "borrower" },
  { label: "Description", value: "description" },
  { label: "County", value: "county" },
];

export const UCC_SEARCH_CATEGORY_OPTIONS = [
  { label: "Sec Party", value: "secparty" },
  { label: "Auth Party", value: "authparty" },
  { label: "Debtor", value: "debtors" },
  { label: "Address", value: "address" }
];