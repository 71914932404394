import states from '../../../../utils/states';
import { ISearchFields, SearchFieldIdName, SearchFieldLocationName } from '../../../../types/search';

export const SEARCH_PARCEL_ID_CATEGORY_OPTIONS = [
  {label: 'Parcel #', value: SearchFieldIdName.Parcel},
  {label: 'Unique Tax #', value: SearchFieldIdName.UniqueTax},
  {label: 'Alternate Tax #', value: SearchFieldIdName.AlternateTax},
  {label: 'ParcelLID', value: SearchFieldIdName.ParcelLid}
]

export const SEARCH_PARCEL_ID_DICT: Record<SearchFieldIdName, ISearchFields> = {
  [SearchFieldIdName.Parcel]: {
    label: 'Parcel #',
    placeholder: 'Search here',
    name: SearchFieldIdName.Parcel,
    isMulti: true,
    queryFieldName: 'PARCEL_APN',
  },
  [SearchFieldIdName.UniqueTax]: {
    label: 'Unique Tax #',
    placeholder: 'Search here',
    name: SearchFieldIdName.UniqueTax,
    isMulti: true,
    queryFieldName: 'UNIQUE_TAXAPN',
  
  },
  [SearchFieldIdName.AlternateTax]: {
    label: 'Alternate Tax #',
    placeholder: 'Search here',
    name: SearchFieldIdName.AlternateTax,
    isMulti: true,
    queryFieldName: 'ALTERNATE_TAXAPN',
  },
  [SearchFieldIdName.ParcelLid]: {
    label: 'ParcelLid',
    placeholder: 'Search here',
    name: SearchFieldIdName.ParcelLid,
    isMulti: true,
    queryFieldName: 'PARCEL_LID',
  }
}

export const searchIdInputs: ISearchFields[] = [
  SEARCH_PARCEL_ID_DICT[SearchFieldIdName.Parcel],
  SEARCH_PARCEL_ID_DICT[SearchFieldIdName.UniqueTax],
  SEARCH_PARCEL_ID_DICT[SearchFieldIdName.AlternateTax],
  SEARCH_PARCEL_ID_DICT[SearchFieldIdName.ParcelLid],
]

export const searchInputs: ISearchFields[] = [
  {
    label: 'State',
    placeholder: 'Search here',
    name: SearchFieldLocationName.State,
    options: states,
    isMulti: false,
    queryFieldName: "STATE",
    defaultValue: { label: "Kansas (KS)", value: "KS" }
  },
  {
    label: 'County',
    placeholder: 'Search county',
    name: SearchFieldLocationName.County,
    isMulti: true,
    prevOption: SearchFieldLocationName.State,
    queryFieldName: 'COUNTY',
    defaultValue: { label: "FINNEY", value: "FINNEY" }
  },
  {
    label: 'Owner Name',
    placeholder: 'Search owner',
    name: SearchFieldLocationName.OwnerName,
    isMulti: true,
    queryFieldName: 'OWNER_NAME',
    searchSubstrings: true,
  },
  {
    label: 'Owner Address',
    placeholder: 'Search address',
    name: SearchFieldLocationName.OwnerAddress,
    isMulti: true,
    queryFieldName: 'MAIL_ADDR',
  },
  {
    label: 'Township',
    placeholder: 'Search township',
    name: SearchFieldLocationName.Township,
    isMulti: true,
    prevOption: SearchFieldLocationName.County,
    queryFieldName: 'TOWNSHIP',
  },
  {
    label: 'Range',
    placeholder: 'Search range',
    name: SearchFieldLocationName.Range,
    isMulti: true,
    prevOption: SearchFieldLocationName.Township,
    queryFieldName: 'RANGE',
  },
  {
    label: 'Section',
    placeholder: 'Search section',
    name: SearchFieldLocationName.Section,
    isMulti: true,
    prevOption: SearchFieldLocationName.Range,
    queryFieldName: 'SECTION',
  },
];

export const validArcGisParamFieldNames = [
  SearchFieldIdName.Parcel,
  SearchFieldIdName.UniqueTax,
  SearchFieldIdName.AlternateTax,
  SearchFieldIdName.ParcelLid,
  SearchFieldLocationName.OwnerName,
  SearchFieldLocationName.OwnerAddress,
  SearchFieldLocationName.County,
  SearchFieldLocationName.Township,
  SearchFieldLocationName.Range,
  SearchFieldLocationName.Section
]

export const searchFormDefaultValues = {
  [SearchFieldIdName.Parcel]: [],
  [SearchFieldIdName.UniqueTax]: [],
  [SearchFieldIdName.AlternateTax]: [],
  [SearchFieldIdName.ParcelLid]: [],
  [SearchFieldLocationName.OwnerName]: [],
  [SearchFieldLocationName.OwnerAddress]: [],
  [SearchFieldLocationName.State]: [{ label: "Kansas (KS)", value: "KS" }],
  [SearchFieldLocationName.County]: [{ label: "FINNEY", value: "FINNEY" }],
  [SearchFieldLocationName.Township]: [],
  [SearchFieldLocationName.Range]: [],
  [SearchFieldLocationName.Section]: [],
}