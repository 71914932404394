import * as React from "react";
import { SVGProps } from "react";
const ExpandIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 16 16"
		fill="none"
		{...props}
	>
		<path
			fill="#CAC4D0"
			d="M3,5h4V3H1v12h12V9h-2v4H3V5z M16,8V0L8,0v2h4.587L6.294,8.294l1.413,1.413L14,3.413V8H16z"
		/>
	</svg>
);
export default ExpandIcon;
