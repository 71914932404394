import React from "react";
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import clsx from "clsx";
import ArrowDropDownIcon from "../../icons/ArrowDropDown";
import Skeleton from "../../atoms/Skeleton";

interface TableProps<T> {
  columns: ColumnDef<T>[];
  data: T[];
  isLoading?: boolean;
  onRowClick?: (row: T) => void;
}

const Table = <T,>({
  columns,
  data,
  isLoading = true,
  onRowClick,
}: TableProps<T>) => {
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: { sorting: [{ id: "date", desc: true }] },
  });

  return (
    <div className="max-h-[600px] overflow-y-auto">
      <table className="w-full border-collapse overflow-hidden bg-transparent text-sm font-medium">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={`header-group_${headerGroup.id}`}
              className="bg-boundsPurple-500"
            >
              {headerGroup.headers.map((header, index) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  className={clsx(
                    "p-2 border-b border-b-boundsPurple-400 bg-boundsPurple-500 cursor-pointer",
                    index === 0 && "rounded-tl-3xl",
                    index === headerGroup.headers.length - 1 && "rounded-tr-3xl"
                  )}
                  style={{
                    width: header.column.columnDef.size,
                  }}
                >
                  <div className="flex justify-between items-center px-2 text-white w-full gap-1">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    <div className="w-[10px] h-[5px]">
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === "desc" ? (
                          <ArrowDropDownIcon />
                        ) : (
                          <ArrowDropDownIcon className="rotate-180" />
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-boundsPurple-400 text-white">
          {table.getRowModel().rows.map((row, rowIndex) => (
            <tr
              key={row.id}
              onClick={() => onRowClick?.(row.original)}
              style={{ cursor: onRowClick ? "pointer" : "default" }}
            >
              {row.getVisibleCells().map((cell, cellIndex) => (
                <td
                  key={cell.id}
                  className={clsx(
                    "p-2 border border-boundsPurple-500",
                    rowIndex === table.getRowModel().rows.length - 1 &&
                      "border-boundsPurple-500",
                    rowIndex === table.getRowModel().rows.length - 1 &&
                      cellIndex === 0 &&
                      "rounded-bl-lg",
                    rowIndex === table.getRowModel().rows.length - 1 &&
                      cellIndex === row.getVisibleCells().length - 1 &&
                      "rounded-br-lg"
                  )}
                  style={{
                    width: cell.column.columnDef.size,
                  }}
                >
                  {isLoading ? (
                    <Skeleton width={"100%"} height={35} borderRadius={10} />
                  ) : (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
