import axios from '../api';

export const getParcel = async (parcel_lid: string): Promise<any> => {
  try {
    const response = await axios.get('/api/parcel?parcel_lid=' + parcel_lid);
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getExtendedParcel = async (parcel_lid: string): Promise<any> => {
  try {
    const response = await axios.get('/api/parcel/extended?parcel_lid=' + parcel_lid);
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};
