import * as React from "react";
import { SVGProps } from "react";
const ArrowUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    fill="#28CB19"
    {...props}
  >
    <path d="m2.266 13.136-1.084-1.084a1.167 1.167 0 0 1 0-1.655L10.67.903a1.167 1.167 0 0 1 1.656 0l9.487 9.488a1.167 1.167 0 0 1 0 1.655l-1.084 1.084a1.173 1.173 0 0 1-1.675-.02l-5.6-5.879v14.034c0 .649-.523 1.172-1.172 1.172h-1.563a1.17 1.17 0 0 1-1.172-1.172V7.232l-5.605 5.884a1.165 1.165 0 0 1-1.675.02Z" />
  </svg>
);
export default ArrowUpIcon;
