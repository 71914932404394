import * as React from "react";
import { SVGProps } from "react";
const CheckMoneyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <path
      fill="#CAC4D0"
      d="M23.75 3.75H1.25C.56 3.75 0 4.31 0 5v15c0 .69.56 1.25 1.25 1.25h22.5c.69 0 1.25-.56 1.25-1.25V5c0-.69-.56-1.25-1.25-1.25ZM6.875 15.308v.63c0 .172-.14.312-.313.312h-.625a.312.312 0 0 1-.312-.313v-.636a2.237 2.237 0 0 1-1.225-.443.313.313 0 0 1-.023-.474l.46-.438a.32.32 0 0 1 .395-.029.94.94 0 0 0 .5.146h1.099c.254 0 .46-.232.46-.516 0-.232-.14-.437-.342-.497l-1.758-.527c-.726-.218-1.233-.915-1.233-1.695 0-.958.744-1.736 1.667-1.76v-.63c0-.173.14-.313.312-.313h.625c.173 0 .313.14.313.313v.636c.44.022.87.176 1.225.443.152.115.16.343.022.474l-.459.438a.32.32 0 0 1-.395.029.937.937 0 0 0-.501-.146H5.669c-.254 0-.461.232-.461.516 0 .232.14.437.342.497l1.758.527c.726.218 1.234.915 1.234 1.695 0 .958-.744 1.736-1.667 1.76Zm9.375-.62c0 .172-.14.312-.313.312h-4.374a.312.312 0 0 1-.313-.313v-.624c0-.173.14-.313.313-.313h4.374c.173 0 .313.14.313.313v.624Zm6.25 0c0 .172-.14.312-.313.312h-3.125a.312.312 0 0 1-.312-.313v-.624c0-.173.14-.313.313-.313h3.125c.172 0 .312.14.312.313v.624Zm0-3.75c0 .172-.14.312-.313.312H11.563a.312.312 0 0 1-.312-.313v-.624c0-.173.14-.313.313-.313h10.624c.173 0 .313.14.313.313v.624Z"
    />
  </svg>
);
export default CheckMoneyIcon;
