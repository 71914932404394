import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PopupState {
  selectedParcelLId: string | null;
}

const initialState: PopupState = {
  selectedParcelLId: null,
};

const popupSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    setSelectedParcelLId: (state, action: PayloadAction<string | null>) => {
      state.selectedParcelLId = action.payload;
    },
  },
});

export const { setSelectedParcelLId } = popupSlice.actions;

export default popupSlice.reducer;
