import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart } from "chart.js";

// May need these / similar in the future
/*Chart.register(LineElement);
Chart.register(LinearScale);
Chart.register(CategoryScale);
Chart.register(PointElement);
Chart.register(Filler);*/

interface IPieChartData {
  labels?: string[];
  values: number[];
  colors?: string[];
}

const PieChart = ({labels, values, colors=["#0263ff", "#f97223", "#8e30ff"]} : IPieChartData) => {
  return (
    <Pie
      style={{minHeight: "400px"}}
      data={{
        labels: labels, 
        datasets: [
          {
            data: values,
            backgroundColor: colors,
          }
        ]
      }}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        layout: { padding: { top: 5 } },
        plugins: {
          legend: {
            position: "right",
            labels: {
              color: "white",
              padding: 25,
              boxHeight: 25,
              boxWidth: 25,
              font: {
                size: 14
              }
            },
            
          },
          datalabels: {
            display: false
          }
        },
      }}
    />
  );
};

export default PieChart;
