import React, { useContext, useEffect } from "react";
import Pagination from "../../molecules/Pagination";
import { PAGE_SIZE } from "../../../utils/constants";
import MortgageHolderTable from "../MortgageHolderTable";
import { IMortgageHolder } from "../../../types/financialOverview";
import { useDispatch } from "react-redux";
import { setSelectedParcelLId } from "../../../redux/actions";

const CityPopup = ({
  data,
  totalCount,
  handlePageChange,
  loadingPagination,
  actualPage,
}: {
  data: IMortgageHolder[] | undefined;
  totalCount: number;
  handlePageChange: (newPage: number) => void;
  loadingPagination: boolean;
  actualPage: number;
}) => {
  const dispatch = useDispatch();

  const handleParcelLId = (row: IMortgageHolder) => {
    dispatch(setSelectedParcelLId(row.parcel_lid));
  };

  useEffect(() => {
    return () => {
      setSelectedParcelLId(null);
    };
  }, []);

  return (
    <div>
      <MortgageHolderTable
        data={data || []}
        isLoading={false}
        onRowClick={(row: IMortgageHolder) => handleParcelLId(row)}
      />
      <Pagination
        actualPage={actualPage}
        totalCount={totalCount ?? 0}
        pageSize={PAGE_SIZE}
        onPageChange={handlePageChange}
        isLoading={loadingPagination}
      />
    </div>
  );
};

export default CityPopup;
