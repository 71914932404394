export enum DataOption {
  CropHistory = "cropHistory",
  PropertyTax = "propertyTax",
  Yield = "yield",
  Flood = "flood",
  Elevation = "elevation",
  WaterWells = "waterWells",
  Soils = "soils",
}

export interface ITax {
  assessment_lid: string;
  apn: string;
  year: number;
  amount: number;
}

/* Elevation */
export interface ElevationData {
  min_slope: number;
  max_slope: number;
  mean_slope: number;
  min_elevation: number;
  max_elevation: number;
}
