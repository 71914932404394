import React from "react";
import ReactDatePicker from "react-datepicker";
import { Control, Controller, FieldValues } from "react-hook-form";

const DatePicker = ({
  name,
  control,
  label,
  errorMessage,
  placeholder = "",
}: {
  label: string;
  name: string;
  control: Control<FieldValues>;
  placeholder?: string;
  errorMessage?: string;
}) => {
  return (
    <>
      <div className="w-full flex flex-col py-3 px-2  bg-boundsGray-100 rounded-t-md border-b border-white">
        <label className="text-xs text-black">{label}</label>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <ReactDatePicker
              className="bg-transparent text-black"
              calendarClassName="bg-boundsPurple-500"
              placeholderText={placeholder}
              onChange={(date) => field.onChange(date)}
              selected={field.value}
            />
          )}
        />
      </div>
      {errorMessage && (
        <span className="font-light text-[12px] text-red-500">
          {errorMessage}
        </span>
      )}
    </>
  );
};

export default DatePicker;
