import * as React from "react";
import { SVGProps } from "react";
const HandsHelpingIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={25}
		height={25}
		fill="none"
		{...props}
	>
		<g clipPath="url(#a)">
			<path
				fill="#CAC4D0"
				d="M19.063 10h-5.937v2.187A2.816 2.816 0 0 1 10.313 15a2.816 2.816 0 0 1-2.812-2.813v-4.75L4.966 8.961A2.495 2.495 0 0 0 3.75 11.1v1.848L.626 14.754a1.247 1.247 0 0 0-.457 1.707l3.125 5.414c.344.598 1.11.8 1.707.457L9.04 20h5.336c1.379 0 2.5-1.121 2.5-2.5h.625c.691 0 1.25-.559 1.25-1.25v-2.5h.312c.52 0 .938-.418.938-.938v-1.875a.935.935 0 0 0-.938-.937Zm5.77-1.461-3.125-5.414A1.25 1.25 0 0 0 20 2.668L15.96 5H11.97c-.47 0-.926.133-1.325.379l-1.308.816a1.242 1.242 0 0 0-.586 1.059v4.933a1.562 1.562 0 1 0 3.125 0V8.75h7.187c1.207 0 2.188.98 2.188 2.187v1.114l3.125-1.805c.598-.348.8-1.11.457-1.707Z"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h25v25H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default HandsHelpingIcon;
