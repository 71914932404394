import React, { FC, useEffect } from "react";
import Point from "@arcgis/core/geometry/Point";
import useBoundsUI from "../../../hooks/useBoundsUI";
import { IDataResult } from "../../../types/search";
import MortgageTable from "../MortgageTable";
import SpinnerIcon from "../../icons/Spinner.icon";

interface IResults {
  data: IDataResult[];
  loadMoreData: () => void;
  isLoading: boolean;
  point: Point;
}

const Results: FC<IResults> = ({ data, loadMoreData, isLoading, point }) => {
  const {
    searchResults: { setPoint },
  } = useBoundsUI();

  useEffect(() => {
    setPoint(point);

    return () => {
      setPoint(null);
    };
  }, []);

  return (
    <div className="flex flex-col gap-6">
      <h1 className="text-3xl">{data.length} results</h1>
      <MortgageTable
        data={data}
        onScrollToBottom={loadMoreData}
        addUcc={true}
        isLoading={isLoading}
      />
      {!isLoading && data.length === 0 && (
        <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6 text-center">
          No results found for selected filters.
        </p>
      )}
    </div>
  );
};

export default Results;
