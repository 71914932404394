import React, { useEffect, useState } from "react";
import { SelectComponent } from "../../../atoms/Select";
import { useForm } from "react-hook-form";
import { IOption } from "../../../../types/search";
import useBoundsUI from "../../../../hooks/useBoundsUI";
import { DataOption } from "../../../../types/insights";
import { Subscription } from "react-hook-form/dist/utils/createSubject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'; 
import { Tooltip } from "react-tooltip";

const PropertyTask = () => {
  const { control, watch, reset } = useForm<{ taxYear: IOption }>();

  const [years, setYears] = useState<{ label: string; value: string }[]>([]);
  const [amount, setAmount] = useState<number | null>();
  const [watcher, setWatcher] = useState<Subscription>();

  const {
    insightData: { data, insightType, insightError },
  } = useBoundsUI();
  const isLoadingData = data === null;

  useEffect(() => {
    reset();
    setAmount(null);
    watcher?.unsubscribe();
    if (insightType === DataOption.PropertyTax && !isLoadingData && data) {
      const newYearlyData = Object.entries(data).sort(([y1], [y2]) => {
        return parseInt(y1) - parseInt(y2);
      });
      const newYears: { label: string; value: string }[] = [];
      newYearlyData.forEach(([_, stats]) => {
        newYears.push({
          label: stats.year,
          value: stats.year,
        });
      });
      setYears(newYears);
      setWatcher(
        watch((val, { name, type }) => {
          if (val.taxYear && val.taxYear.value) {
            const year = val.taxYear.value;
            const entry = data?.find((x) => x.year === year);
            if (entry) {
              setAmount(entry.amount);
            } else {
              setAmount(null);
            }
          }
        })
      );
    }
  }, [data, insightError]);

  return (
    <div className="min-w-[285px]">
      <div className="flex flex-row items-center ">
				<h2 className="text-[32px] mr-1">Property Tax</h2>
				<FontAwesomeIcon className="question-mark" icon={faCircleQuestion} />
				<Tooltip anchorSelect=".question-mark" place="bottom" offset={10}>
					Source: County Assessor
				</Tooltip>
			</div>	
      {insightError ? (
        <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6">
          {insightError}
        </p>
      ) : isLoadingData ? (
        <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6">
          Loading...
        </p>
      ) :  (data && data.length > 0) ? (
        <>
          {years && years.length > 0 && (
            <SelectComponent
              className={"mt-6"}
              name={"taxYear"}
              control={control}
              label={"Tax Year"}
              options={years}
              placeholder={"--Select one--"}
              closeMenuOnSelect={true}
            />
          )}
          {amount && (
            <div className="bg-boundsPurple-150 rounded-md py-2 px-4 mt-6">
              <table className="table-auto">
                <thead>
                  <tr>
                    <th className="pr-3">#</th>
                    <th>Property Tax</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="pr-3">1</td>
                    <td>${amount.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </>
      ) : (
        <>
          <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6">
            Select an area to view data
          </p>
        </>
      )}
    </div>
  );
};

export default PropertyTask;
