import React, { useState, useEffect } from "react";
import { Control } from "react-hook-form";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Query from "@arcgis/core/rest/support/Query";

import { useSearchForm } from "../../../../hooks/useSearchForm";
import useDebounce from "../../../../hooks/useDebounce";
import { NavigationGroupLayer } from "../../../../layers/Layers";
import {
  ISearchFields,
  ISearchFormValues,
  IOption,
} from "../../../../types/search";
import { CreatableSelectComponent } from "../../../atoms/Select";

const INPUT_OPTIONS_RESPONSE_COUNT = 50;

const SearchSelect = ({
  control,
  searchInput,
  isSubmitting,
  title,
}: {
  control: Control<ISearchFormValues>;
  searchInput: ISearchFields;
  isSubmitting: boolean;
  title?: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchOptions, setSearchOptions] = useState<IOption[]>([]);
  const [queryOffset, setQueryOffset] = useState(0);
  const [inputSearch, setInputSearch] = useState("");

  const { buildWhereClause, getSplitQueryFieldName } = useSearchForm({
    control,
  });
  const debouncedInputSearch = useDebounce(inputSearch, 1000);

  const getSearchInputOptions = async (
    input: string,
    clearExistingOptions?: boolean
  ) => {
    const queryFieldName = searchInput?.queryFieldName || "";
    if (!queryFieldName) return [];

    if (searchInput.options) return searchInput.options;

    const parcelQuery = new Query({
      returnGeometry: false,
      outFields: getSplitQueryFieldName(searchInput.name),
      returnDistinctValues: true,
      num: INPUT_OPTIONS_RESPONSE_COUNT,
      start: queryOffset * 50,
      where: buildWhereClause(searchInput.name, input),
    });

    const parcelLayer = NavigationGroupLayer.findLayerById(
      "Parcellightboxlayer"
    ) as FeatureLayer;

    try {
      setIsLoading(true);
      const featureSet = await parcelLayer.queryFeatures(parcelQuery);
      const options = featureSet.features
        .map((feature) => {
          const value = feature.attributes[queryFieldName];
          return { value, label: value };
        })
        .filter(({ value }) => value != null);
      const searchOptionList = clearExistingOptions
        ? options
        : [...searchOptions, ...options];
      setSearchOptions(searchOptionList);
    } catch (error) {
      console.error("Error fetching search input options:", error);
      return [];
    }

    setIsLoading(false);
  };

  const loadMoreOptions = () => {
    if (!isLoading) setQueryOffset(queryOffset + 1);
  };

  const onFocus = () => {
    if (!debouncedInputSearch) getSearchInputOptions("", true);
  };

  useEffect(() => {
    setSearchOptions([]);
  }, [searchInput.name]);

  useEffect(() => {
    if (!queryOffset && debouncedInputSearch === "") return;
    getSearchInputOptions(debouncedInputSearch);
  }, [queryOffset, debouncedInputSearch]);

  return (
    <>
      <div>
        {title && <h3 className="text-2xl mb-4">{title}</h3>}
        <CreatableSelectComponent
          control={control}
          name={searchInput.name}
          label={searchInput.label}
          placeholder={searchInput.placeholder}
          isMulti={searchInput.isMulti}
          closeMenuOnSelect={!searchInput.isMulti}
          options={searchInput.options || searchOptions}
          defaultValue={searchInput.defaultValue}
          createOptionPosition="first"
          isSearch={true}
          isSearching={isLoading}
          isDisabled={isSubmitting}
          onMenuScrollToBottom={loadMoreOptions}
          onFocus={onFocus}
          onInputChange={setInputSearch}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default SearchSelect;
