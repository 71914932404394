import React from "react";
import { Control, Controller } from "react-hook-form";

const Toggle = ({
  name,
  label,
  control,
  disabled = false,
  defaultValue = false,
}: {
  name: string;
  label: string;
  control: Control<any>;
  disabled?: boolean;
  defaultValue?: boolean;
}) => {
  return (
    <div className="flex items-center space-x-4">
      <label className={`text-white ${disabled ? "opacity-50" : ""}`}>{label}</label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <div
            className={`relative inline-flex items-center h-6 rounded-full w-11 cursor-pointer ${
              field.value ? "bg-boundsYellow-50" : "bg-gray-300"
            } ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={() => !disabled && field.onChange(!field.value)}
          >
            <span
              className={`transform transition-transform duration-200 ease-in-out ${
                field.value ? "translate-x-6" : "translate-x-1"
              } inline-block w-4 h-4 bg-white rounded-full`}
            />
          </div>
        )}
      />
    </div>
  );
};

export default Toggle;
