export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
	const userTimezoneOffset = date.getTimezoneOffset() * 60000;
	const dateWithoutTZ = new Date(date.getTime() + userTimezoneOffset);

  const month = String(dateWithoutTZ.getMonth() + 1).padStart(2, "0");
  const day = String(dateWithoutTZ.getDate()).padStart(2, "0");
  const year = dateWithoutTZ.getFullYear();
  return `${month}/${day}/${year}`;
};
