import clsx from "clsx";
import React from "react";

type SkeletonProps = React.CSSProperties & {
  className?: string;
};

const Skeleton = (props: SkeletonProps) => {
  const { className, ...rest } = props;

  return (
    <div className="w-full">
      <div
        style={{ ...rest }}
        className={clsx("bg-zinc-700 animate-pulse", className)}
      ></div>
    </div>
  );
};

export default Skeleton;
