import React, { useEffect, useState } from "react";
import BoxHistory from "../BoxHistory";
import PercentageText from "../PercentageText";
import { Chart as ChartJS, ArcElement, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import useBoundsUI from "../../../../hooks/useBoundsUI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { Tooltip } from "react-tooltip";

ChartJS.register(ArcElement, Legend);

interface CropData {
  class_names: string;
  farmable: boolean;
  frequency: number;
  objectid: number;
  pct: string;
  rgb: string;
}

interface CropDisplayInfo {
  name: string;
  color: string;
  avg: number;
}

const CropHistory = () => {
  const [yearlyData, setYearlyData] = useState<[string, CropData[]][]>();
  const [dataSummary, setDataSummary] = useState<CropDisplayInfo[]>();
  const {
    featureLayer: { setGroup },
    insightData: { data, insightError },
  } = useBoundsUI();
  const isLoadingData = data === null;
  let otherTotal = 0;
  const RGBToHex = (rgb: string) => {
    const rgbArr = rgb.split(",");
    let r = (+rgbArr[0]).toString(16),
      g = (+rgbArr[1]).toString(16),
      b = (+rgbArr[2]).toString(16);
    if (r.length == 1) r = "0" + r;
    if (g.length == 1) g = "0" + g;
    if (b.length == 1) b = "0" + b;
    return "#" + r + g + b;
  };

  useEffect(() => {
    setGroup({
      groupName: "CDLGroupLayer",
      layers: ["CDL2023Layer"],
    });
    return () => {
      setGroup({
        groupName: "CDLGroupLayer",
        layers: null,
      });
    };
  }, []);

  useEffect(() => {
    try {
      if (data) {
        const newYearlyData = Object.entries(data).sort(([y1], [y2]) => {
          return parseInt(y2) - parseInt(y1);
        });
        const crop_summary: CropDisplayInfo[] = [];
        const numYears = newYearlyData.length;
        newYearlyData.forEach(([year, stats]) => {
          stats.forEach((stat: CropData) => {
            year ? "" : ""; //eslint
            const name = stat.class_names;
            const pct_weighted = parseFloat(stat.pct) / numYears;
            const i = crop_summary.findIndex((x) => x.name == name);
            if (i > -1) {
              crop_summary[i].avg += pct_weighted;
            } else {
              if (pct_weighted < 2) {
                otherTotal += pct_weighted;
              } else {
                crop_summary.push({
                  name: name,
                  color: RGBToHex(stat.rgb),
                  avg: pct_weighted,
                });
              }
            }
          });
        });
        if (otherTotal > 0) {
          crop_summary.push({
            name: "Other",
            color: "#CCCCCC", // You can set your desired color for "Other"
            avg: otherTotal,
          });
        }
        setDataSummary(
          crop_summary.sort((a, b) => {
            return b.avg - a.avg;
          })
        );
        setYearlyData(newYearlyData);
      } else {
        setDataSummary([]);
        setYearlyData([]);
      }
    } catch {
      setDataSummary([]);
      setYearlyData([]);
    }
  }, [data]);

  return (
    <div className="min-w-[285px]">
      <div className="flex items-center">
        <h2 className="text-[32px] ">Crop History</h2>
        &nbsp;
        <FontAwesomeIcon className="question-mark" icon={faCircleQuestion} />
        <Tooltip anchorSelect=".question-mark" place="bottom" offset={10}>
          Source: USDA Crop Data Layer
        </Tooltip>
      </div>
      {insightError ? (
        <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6">
          {insightError}
        </p>
      ) : isLoadingData ? (
        <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6">
          Loading...
        </p>
      ) : (data && (Object.keys(data).length > 0)) ? (
        <div className="flex gap-4 flex-col pt-4">
          {dataSummary && (
            <>
              <div className="flex flex-col gap-2">
                {dataSummary.map((item: CropDisplayInfo, index: number) => (
                  <PercentageText
                    key={`summary-${index}`}
                    color={item.color}
                    percentage={
                      item.avg > 0.1 ? Math.round(item.avg * 10) / 10 : "<0.1"
                    }
                  >
                    {item.name}
                  </PercentageText>
                ))}
              </div>

              <div className="max-h-[300px] flex justify-center">
                <Doughnut
                  datasetIdKey="idfjjjj2j2j2"
                  data={{
                    labels: [],
                    datasets: [
                      {
                        data: dataSummary.map((item: CropDisplayInfo) => {
                          return item.avg;
                        }),
                        backgroundColor: dataSummary.map(
                          (item: CropDisplayInfo) => {
                            return item.color;
                          }
                        ),
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      datalabels: {
                        color: "#fff",
                        anchor: "end",
                        align: "end",
                        formatter: (value: number) => {
                          return value.toFixed(2) + '%';
                        },
                      },
                    },
                    aspectRatio: 4 / 3,
                    layout: {
                      padding: 32,
                    },
                  }}
                />
              </div>
            </>
          )}

          <p className="text-white ml-4">History</p>
          {yearlyData &&
            yearlyData.map(([year, stats]) => {
              const stats_sorted = stats.sort((a: CropData, b: CropData) => {
                return parseFloat(b.pct) - parseFloat(a.pct);
              });
              return (
                <BoxHistory
                  color={RGBToHex(stats_sorted[0].rgb)}
                  year={parseInt(year)}
                  key={"box-" + year}
                >
                  {stats_sorted.map((item: CropData, index: number) => {
                    const val = Math.round(parseFloat(item.pct) * 10) / 10;
                    return val > 0 && (
                      <PercentageText
                        color={RGBToHex(item.rgb)}
                        percentage={val}
                        key={"stat-" + year + "-" + index}
                      >
                        {item.class_names}
                      </PercentageText>
                    );
                  })}
                </BoxHistory>
              );
            })}
        </div>
      ) : (
        <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6">
          Select an area to view data
        </p>
      )}
    </div>
  );
};

export default CropHistory;
