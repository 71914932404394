import React, { useCallback, useEffect, useMemo, useState } from "react";
import useBoundsUI from "../../../../hooks/useBoundsUI";
import clsx from "clsx";
import ArrowDropDownIcon from "../../../icons/ArrowDropDown";
import CardSelection from "./Card";
import MapView from "@arcgis/core/views/MapView";
import { setSelectedParcelLId } from "../../../../redux/actions";
import { useDispatch } from "react-redux";

const SelectionWidget = ({ view }: { view: MapView }) => {
  const dispatch = useDispatch();
  const {
    selection: { parcels, setParcels },
    featureLayer: { setGroup, group },
  } = useBoundsUI();
  const [openMenu, setOpenMenu] = useState(false);

  const handleDeselectAll = useCallback(() => {
    setParcels([]);
    setOpenMenu(false);
    dispatch(setSelectedParcelLId(""));
    if (view) view.graphics.removeAll();
  }, [view]);

  useEffect(() => {
    setOpenMenu(parcels.length != 0);
  }, [parcels]);

  const totalSelectedAcres = useMemo(() => {
    return parcels
      .reduce(
        (sum, current) =>
          sum + Number(current?.attributes?.ACREAGE?.toFixed(2) || 0),
        0
      )
      .toFixed(3);
  }, [parcels]);

  useEffect(() => {
    setGroup({
      groupName: "NavigationGroupLayer",
      layers: ["Parcellightboxlayer"],
    });
  }, [setGroup]);

  return (
    <div
      className={clsx(
        "bg-boundsPurple-700 px-3 relative w-full",
        parcels && parcels?.length > 0 && "min-w-72",
        openMenu ? "rounded-t-[5px]" : "rounded-[5px]"
      )}
    >
      <div className="py-4 flex gap-3">
        <div className="text-lg font-bold">{totalSelectedAcres} Acres</div>
        <div className="border-l border-[#7E7E7E] h-6"></div>
        <button
          className="flex gap-8 items-center justify-between"
          onClick={() => setOpenMenu(!openMenu)}
          disabled={!parcels || parcels.length === 0}
        >
          {parcels.length} Selections <ArrowDropDownIcon />
        </button>
      </div>
      {openMenu && parcels?.length >= 0 && (
        <div className="absolute w-full left-0 top-14 bg-boundsPurple-700 flex flex-col rounded-b-[5px] h-auto max-h-[calc(100vh-5rem)] overflow-y-auto">
          <div className="w-full px-6 flex flex-col items-center">
            <hr className="w-full border-[#F2C94C]" />
            <button
              className="m-auto bg-boundsGray-500 py-2 px-6 rounded-[5px] my-6 text-boundsGray-100 font-medium"
              onClick={handleDeselectAll}
            >
              Deselect All
            </button>
            <hr className="w-full border-[#F2C94C]" />
          </div>
          <div className="px-6 overflow-y-auto">
            <div className="py-6 flex flex-col gap-6 h-full mb-6">
              {parcels?.map((item, index) => (
                <CardSelection
                  key={`selection-${item.attributes.OBJECTID}`}
                  {...item}
                  index={index}
                  view={view}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectionWidget;
