import { AxiosBalancer } from "../api";
import { IDashboardFilters } from "../types/financialOverview";
import {
  IInterestRateResponse,
  IMortgageHolderResponse,
  IRateAnalysisParams,
  RateAnalysisResponse,
} from "../types/mortgage";
import { IUccSearchParams, IUccSearchResponse } from "../types/ucc";

const axiosBalancer = new AxiosBalancer();

export const getDropdownData = async (params: IDashboardFilters): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/dashboard/dropdown-data",
      method: "get",
      params: params
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getMonthlyMortgages = async (params: IDashboardFilters, abortSignal: AbortSignal): Promise<any[] | null> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/dashboard/monthly-mortgages",
      method: "get",
      params: params,
      signal: abortSignal
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getTotalMortgages = async (params: IDashboardFilters, abortSignal: AbortSignal): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/dashboard/total-mortgages",
      method: "get",
      params: params,
      signal: abortSignal
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getUccFiled = async (params: IDashboardFilters, abortSignal: AbortSignal): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/dashboard/total-ucc",
      method: "get",
      params: params,
      signal: abortSignal
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getTotalLoan = async (params: IDashboardFilters, abortSignal: AbortSignal): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/dashboard/total-loan-amount",
      method: "get",
      params: params,
      signal: abortSignal
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getEstimatedInterest = async (params: IDashboardFilters, abortSignal: AbortSignal): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/dashboard/estimated-interest",
      method: "get",
      params: params,
      signal: abortSignal
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getTotalAcreCount = async (params: IDashboardFilters, abortSignal: AbortSignal): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/dashboard/total-acre-count",
      method: "get",
      params: params,
      signal: abortSignal
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getFarmableAcres = async (params: IDashboardFilters, abortSignal: AbortSignal): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/dashboard/total-farmable-acres",
      method: "get",
      params: params,
      signal: abortSignal
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const getMortgageHolders = async ({
  page,
  page_size,
  sort_by,
  sort_order,
  search,
  params,
  abortSignal
}: {
  page: number;
  page_size: number;
  sort_by: string;
  sort_order: string;
  search?: string;
  params?: IDashboardFilters;
  abortSignal?: AbortSignal
}): Promise<IMortgageHolderResponse | null> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/mortgage/holder",
      method: "get",
      params: {
        page,
        page_size,
        sort_by,
        sort_order,
        search,
        start_date: params?.start_date,
        end_date: params?.end_date,
        loan_institution: params?.loan_institution,
        county: params?.county,
        city: params?.city,
        parcel_lid: params?.parcel_lid,
      },
      signal: abortSignal
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

/* Mortgage */

export const rateAnalysis = async (
  params: IRateAnalysisParams, abortSignal: AbortSignal
): Promise<RateAnalysisResponse | null> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/mortgage/rate-analysis",
      method: "get",
      params: { ...params },
      signal: abortSignal
    });
    return response.data as RateAnalysisResponse;
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response;
      if (status === 401) {
        // Handle unauthorized error specifically
        return data as RateAnalysisResponse;
      }
      // Log other HTTP errors
      console.error(`HTTP Error: ${status}`, data);
    } else {
      // Log network or other errors
      console.error("Network or other error:", error.message);
    }
    return null;
  }
};

export const getInterestRates = async (
  params: { start_date: string; end_date: string }
): Promise<IInterestRateResponse[] | null> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/mortgage/interest-rates",
      method: "get",
      params: { ...params },
    });
    return response.data as IInterestRateResponse[];
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

/* UCC */

export const uccSearch = async (
  params: IUccSearchParams, abortSignal: AbortSignal
): Promise<IUccSearchResponse | null> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/ucc",
      method: "get",
      params: { ...params },
      signal: abortSignal
    });
    return response.data as IUccSearchResponse;
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response;
      console.error(`HTTP Error: ${status}`, data);
    } else {
      console.error("Network or other error:", error.message);
    }
    return null;
  }
};

export const getUccFile = async (filepath: string): Promise<any> => {
  try {
    const response = await axiosBalancer.request({
      url: "/api/ucc/file",
      method: "get",
      params: { filepath },
      responseType: "arraybuffer",
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      console.error("Error getting file");
      return null;
    }
  }
};
