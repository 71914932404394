import MapView from "@arcgis/core/views/MapView";
import React from "react";
import ZoomInIcon from "../../../icons/ZoomIn.icon";
import ZoomOutIcon from "../../../icons/ZoomOut.icon";

const ZoomControls = ({ view }: { view: MapView }) => {
  const zoomIn = () => {
    if (view) {
      view.zoom += 1;
    }
  };

  const zoomOut = () => {
    if (view) {
      view.zoom -= 1;
    }
  };
  return (
    <div className="bg-boundsPurple-700 p-3 rounded-[5px] flex gap-6 justify-between">
      <button onClick={() => view && zoomIn()}>
        {" "}
        <ZoomInIcon />{" "}
      </button>
      <div className="border-l border-[#7E7E7E] h-6 "></div>
      <button onClick={() => view && zoomOut()}>
        <ZoomOutIcon />{" "}
      </button>
    </div>
  );
};

export default ZoomControls;
