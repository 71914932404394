export const counties = [
  {
    County: 'Autauga County',
    value: 'AUTAUGA',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Baldwin County',
    value: 'BALDWIN',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Barbour County',
    value: 'BARBOUR',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Bibb County',
    value: 'BIBB',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Blount County',
    value: 'BLOUNT',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Bullock County',
    value: 'BULLOCK',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Butler County',
    value: 'BUTLER',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Calhoun County',
    value: 'CALHOUN',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Chambers County',
    value: 'CHAMBERS',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Cherokee County',
    value: 'CHEROKEE',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Chilton County',
    value: 'CHILTON',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Choctaw County',
    value: 'CHOCTAW',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Clarke County',
    value: 'CLARKE',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Clay County',
    value: 'CLAY',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Cleburne County',
    value: 'CLEBURNE',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Coffee County',
    value: 'COFFEE',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Colbert County',
    value: 'COLBERT',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Conecuh County',
    value: 'CONECUH',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Coosa County',
    value: 'COOSA',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Covington County',
    value: 'COVINGTON',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Crenshaw County',
    value: 'CRENSHAW',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Cullman County',
    value: 'CULLMAN',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Dale County',
    value: 'DALE',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Dallas County',
    value: 'DALLAS',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'DeKalb County',
    value: 'DEKALB',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Elmore County',
    value: 'ELMORE',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Escambia County',
    value: 'ESCAMBIA',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Etowah County',
    value: 'ETOWAH',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Fayette County',
    value: 'FAYETTE',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Geneva County',
    value: 'GENEVA',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Greene County',
    value: 'GREENE',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Hale County',
    value: 'HALE',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Henry County',
    value: 'HENRY',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Houston County',
    value: 'HOUSTON',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Lamar County',
    value: 'LAMAR',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Lauderdale County',
    value: 'LAUDERDALE',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Lawrence County',
    value: 'LAWRENCE',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Lee County',
    value: 'LEE',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Limestone County',
    value: 'LIMESTONE',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Lowndes County',
    value: 'LOWNDES',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Macon County',
    value: 'MACON',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Marengo County',
    value: 'MARENGO',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Marion County',
    value: 'MARION',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Marshall County',
    value: 'MARSHALL',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Mobile County',
    value: 'MOBILE',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Monroe County',
    value: 'MONROE',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Montgomery County',
    value: 'MONTGOMERY',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Morgan County',
    value: 'MORGAN',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Perry County',
    value: 'PERRY',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Pickens County',
    value: 'PICKENS',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Pike County',
    value: 'PIKE',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Randolph County',
    value: 'RANDOLPH',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Russell County',
    value: 'RUSSELL',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'St. Clair County',
    value: 'ST.',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Shelby County',
    value: 'SHELBY',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Sumter County',
    value: 'SUMTER',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Talladega County',
    value: 'TALLADEGA',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Tallapoosa County',
    value: 'TALLAPOOSA',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Tuscaloosa County',
    value: 'TUSCALOOSA',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Walker County',
    value: 'WALKER',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Wilcox County',
    value: 'WILCOX',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Winston County',
    value: 'WINSTON',
    // State: "Alabama",
    State: 'AL',
  },
  {
    County: 'Aleutians East Borough',
    value: 'ALEUTIANS',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Aleutians West Census Area',
    value: 'ALEUTIANS',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Anchorage, Municipality of',
    value: 'ANCHORAGE,',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Bethel Census Area',
    value: 'BETHEL',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Bristol Bay Borough',
    value: 'BRISTOL',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Denali Borough',
    value: 'DENALI',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Dillingham Census Area',
    value: 'DILLINGHAM',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Fairbanks North Star Borough',
    value: 'FAIRBANKS',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Haines Borough',
    value: 'HAINES',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Hoonah-Angoon Census Area',
    value: 'HOONAH-ANGOON',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Juneau, City and Borough',
    value: 'JUNEAU,',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Kenai Peninsula Borough',
    value: 'KENAI',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Ketchikan Gateway Borough',
    value: 'KETCHIKAN',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Kodiak Island Borough',
    value: 'KODIAK',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Lake and Peninsula Borough',
    value: 'LAKE',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Matanuska-Susitna Borough',
    value: 'MATANUSKA-SUSITNA',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Nome Census Area',
    value: 'NOME',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'North Slope Borough',
    value: 'NORTH',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Northwest Arctic Borough',
    value: 'NORTHWEST',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Petersburg Census Area',
    value: 'PETERSBURG',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Prince of Wales-Hyder Census Area',
    value: 'PRINCE',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Sitka, City and Borough of',
    value: 'SITKA,',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Skagway Borough, Municipality of',
    value: 'SKAGWAY',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Southeast Fairbanks Census Area',
    value: 'SOUTHEAST',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Valdez-Cordova Census Area',
    value: 'VALDEZ-CORDOVA',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Wade Hampton Census Area',
    value: 'WADE',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Wrangell, City and Borough of',
    value: 'WRANGELL,',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Yakutat, City and Borough of',
    value: 'YAKUTAT,',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Yukon-Koyukuk Census Area',
    value: 'YUKON-KOYUKUK',
    // State: "Alaska",
    State: 'AK',
  },
  {
    County: 'Apache County',
    value: 'APACHE',
    // State: "Arizona",
    State: 'AZ',
  },
  {
    County: 'Cochise County',
    value: 'COCHISE',
    // State: "Arizona",
    State: 'AZ',
  },
  {
    County: 'Coconino County',
    value: 'COCONINO',
    // State: "Arizona",
    State: 'AZ',
  },
  {
    County: 'Gila County',
    value: 'GILA',
    // State: "Arizona",
    State: 'AZ',
  },
  {
    County: 'Graham County',
    value: 'GRAHAM',
    // State: "Arizona",
    State: 'AZ',
  },
  {
    County: 'Greenlee County',
    value: 'GREENLEE',
    // State: "Arizona",
    State: 'AZ',
  },
  {
    County: 'La Paz County',
    value: 'LA',
    // State: "Arizona",
    State: 'AZ',
  },
  {
    County: 'Maricopa County',
    value: 'MARICOPA',
    // State: "Arizona",
    State: 'AZ',
  },
  {
    County: 'Mohave County',
    value: 'MOHAVE',
    // State: "Arizona",
    State: 'AZ',
  },
  {
    County: 'Navajo County',
    value: 'NAVAJO',
    // State: "Arizona",
    State: 'AZ',
  },
  {
    County: 'Pima County',
    value: 'PIMA',
    // State: "Arizona",
    State: 'AZ',
  },
  {
    County: 'Pinal County',
    value: 'PINAL',
    // State: "Arizona",
    State: 'AZ',
  },
  {
    County: 'Santa Cruz County',
    value: 'SANTA',
    // State: "Arizona",
    State: 'AZ',
  },
  {
    County: 'Yavapai County',
    value: 'YAVAPAI',
    // State: "Arizona",
    State: 'AZ',
  },
  {
    County: 'Yuma County',
    value: 'YUMA',
    // State: "Arizona",
    State: 'AZ',
  },
  {
    County: 'Arkansas County',
    value: 'ARKANSAS',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Ashley County',
    value: 'ASHLEY',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Baxter County',
    value: 'BAXTER',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Benton County',
    value: 'BENTON',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Boone County',
    value: 'BOONE',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Bradley County',
    value: 'BRADLEY',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Calhoun County',
    value: 'CALHOUN',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Carroll County',
    value: 'CARROLL',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Chicot County',
    value: 'CHICOT',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Clark County',
    value: 'CLARK',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Clay County',
    value: 'CLAY',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Cleburne County',
    value: 'CLEBURNE',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Cleveland County',
    value: 'CLEVELAND',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Columbia County',
    value: 'COLUMBIA',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Conway County',
    value: 'CONWAY',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Craighead County',
    value: 'CRAIGHEAD',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Crawford County',
    value: 'CRAWFORD',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Crittenden County',
    value: 'CRITTENDEN',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Cross County',
    value: 'CROSS',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Dallas County',
    value: 'DALLAS',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Desha County',
    value: 'DESHA',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Drew County',
    value: 'DREW',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Faulkner County',
    value: 'FAULKNER',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Fulton County',
    value: 'FULTON',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Garland County',
    value: 'GARLAND',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Grant County',
    value: 'GRANT',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Greene County',
    value: 'GREENE',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Hempstead County',
    value: 'HEMPSTEAD',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Hot Spring County',
    value: 'HOT',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Howard County',
    value: 'HOWARD',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Independence County',
    value: 'INDEPENDENCE',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Izard County',
    value: 'IZARD',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Johnson County',
    value: 'JOHNSON',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Lafayette County',
    value: 'LAFAYETTE',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Lawrence County',
    value: 'LAWRENCE',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Lee County',
    value: 'LEE',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Little River County',
    value: 'LITTLE',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Logan County',
    value: 'LOGAN',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Lonoke County',
    value: 'LONOKE',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Marion County',
    value: 'MARION',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Miller County',
    value: 'MILLER',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Mississippi County',
    value: 'MISSISSIPPI',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Monroe County',
    value: 'MONROE',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Montgomery County',
    value: 'MONTGOMERY',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Nevada County',
    value: 'NEVADA',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Newton County',
    value: 'NEWTON',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Ouachita County',
    value: 'OUACHITA',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Perry County',
    value: 'PERRY',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Phillips County',
    value: 'PHILLIPS',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Pike County',
    value: 'PIKE',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Poinsett County',
    value: 'POINSETT',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Polk County',
    value: 'POLK',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Pope County',
    value: 'POPE',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Prairie County',
    value: 'PRAIRIE',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Pulaski County',
    value: 'PULASKI',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Randolph County',
    value: 'RANDOLPH',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'St. Francis County',
    value: 'ST.',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Saline County',
    value: 'SALINE',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Scott County',
    value: 'SCOTT',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Searcy County',
    value: 'SEARCY',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Sebastian County',
    value: 'SEBASTIAN',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Sevier County',
    value: 'SEVIER',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Sharp County',
    value: 'SHARP',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Stone County',
    value: 'STONE',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Union County',
    value: 'UNION',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Van Buren County',
    value: 'VAN',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'White County',
    value: 'WHITE',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Woodruff County',
    value: 'WOODRUFF',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Yell County',
    value: 'YELL',
    // State: "Arkansas",
    State: 'AR',
  },
  {
    County: 'Alameda County',
    value: 'ALAMEDA',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Alpine County',
    value: 'ALPINE',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Amador County',
    value: 'AMADOR',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Butte County',
    value: 'BUTTE',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Calaveras County',
    value: 'CALAVERAS',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Colusa County',
    value: 'COLUSA',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Contra Costa County',
    value: 'CONTRA',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Del Norte County',
    value: 'DEL',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'El Dorado County',
    value: 'EL',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Fresno County',
    value: 'FRESNO',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Glenn County',
    value: 'GLENN',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Humboldt County',
    value: 'HUMBOLDT',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Imperial County',
    value: 'IMPERIAL',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Inyo County',
    value: 'INYO',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Kern County',
    value: 'KERN',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Kings County',
    value: 'KINGS',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Lake County',
    value: 'LAKE',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Lassen County',
    value: 'LASSEN',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Los Angeles County',
    value: 'LOS',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Madera County',
    value: 'MADERA',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Marin County',
    value: 'MARIN',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Mariposa County',
    value: 'MARIPOSA',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Mendocino County',
    value: 'MENDOCINO',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Merced County',
    value: 'MERCED',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Modoc County',
    value: 'MODOC',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Mono County',
    value: 'MONO',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Monterey County',
    value: 'MONTEREY',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Napa County',
    value: 'NAPA',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Nevada County',
    value: 'NEVADA',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Orange County',
    value: 'ORANGE',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Placer County',
    value: 'PLACER',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Plumas County',
    value: 'PLUMAS',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Riverside County',
    value: 'RIVERSIDE',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Sacramento County',
    value: 'SACRAMENTO',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'San Benito County',
    value: 'SAN',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'San Bernardino County',
    value: 'SAN',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'San Diego County',
    value: 'SAN',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'San Francisco, City and County of',
    value: 'SAN',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'San Joaquin County',
    value: 'SAN',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'San Luis Obispo County',
    value: 'SAN',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'San Mateo County',
    value: 'SAN',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Santa Barbara County',
    value: 'SANTA',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Santa Clara County',
    value: 'SANTA',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Santa Cruz County',
    value: 'SANTA',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Shasta County',
    value: 'SHASTA',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Sierra County',
    value: 'SIERRA',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Siskiyou County',
    value: 'SISKIYOU',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Solano County',
    value: 'SOLANO',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Sonoma County',
    value: 'SONOMA',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Stanislaus County',
    value: 'STANISLAUS',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Sutter County',
    value: 'SUTTER',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Tehama County',
    value: 'TEHAMA',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Trinity County',
    value: 'TRINITY',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Tulare County',
    value: 'TULARE',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Tuolumne County',
    value: 'TUOLUMNE',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Ventura County',
    value: 'VENTURA',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Yolo County',
    value: 'YOLO',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Yuba County',
    value: 'YUBA',
    // State: "California",
    State: 'CA',
  },
  {
    County: 'Adams County',
    value: 'ADAMS',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Alamosa County',
    value: 'ALAMOSA',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Arapahoe County',
    value: 'ARAPAHOE',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Archuleta County',
    value: 'ARCHULETA',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Baca County',
    value: 'BACA',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Bent County',
    value: 'BENT',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Boulder County',
    value: 'BOULDER',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Broomfield, City and County of',
    value: 'BROOMFIELD,',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Chaffee County',
    value: 'CHAFFEE',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Cheyenne County',
    value: 'CHEYENNE',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Clear Creek County',
    value: 'CLEAR',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Conejos County',
    value: 'CONEJOS',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Costilla County',
    value: 'COSTILLA',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Crowley County',
    value: 'CROWLEY',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Custer County',
    value: 'CUSTER',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Delta County',
    value: 'DELTA',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Denver, City and County of',
    value: 'DENVER,',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Dolores County',
    value: 'DOLORES',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Douglas County',
    value: 'DOUGLAS',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Eagle County',
    value: 'EAGLE',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Elbert County',
    value: 'ELBERT',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'El Paso County',
    value: 'EL',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Fremont County',
    value: 'FREMONT',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Garfield County',
    value: 'GARFIELD',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Gilpin County',
    value: 'GILPIN',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Grand County',
    value: 'GRAND',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Gunnison County',
    value: 'GUNNISON',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Hinsdale County',
    value: 'HINSDALE',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Huerfano County',
    value: 'HUERFANO',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Kiowa County',
    value: 'KIOWA',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Kit Carson County',
    value: 'KIT',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Lake County',
    value: 'LAKE',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'La Plata County',
    value: 'LA',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Larimer County',
    value: 'LARIMER',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Las Animas County',
    value: 'LAS',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Logan County',
    value: 'LOGAN',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Mesa County',
    value: 'MESA',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Mineral County',
    value: 'MINERAL',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Moffat County',
    value: 'MOFFAT',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Montezuma County',
    value: 'MONTEZUMA',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Montrose County',
    value: 'MONTROSE',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Morgan County',
    value: 'MORGAN',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Otero County',
    value: 'OTERO',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Ouray County',
    value: 'OURAY',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Park County',
    value: 'PARK',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Phillips County',
    value: 'PHILLIPS',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Pitkin County',
    value: 'PITKIN',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Prowers County',
    value: 'PROWERS',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Pueblo County',
    value: 'PUEBLO',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Rio Blanco County',
    value: 'RIO',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Rio Grande County',
    value: 'RIO',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Routt County',
    value: 'ROUTT',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Saguache County',
    value: 'SAGUACHE',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'San Juan County',
    value: 'SAN',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'San Miguel County',
    value: 'SAN',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Sedgwick County',
    value: 'SEDGWICK',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Summit County',
    value: 'SUMMIT',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Teller County',
    value: 'TELLER',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Weld County',
    value: 'WELD',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Yuma County',
    value: 'YUMA',
    // State: "Colorado",
    State: 'CO',
  },
  {
    County: 'Fairfield County',
    value: 'FAIRFIELD',
    // State: "Connecticut",
    State: 'CT',
  },
  {
    County: 'Hartford County',
    value: 'HARTFORD',
    // State: "Connecticut",
    State: 'CT',
  },
  {
    County: 'Litchfield County',
    value: 'LITCHFIELD',
    // State: "Connecticut",
    State: 'CT',
  },
  {
    County: 'Middlesex County',
    value: 'MIDDLESEX',
    // State: "Connecticut",
    State: 'CT',
  },
  {
    County: 'New Haven County',
    value: 'NEW',
    // State: "Connecticut",
    State: 'CT',
  },
  {
    County: 'New London County',
    value: 'NEW',
    // State: "Connecticut",
    State: 'CT',
  },
  {
    County: 'Tolland County',
    value: 'TOLLAND',
    // State: "Connecticut",
    State: 'CT',
  },
  {
    County: 'Windham County',
    value: 'WINDHAM',
    // State: "Connecticut",
    State: 'CT',
  },
  {
    County: 'Kent County',
    value: 'KENT',
    // State: "Delaware",
    State: 'DE',
  },
  {
    County: 'New Castle County',
    value: 'NEW',
    // State: "Delaware",
    State: 'DE',
  },
  {
    County: 'Sussex County',
    value: 'SUSSEX',
    // State: "Delaware",
    State: 'DE',
  },
  {
    County: 'District of Columbia',
    value: 'DISTRICT',
    // State: "District of Columbia",
    State: '',
  },
  {
    County: 'Alachua County',
    value: 'ALACHUA',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Baker County',
    value: 'BAKER',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Bay County',
    value: 'BAY',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Bradford County',
    value: 'BRADFORD',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Brevard County',
    value: 'BREVARD',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Broward County',
    value: 'BROWARD',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Calhoun County',
    value: 'CALHOUN',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Charlotte County',
    value: 'CHARLOTTE',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Citrus County',
    value: 'CITRUS',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Clay County',
    value: 'CLAY',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Collier County',
    value: 'COLLIER',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Columbia County',
    value: 'COLUMBIA',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'DeSoto County',
    value: 'DESOTO',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Dixie County',
    value: 'DIXIE',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Duval County',
    value: 'DUVAL',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Escambia County',
    value: 'ESCAMBIA',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Flagler County',
    value: 'FLAGLER',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Gadsden County',
    value: 'GADSDEN',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Gilchrist County',
    value: 'GILCHRIST',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Glades County',
    value: 'GLADES',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Gulf County',
    value: 'GULF',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Hamilton County',
    value: 'HAMILTON',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Hardee County',
    value: 'HARDEE',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Hendry County',
    value: 'HENDRY',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Hernando County',
    value: 'HERNANDO',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Highlands County',
    value: 'HIGHLANDS',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Hillsborough County',
    value: 'HILLSBOROUGH',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Holmes County',
    value: 'HOLMES',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Indian River County',
    value: 'INDIAN',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Lafayette County',
    value: 'LAFAYETTE',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Lake County',
    value: 'LAKE',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Lee County',
    value: 'LEE',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Leon County',
    value: 'LEON',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Levy County',
    value: 'LEVY',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Liberty County',
    value: 'LIBERTY',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Manatee County',
    value: 'MANATEE',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Marion County',
    value: 'MARION',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Martin County',
    value: 'MARTIN',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Miami-Dade County',
    value: 'MIAMI-DADE',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Monroe County',
    value: 'MONROE',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Nassau County',
    value: 'NASSAU',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Okaloosa County',
    value: 'OKALOOSA',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Okeechobee County',
    value: 'OKEECHOBEE',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Orange County',
    value: 'ORANGE',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Osceola County',
    value: 'OSCEOLA',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Palm Beach County',
    value: 'PALM',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Pasco County',
    value: 'PASCO',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Pinellas County',
    value: 'PINELLAS',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Polk County',
    value: 'POLK',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Putnam County',
    value: 'PUTNAM',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'St. Johns County',
    value: 'ST.',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'St. Lucie County',
    value: 'ST.',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Santa Rosa County',
    value: 'SANTA',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Sarasota County',
    value: 'SARASOTA',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Seminole County',
    value: 'SEMINOLE',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Sumter County',
    value: 'SUMTER',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Suwannee County',
    value: 'SUWANNEE',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Taylor County',
    value: 'TAYLOR',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Union County',
    value: 'UNION',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Volusia County',
    value: 'VOLUSIA',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Wakulla County',
    value: 'WAKULLA',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Walton County',
    value: 'WALTON',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Florida",
    State: 'FL',
  },
  {
    County: 'Appling County',
    value: 'APPLING',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Atkinson County',
    value: 'ATKINSON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Bacon County',
    value: 'BACON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Baker County',
    value: 'BAKER',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Baldwin County',
    value: 'BALDWIN',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Banks County',
    value: 'BANKS',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Barrow County',
    value: 'BARROW',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Bartow County',
    value: 'BARTOW',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Ben Hill County',
    value: 'BEN',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Berrien County',
    value: 'BERRIEN',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Bibb County',
    value: 'BIBB',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Bleckley County',
    value: 'BLECKLEY',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Brantley County',
    value: 'BRANTLEY',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Brooks County',
    value: 'BROOKS',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Bryan County',
    value: 'BRYAN',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Bulloch County',
    value: 'BULLOCH',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Burke County',
    value: 'BURKE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Butts County',
    value: 'BUTTS',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Calhoun County',
    value: 'CALHOUN',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Camden County',
    value: 'CAMDEN',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Candler County',
    value: 'CANDLER',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Carroll County',
    value: 'CARROLL',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Catoosa County',
    value: 'CATOOSA',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Charlton County',
    value: 'CHARLTON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Chatham County',
    value: 'CHATHAM',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Chattahoochee County',
    value: 'CHATTAHOOCHEE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Chattooga County',
    value: 'CHATTOOGA',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Cherokee County',
    value: 'CHEROKEE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Clarke County',
    value: 'CLARKE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Clay County',
    value: 'CLAY',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Clayton County',
    value: 'CLAYTON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Clinch County',
    value: 'CLINCH',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Cobb County',
    value: 'COBB',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Coffee County',
    value: 'COFFEE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Colquitt County',
    value: 'COLQUITT',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Columbia County',
    value: 'COLUMBIA',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Cook County',
    value: 'COOK',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Coweta County',
    value: 'COWETA',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Crawford County',
    value: 'CRAWFORD',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Crisp County',
    value: 'CRISP',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Dade County',
    value: 'DADE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Dawson County',
    value: 'DAWSON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Decatur County',
    value: 'DECATUR',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'DeKalb County',
    value: 'DEKALB',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Dodge County',
    value: 'DODGE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Dooly County',
    value: 'DOOLY',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Dougherty County',
    value: 'DOUGHERTY',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Douglas County',
    value: 'DOUGLAS',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Early County',
    value: 'EARLY',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Echols County',
    value: 'ECHOLS',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Effingham County',
    value: 'EFFINGHAM',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Elbert County',
    value: 'ELBERT',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Emanuel County',
    value: 'EMANUEL',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Evans County',
    value: 'EVANS',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Fannin County',
    value: 'FANNIN',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Fayette County',
    value: 'FAYETTE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Floyd County',
    value: 'FLOYD',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Forsyth County',
    value: 'FORSYTH',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Fulton County',
    value: 'FULTON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Gilmer County',
    value: 'GILMER',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Glascock County',
    value: 'GLASCOCK',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Glynn County',
    value: 'GLYNN',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Gordon County',
    value: 'GORDON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Grady County',
    value: 'GRADY',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Greene County',
    value: 'GREENE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Gwinnett County',
    value: 'GWINNETT',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Habersham County',
    value: 'HABERSHAM',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Hall County',
    value: 'HALL',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Hancock County',
    value: 'HANCOCK',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Haralson County',
    value: 'HARALSON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Harris County',
    value: 'HARRIS',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Hart County',
    value: 'HART',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Heard County',
    value: 'HEARD',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Henry County',
    value: 'HENRY',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Houston County',
    value: 'HOUSTON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Irwin County',
    value: 'IRWIN',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Jasper County',
    value: 'JASPER',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Jeff Davis County',
    value: 'JEFF',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Jenkins County',
    value: 'JENKINS',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Johnson County',
    value: 'JOHNSON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Jones County',
    value: 'JONES',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Lamar County',
    value: 'LAMAR',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Lanier County',
    value: 'LANIER',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Laurens County',
    value: 'LAURENS',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Lee County',
    value: 'LEE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Liberty County',
    value: 'LIBERTY',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Long County',
    value: 'LONG',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Lowndes County',
    value: 'LOWNDES',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Lumpkin County',
    value: 'LUMPKIN',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'McDuffie County',
    value: 'MCDUFFIE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'McIntosh County',
    value: 'MCINTOSH',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Macon County',
    value: 'MACON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Marion County',
    value: 'MARION',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Meriwether County',
    value: 'MERIWETHER',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Miller County',
    value: 'MILLER',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Mitchell County',
    value: 'MITCHELL',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Monroe County',
    value: 'MONROE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Montgomery County',
    value: 'MONTGOMERY',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Morgan County',
    value: 'MORGAN',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Murray County',
    value: 'MURRAY',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Muscogee County',
    value: 'MUSCOGEE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Newton County',
    value: 'NEWTON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Oconee County',
    value: 'OCONEE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Oglethorpe County',
    value: 'OGLETHORPE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Paulding County',
    value: 'PAULDING',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Peach County',
    value: 'PEACH',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Pickens County',
    value: 'PICKENS',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Pierce County',
    value: 'PIERCE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Pike County',
    value: 'PIKE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Polk County',
    value: 'POLK',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Pulaski County',
    value: 'PULASKI',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Putnam County',
    value: 'PUTNAM',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Quitman County',
    value: 'QUITMAN',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Rabun County',
    value: 'RABUN',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Randolph County',
    value: 'RANDOLPH',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Richmond County',
    value: 'RICHMOND',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Rockdale County',
    value: 'ROCKDALE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Schley County',
    value: 'SCHLEY',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Screven County',
    value: 'SCREVEN',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Seminole County',
    value: 'SEMINOLE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Spalding County',
    value: 'SPALDING',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Stephens County',
    value: 'STEPHENS',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Stewart County',
    value: 'STEWART',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Sumter County',
    value: 'SUMTER',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Talbot County',
    value: 'TALBOT',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Taliaferro County',
    value: 'TALIAFERRO',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Tattnall County',
    value: 'TATTNALL',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Taylor County',
    value: 'TAYLOR',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Telfair County',
    value: 'TELFAIR',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Terrell County',
    value: 'TERRELL',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Thomas County',
    value: 'THOMAS',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Tift County',
    value: 'TIFT',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Toombs County',
    value: 'TOOMBS',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Towns County',
    value: 'TOWNS',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Treutlen County',
    value: 'TREUTLEN',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Troup County',
    value: 'TROUP',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Turner County',
    value: 'TURNER',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Twiggs County',
    value: 'TWIGGS',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Union County',
    value: 'UNION',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Upson County',
    value: 'UPSON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Walker County',
    value: 'WALKER',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Walton County',
    value: 'WALTON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Ware County',
    value: 'WARE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Warren County',
    value: 'WARREN',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Wayne County',
    value: 'WAYNE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Webster County',
    value: 'WEBSTER',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Wheeler County',
    value: 'WHEELER',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'White County',
    value: 'WHITE',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Whitfield County',
    value: 'WHITFIELD',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Wilcox County',
    value: 'WILCOX',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Wilkes County',
    value: 'WILKES',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Wilkinson County',
    value: 'WILKINSON',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Worth County',
    value: 'WORTH',
    // State: "Georgia",
    State: 'GA',
  },
  {
    County: 'Hawaii County',
    value: 'HAWAII',
    // State: "Hawai?i",
    State: '',
  },
  {
    County: 'Honolulu, City and County of',
    value: 'HONOLULU,',
    // State: "Hawai?i",
    State: '',
  },
  {
    County: 'Kalawao County',
    value: 'KALAWAO',
    // State: "Hawai?i",
    State: '',
  },
  {
    County: 'Kauai County',
    value: 'KAUAI',
    // State: "Hawai?i",
    State: '',
  },
  {
    County: 'Maui County',
    value: 'MAUI',
    // State: "Hawai?i",
    State: '',
  },
  {
    County: 'Ada County',
    value: 'ADA',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Adams County',
    value: 'ADAMS',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Bannock County',
    value: 'BANNOCK',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Bear Lake County',
    value: 'BEAR',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Benewah County',
    value: 'BENEWAH',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Bingham County',
    value: 'BINGHAM',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Blaine County',
    value: 'BLAINE',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Boise County',
    value: 'BOISE',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Bonner County',
    value: 'BONNER',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Bonneville County',
    value: 'BONNEVILLE',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Boundary County',
    value: 'BOUNDARY',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Butte County',
    value: 'BUTTE',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Camas County',
    value: 'CAMAS',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Canyon County',
    value: 'CANYON',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Caribou County',
    value: 'CARIBOU',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Cassia County',
    value: 'CASSIA',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Clark County',
    value: 'CLARK',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Clearwater County',
    value: 'CLEARWATER',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Custer County',
    value: 'CUSTER',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Elmore County',
    value: 'ELMORE',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Fremont County',
    value: 'FREMONT',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Gem County',
    value: 'GEM',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Gooding County',
    value: 'GOODING',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Idaho County',
    value: 'IDAHO',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Jerome County',
    value: 'JEROME',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Kootenai County',
    value: 'KOOTENAI',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Latah County',
    value: 'LATAH',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Lemhi County',
    value: 'LEMHI',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Lewis County',
    value: 'LEWIS',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Minidoka County',
    value: 'MINIDOKA',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Nez Perce County',
    value: 'NEZ',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Oneida County',
    value: 'ONEIDA',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Owyhee County',
    value: 'OWYHEE',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Payette County',
    value: 'PAYETTE',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Power County',
    value: 'POWER',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Shoshone County',
    value: 'SHOSHONE',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Teton County',
    value: 'TETON',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Twin Falls County',
    value: 'TWIN',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Valley County',
    value: 'VALLEY',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Idaho",
    State: 'ID',
  },
  {
    County: 'Adams County',
    value: 'ADAMS',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Alexander County',
    value: 'ALEXANDER',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Bond County',
    value: 'BOND',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Boone County',
    value: 'BOONE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Brown County',
    value: 'BROWN',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Bureau County',
    value: 'BUREAU',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Calhoun County',
    value: 'CALHOUN',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Carroll County',
    value: 'CARROLL',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Cass County',
    value: 'CASS',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Champaign County',
    value: 'CHAMPAIGN',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Christian County',
    value: 'CHRISTIAN',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Clark County',
    value: 'CLARK',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Clay County',
    value: 'CLAY',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Clinton County',
    value: 'CLINTON',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Coles County',
    value: 'COLES',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Cook County',
    value: 'COOK',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Crawford County',
    value: 'CRAWFORD',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Cumberland County',
    value: 'CUMBERLAND',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'DeKalb County',
    value: 'DEKALB',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'De Witt County',
    value: 'DE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Douglas County',
    value: 'DOUGLAS',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'DuPage County',
    value: 'DUPAGE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Edgar County',
    value: 'EDGAR',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Edwards County',
    value: 'EDWARDS',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Effingham County',
    value: 'EFFINGHAM',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Fayette County',
    value: 'FAYETTE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Ford County',
    value: 'FORD',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Fulton County',
    value: 'FULTON',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Gallatin County',
    value: 'GALLATIN',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Greene County',
    value: 'GREENE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Grundy County',
    value: 'GRUNDY',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Hamilton County',
    value: 'HAMILTON',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Hancock County',
    value: 'HANCOCK',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Hardin County',
    value: 'HARDIN',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Henderson County',
    value: 'HENDERSON',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Henry County',
    value: 'HENRY',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Iroquois County',
    value: 'IROQUOIS',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Jasper County',
    value: 'JASPER',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Jersey County',
    value: 'JERSEY',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Jo Daviess County',
    value: 'JO',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Johnson County',
    value: 'JOHNSON',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Kane County',
    value: 'KANE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Kankakee County',
    value: 'KANKAKEE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Kendall County',
    value: 'KENDALL',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Knox County',
    value: 'KNOX',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Lake County',
    value: 'LAKE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'LaSalle County',
    value: 'LASALLE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Lawrence County',
    value: 'LAWRENCE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Lee County',
    value: 'LEE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Livingston County',
    value: 'LIVINGSTON',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Logan County',
    value: 'LOGAN',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'McDonough County',
    value: 'MCDONOUGH',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'McHenry County',
    value: 'MCHENRY',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'McLean County',
    value: 'MCLEAN',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Macon County',
    value: 'MACON',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Macoupin County',
    value: 'MACOUPIN',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Marion County',
    value: 'MARION',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Marshall County',
    value: 'MARSHALL',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Mason County',
    value: 'MASON',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Massac County',
    value: 'MASSAC',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Menard County',
    value: 'MENARD',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Mercer County',
    value: 'MERCER',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Monroe County',
    value: 'MONROE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Montgomery County',
    value: 'MONTGOMERY',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Morgan County',
    value: 'MORGAN',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Moultrie County',
    value: 'MOULTRIE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Ogle County',
    value: 'OGLE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Peoria County',
    value: 'PEORIA',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Perry County',
    value: 'PERRY',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Piatt County',
    value: 'PIATT',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Pike County',
    value: 'PIKE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Pope County',
    value: 'POPE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Pulaski County',
    value: 'PULASKI',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Putnam County',
    value: 'PUTNAM',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Randolph County',
    value: 'RANDOLPH',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Richland County',
    value: 'RICHLAND',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Rock Island County',
    value: 'ROCK',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'St. Clair County',
    value: 'ST.',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Saline County',
    value: 'SALINE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Sangamon County',
    value: 'SANGAMON',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Schuyler County',
    value: 'SCHUYLER',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Scott County',
    value: 'SCOTT',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Shelby County',
    value: 'SHELBY',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Stark County',
    value: 'STARK',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Stephenson County',
    value: 'STEPHENSON',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Tazewell County',
    value: 'TAZEWELL',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Union County',
    value: 'UNION',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Vermilion County',
    value: 'VERMILION',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Wabash County',
    value: 'WABASH',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Warren County',
    value: 'WARREN',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Wayne County',
    value: 'WAYNE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'White County',
    value: 'WHITE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Whiteside County',
    value: 'WHITESIDE',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Will County',
    value: 'WILL',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Williamson County',
    value: 'WILLIAMSON',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Winnebago County',
    value: 'WINNEBAGO',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Woodford County',
    value: 'WOODFORD',
    // State: "Illinois",
    State: 'IL',
  },
  {
    County: 'Adams County',
    value: 'ADAMS',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Allen County',
    value: 'ALLEN',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Bartholomew County',
    value: 'BARTHOLOMEW',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Benton County',
    value: 'BENTON',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Blackford County',
    value: 'BLACKFORD',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Boone County',
    value: 'BOONE',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Brown County',
    value: 'BROWN',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Carroll County',
    value: 'CARROLL',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Cass County',
    value: 'CASS',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Clark County',
    value: 'CLARK',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Clay County',
    value: 'CLAY',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Clinton County',
    value: 'CLINTON',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Crawford County',
    value: 'CRAWFORD',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Daviess County',
    value: 'DAVIESS',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Dearborn County',
    value: 'DEARBORN',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Decatur County',
    value: 'DECATUR',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'DeKalb County',
    value: 'DEKALB',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Delaware County',
    value: 'DELAWARE',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Dubois County',
    value: 'DUBOIS',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Elkhart County',
    value: 'ELKHART',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Fayette County',
    value: 'FAYETTE',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Floyd County',
    value: 'FLOYD',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Fountain County',
    value: 'FOUNTAIN',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Fulton County',
    value: 'FULTON',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Gibson County',
    value: 'GIBSON',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Grant County',
    value: 'GRANT',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Greene County',
    value: 'GREENE',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Hamilton County',
    value: 'HAMILTON',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Hancock County',
    value: 'HANCOCK',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Harrison County',
    value: 'HARRISON',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Hendricks County',
    value: 'HENDRICKS',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Henry County',
    value: 'HENRY',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Howard County',
    value: 'HOWARD',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Huntington County',
    value: 'HUNTINGTON',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Jasper County',
    value: 'JASPER',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Jay County',
    value: 'JAY',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Jennings County',
    value: 'JENNINGS',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Johnson County',
    value: 'JOHNSON',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Knox County',
    value: 'KNOX',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Kosciusko County',
    value: 'KOSCIUSKO',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'LaGrange County',
    value: 'LAGRANGE',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Lake County',
    value: 'LAKE',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'LaPorte County',
    value: 'LAPORTE',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Lawrence County',
    value: 'LAWRENCE',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Marion County',
    value: 'MARION',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Marshall County',
    value: 'MARSHALL',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Martin County',
    value: 'MARTIN',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Miami County',
    value: 'MIAMI',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Monroe County',
    value: 'MONROE',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Montgomery County',
    value: 'MONTGOMERY',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Morgan County',
    value: 'MORGAN',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Newton County',
    value: 'NEWTON',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Noble County',
    value: 'NOBLE',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Ohio County',
    value: 'OHIO',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Orange County',
    value: 'ORANGE',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Owen County',
    value: 'OWEN',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Parke County',
    value: 'PARKE',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Perry County',
    value: 'PERRY',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Pike County',
    value: 'PIKE',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Porter County',
    value: 'PORTER',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Posey County',
    value: 'POSEY',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Pulaski County',
    value: 'PULASKI',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Putnam County',
    value: 'PUTNAM',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Randolph County',
    value: 'RANDOLPH',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Ripley County',
    value: 'RIPLEY',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Rush County',
    value: 'RUSH',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'St. Joseph County',
    value: 'ST.',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Scott County',
    value: 'SCOTT',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Shelby County',
    value: 'SHELBY',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Spencer County',
    value: 'SPENCER',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Starke County',
    value: 'STARKE',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Steuben County',
    value: 'STEUBEN',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Sullivan County',
    value: 'SULLIVAN',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Switzerland County',
    value: 'SWITZERLAND',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Tippecanoe County',
    value: 'TIPPECANOE',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Tipton County',
    value: 'TIPTON',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Union County',
    value: 'UNION',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Vanderburgh County',
    value: 'VANDERBURGH',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Vermillion County',
    value: 'VERMILLION',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Vigo County',
    value: 'VIGO',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Wabash County',
    value: 'WABASH',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Warren County',
    value: 'WARREN',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Warrick County',
    value: 'WARRICK',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Wayne County',
    value: 'WAYNE',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Wells County',
    value: 'WELLS',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'White County',
    value: 'WHITE',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Whitley County',
    value: 'WHITLEY',
    // State: "Indiana",
    State: 'IN',
  },
  {
    County: 'Adair County',
    value: 'ADAIR',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Adams County',
    value: 'ADAMS',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Allamakee County',
    value: 'ALLAMAKEE',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Appanoose County',
    value: 'APPANOOSE',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Audubon County',
    value: 'AUDUBON',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Benton County',
    value: 'BENTON',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Black Hawk County',
    value: 'BLACK',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Boone County',
    value: 'BOONE',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Bremer County',
    value: 'BREMER',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Buchanan County',
    value: 'BUCHANAN',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Buena Vista County',
    value: 'BUENA',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Butler County',
    value: 'BUTLER',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Calhoun County',
    value: 'CALHOUN',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Carroll County',
    value: 'CARROLL',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Cass County',
    value: 'CASS',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Cedar County',
    value: 'CEDAR',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Cerro Gordo County',
    value: 'CERRO',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Cherokee County',
    value: 'CHEROKEE',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Chickasaw County',
    value: 'CHICKASAW',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Clarke County',
    value: 'CLARKE',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Clay County',
    value: 'CLAY',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Clayton County',
    value: 'CLAYTON',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Clinton County',
    value: 'CLINTON',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Crawford County',
    value: 'CRAWFORD',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Dallas County',
    value: 'DALLAS',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Davis County',
    value: 'DAVIS',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Decatur County',
    value: 'DECATUR',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Delaware County',
    value: 'DELAWARE',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Des Moines County',
    value: 'DES',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Dickinson County',
    value: 'DICKINSON',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Dubuque County',
    value: 'DUBUQUE',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Emmet County',
    value: 'EMMET',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Fayette County',
    value: 'FAYETTE',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Floyd County',
    value: 'FLOYD',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Fremont County',
    value: 'FREMONT',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Greene County',
    value: 'GREENE',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Grundy County',
    value: 'GRUNDY',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Guthrie County',
    value: 'GUTHRIE',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Hamilton County',
    value: 'HAMILTON',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Hancock County',
    value: 'HANCOCK',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Hardin County',
    value: 'HARDIN',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Harrison County',
    value: 'HARRISON',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Henry County',
    value: 'HENRY',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Howard County',
    value: 'HOWARD',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Humboldt County',
    value: 'HUMBOLDT',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Ida County',
    value: 'IDA',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Iowa County',
    value: 'IOWA',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Jasper County',
    value: 'JASPER',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Johnson County',
    value: 'JOHNSON',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Jones County',
    value: 'JONES',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Keokuk County',
    value: 'KEOKUK',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Kossuth County',
    value: 'KOSSUTH',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Lee County',
    value: 'LEE',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Linn County',
    value: 'LINN',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Louisa County',
    value: 'LOUISA',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Lucas County',
    value: 'LUCAS',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Lyon County',
    value: 'LYON',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Mahaska County',
    value: 'MAHASKA',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Marion County',
    value: 'MARION',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Marshall County',
    value: 'MARSHALL',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Mills County',
    value: 'MILLS',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Mitchell County',
    value: 'MITCHELL',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Monona County',
    value: 'MONONA',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Monroe County',
    value: 'MONROE',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Montgomery County',
    value: 'MONTGOMERY',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Muscatine County',
    value: 'MUSCATINE',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: "O'Brien County",
    value: "O'BRIEN",
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Osceola County',
    value: 'OSCEOLA',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Page County',
    value: 'PAGE',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Palo Alto County',
    value: 'PALO',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Plymouth County',
    value: 'PLYMOUTH',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Pocahontas County',
    value: 'POCAHONTAS',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Polk County',
    value: 'POLK',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Pottawattamie County',
    value: 'POTTAWATTAMIE',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Poweshiek County',
    value: 'POWESHIEK',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Ringgold County',
    value: 'RINGGOLD',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Sac County',
    value: 'SAC',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Scott County',
    value: 'SCOTT',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Shelby County',
    value: 'SHELBY',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Sioux County',
    value: 'SIOUX',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Story County',
    value: 'STORY',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Tama County',
    value: 'TAMA',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Taylor County',
    value: 'TAYLOR',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Union County',
    value: 'UNION',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Van Buren County',
    value: 'VAN',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Wapello County',
    value: 'WAPELLO',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Warren County',
    value: 'WARREN',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Wayne County',
    value: 'WAYNE',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Webster County',
    value: 'WEBSTER',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Winnebago County',
    value: 'WINNEBAGO',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Winneshiek County',
    value: 'WINNESHIEK',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Woodbury County',
    value: 'WOODBURY',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Worth County',
    value: 'WORTH',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Wright County',
    value: 'WRIGHT',
    // State: "Iowa",
    State: 'IA',
  },
  {
    County: 'Allen County',
    value: 'ALLEN',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Anderson County',
    value: 'ANDERSON',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Atchison County',
    value: 'ATCHISON',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Barber County',
    value: 'BARBER',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Barton County',
    value: 'BARTON',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Bourbon County',
    value: 'BOURBON',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Brown County',
    value: 'BROWN',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Butler County',
    value: 'BUTLER',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Chase County',
    value: 'CHASE',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Chautauqua County',
    value: 'CHAUTAUQUA',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Cherokee County',
    value: 'CHEROKEE',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Cheyenne County',
    value: 'CHEYENNE',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Clark County',
    value: 'CLARK',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Clay County',
    value: 'CLAY',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Cloud County',
    value: 'CLOUD',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Coffey County',
    value: 'COFFEY',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Comanche County',
    value: 'COMANCHE',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Cowley County',
    value: 'COWLEY',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Crawford County',
    value: 'CRAWFORD',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Decatur County',
    value: 'DECATUR',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Dickinson County',
    value: 'DICKINSON',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Doniphan County',
    value: 'DONIPHAN',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Douglas County',
    value: 'DOUGLAS',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Edwards County',
    value: 'EDWARDS',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Elk County',
    value: 'ELK',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Ellis County',
    value: 'ELLIS',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Ellsworth County',
    value: 'ELLSWORTH',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Finney County',
    value: 'FINNEY',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Ford County',
    value: 'FORD',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Geary County',
    value: 'GEARY',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Gove County',
    value: 'GOVE',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Graham County',
    value: 'GRAHAM',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Grant County',
    value: 'GRANT',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Gray County',
    value: 'GRAY',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Greeley County',
    value: 'GREELEY',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Greenwood County',
    value: 'GREENWOOD',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Hamilton County',
    value: 'HAMILTON',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Harper County',
    value: 'HARPER',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Harvey County',
    value: 'HARVEY',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Haskell County',
    value: 'HASKELL',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Hodgeman County',
    value: 'HODGEMAN',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Jewell County',
    value: 'JEWELL',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Johnson County',
    value: 'JOHNSON',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Kearny County',
    value: 'KEARNY',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Kingman County',
    value: 'KINGMAN',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Kiowa County',
    value: 'KIOWA',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Labette County',
    value: 'LABETTE',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Lane County',
    value: 'LANE',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Leavenworth County',
    value: 'LEAVENWORTH',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Linn County',
    value: 'LINN',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Logan County',
    value: 'LOGAN',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Lyon County',
    value: 'LYON',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'McPherson County',
    value: 'MCPHERSON',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Marion County',
    value: 'MARION',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Marshall County',
    value: 'MARSHALL',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Meade County',
    value: 'MEADE',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Miami County',
    value: 'MIAMI',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Mitchell County',
    value: 'MITCHELL',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Montgomery County',
    value: 'MONTGOMERY',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Morris County',
    value: 'MORRIS',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Morton County',
    value: 'MORTON',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Nemaha County',
    value: 'NEMAHA',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Neosho County',
    value: 'NEOSHO',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Ness County',
    value: 'NESS',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Norton County',
    value: 'NORTON',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Osage County',
    value: 'OSAGE',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Osborne County',
    value: 'OSBORNE',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Ottawa County',
    value: 'OTTAWA',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Pawnee County',
    value: 'PAWNEE',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Phillips County',
    value: 'PHILLIPS',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Pottawatomie County',
    value: 'POTTAWATOMIE',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Pratt County',
    value: 'PRATT',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Rawlins County',
    value: 'RAWLINS',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Reno County',
    value: 'RENO',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Republic County',
    value: 'REPUBLIC',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Rice County',
    value: 'RICE',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Riley County',
    value: 'RILEY',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Rooks County',
    value: 'ROOKS',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Rush County',
    value: 'RUSH',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Russell County',
    value: 'RUSSELL',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Saline County',
    value: 'SALINE',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Scott County',
    value: 'SCOTT',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Sedgwick County',
    value: 'SEDGWICK',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Seward County',
    value: 'SEWARD',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Shawnee County',
    value: 'SHAWNEE',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Sheridan County',
    value: 'SHERIDAN',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Sherman County',
    value: 'SHERMAN',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Smith County',
    value: 'SMITH',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Stafford County',
    value: 'STAFFORD',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Stanton County',
    value: 'STANTON',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Stevens County',
    value: 'STEVENS',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Sumner County',
    value: 'SUMNER',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Thomas County',
    value: 'THOMAS',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Trego County',
    value: 'TREGO',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Wabaunsee County',
    value: 'WABAUNSEE',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Wallace County',
    value: 'WALLACE',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Wichita County',
    value: 'WICHITA',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Wilson County',
    value: 'WILSON',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Woodson County',
    value: 'WOODSON',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Wyandotte County',
    value: 'WYANDOTTE',
    // State: "Kansas",
    State: 'KS',
  },
  {
    County: 'Adair County',
    value: 'ADAIR',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Allen County',
    value: 'ALLEN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Anderson County',
    value: 'ANDERSON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Ballard County',
    value: 'BALLARD',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Barren County',
    value: 'BARREN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Bath County',
    value: 'BATH',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Bell County',
    value: 'BELL',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Boone County',
    value: 'BOONE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Bourbon County',
    value: 'BOURBON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Boyd County',
    value: 'BOYD',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Boyle County',
    value: 'BOYLE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Bracken County',
    value: 'BRACKEN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Breathitt County',
    value: 'BREATHITT',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Breckinridge County',
    value: 'BRECKINRIDGE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Bullitt County',
    value: 'BULLITT',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Butler County',
    value: 'BUTLER',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Caldwell County',
    value: 'CALDWELL',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Calloway County',
    value: 'CALLOWAY',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Campbell County',
    value: 'CAMPBELL',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Carlisle County',
    value: 'CARLISLE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Carroll County',
    value: 'CARROLL',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Carter County',
    value: 'CARTER',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Casey County',
    value: 'CASEY',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Christian County',
    value: 'CHRISTIAN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Clark County',
    value: 'CLARK',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Clay County',
    value: 'CLAY',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Clinton County',
    value: 'CLINTON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Crittenden County',
    value: 'CRITTENDEN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Cumberland County',
    value: 'CUMBERLAND',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Daviess County',
    value: 'DAVIESS',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Edmonson County',
    value: 'EDMONSON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Elliott County',
    value: 'ELLIOTT',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Estill County',
    value: 'ESTILL',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Fayette County',
    value: 'FAYETTE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Fleming County',
    value: 'FLEMING',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Floyd County',
    value: 'FLOYD',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Fulton County',
    value: 'FULTON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Gallatin County',
    value: 'GALLATIN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Garrard County',
    value: 'GARRARD',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Grant County',
    value: 'GRANT',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Graves County',
    value: 'GRAVES',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Grayson County',
    value: 'GRAYSON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Green County',
    value: 'GREEN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Greenup County',
    value: 'GREENUP',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Hancock County',
    value: 'HANCOCK',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Hardin County',
    value: 'HARDIN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Harlan County',
    value: 'HARLAN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Harrison County',
    value: 'HARRISON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Hart County',
    value: 'HART',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Henderson County',
    value: 'HENDERSON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Henry County',
    value: 'HENRY',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Hickman County',
    value: 'HICKMAN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Hopkins County',
    value: 'HOPKINS',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Jessamine County',
    value: 'JESSAMINE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Johnson County',
    value: 'JOHNSON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Kenton County',
    value: 'KENTON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Knott County',
    value: 'KNOTT',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Knox County',
    value: 'KNOX',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Larue County',
    value: 'LARUE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Laurel County',
    value: 'LAUREL',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Lawrence County',
    value: 'LAWRENCE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Lee County',
    value: 'LEE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Leslie County',
    value: 'LESLIE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Letcher County',
    value: 'LETCHER',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Lewis County',
    value: 'LEWIS',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Livingston County',
    value: 'LIVINGSTON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Logan County',
    value: 'LOGAN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Lyon County',
    value: 'LYON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'McCracken County',
    value: 'MCCRACKEN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'McCreary County',
    value: 'MCCREARY',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'McLean County',
    value: 'MCLEAN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Magoffin County',
    value: 'MAGOFFIN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Marion County',
    value: 'MARION',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Marshall County',
    value: 'MARSHALL',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Martin County',
    value: 'MARTIN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Mason County',
    value: 'MASON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Meade County',
    value: 'MEADE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Menifee County',
    value: 'MENIFEE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Mercer County',
    value: 'MERCER',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Metcalfe County',
    value: 'METCALFE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Monroe County',
    value: 'MONROE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Montgomery County',
    value: 'MONTGOMERY',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Morgan County',
    value: 'MORGAN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Muhlenberg County',
    value: 'MUHLENBERG',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Nelson County',
    value: 'NELSON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Nicholas County',
    value: 'NICHOLAS',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Ohio County',
    value: 'OHIO',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Oldham County',
    value: 'OLDHAM',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Owen County',
    value: 'OWEN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Owsley County',
    value: 'OWSLEY',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Pendleton County',
    value: 'PENDLETON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Perry County',
    value: 'PERRY',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Pike County',
    value: 'PIKE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Powell County',
    value: 'POWELL',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Pulaski County',
    value: 'PULASKI',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Robertson County',
    value: 'ROBERTSON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Rockcastle County',
    value: 'ROCKCASTLE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Rowan County',
    value: 'ROWAN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Russell County',
    value: 'RUSSELL',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Scott County',
    value: 'SCOTT',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Shelby County',
    value: 'SHELBY',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Simpson County',
    value: 'SIMPSON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Spencer County',
    value: 'SPENCER',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Taylor County',
    value: 'TAYLOR',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Todd County',
    value: 'TODD',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Trigg County',
    value: 'TRIGG',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Trimble County',
    value: 'TRIMBLE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Union County',
    value: 'UNION',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Warren County',
    value: 'WARREN',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Wayne County',
    value: 'WAYNE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Webster County',
    value: 'WEBSTER',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Whitley County',
    value: 'WHITLEY',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Wolfe County',
    value: 'WOLFE',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Woodford County',
    value: 'WOODFORD',
    // State: "Kentucky",
    State: 'KY',
  },
  {
    County: 'Acadia Parish',
    value: 'ACADIA',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Allen Parish',
    value: 'ALLEN',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Ascension Parish',
    value: 'ASCENSION',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Assumption Parish',
    value: 'ASSUMPTION',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Avoyelles Parish',
    value: 'AVOYELLES',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Beauregard Parish',
    value: 'BEAUREGARD',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Bienville Parish',
    value: 'BIENVILLE',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Bossier Parish',
    value: 'BOSSIER',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Caddo Parish',
    value: 'CADDO',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Calcasieu Parish',
    value: 'CALCASIEU',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Caldwell Parish',
    value: 'CALDWELL',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Cameron Parish',
    value: 'CAMERON',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Catahoula Parish',
    value: 'CATAHOULA',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Claiborne Parish',
    value: 'CLAIBORNE',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Concordia Parish',
    value: 'CONCORDIA',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'De Soto Parish',
    value: 'DE',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'East Baton Rouge Parish',
    value: 'EAST',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'East Carroll Parish',
    value: 'EAST',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'East Feliciana Parish',
    value: 'EAST',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Evangeline Parish',
    value: 'EVANGELINE',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Franklin Parish',
    value: 'FRANKLIN',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Grant Parish',
    value: 'GRANT',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Iberia Parish',
    value: 'IBERIA',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Iberville Parish',
    value: 'IBERVILLE',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Jackson Parish',
    value: 'JACKSON',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Jefferson Parish',
    value: 'JEFFERSON',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Jefferson Davis Parish',
    value: 'JEFFERSON',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Lafayette Parish',
    value: 'LAFAYETTE',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Lafourche Parish',
    value: 'LAFOURCHE',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'LaSalle Parish',
    value: 'LASALLE',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Lincoln Parish',
    value: 'LINCOLN',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Livingston Parish',
    value: 'LIVINGSTON',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Madison Parish',
    value: 'MADISON',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Morehouse Parish',
    value: 'MOREHOUSE',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Natchitoches Parish',
    value: 'NATCHITOCHES',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Orleans Parish',
    value: 'ORLEANS',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Ouachita Parish',
    value: 'OUACHITA',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Plaquemines Parish',
    value: 'PLAQUEMINES',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Pointe Coupee Parish',
    value: 'POINTE',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Rapides Parish',
    value: 'RAPIDES',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Red River Parish',
    value: 'RED',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Richland Parish',
    value: 'RICHLAND',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Sabine Parish',
    value: 'SABINE',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'St. Bernard Parish',
    value: 'ST.',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'St. Charles Parish',
    value: 'ST.',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'St. Helena Parish',
    value: 'ST.',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'St. James Parish',
    value: 'ST.',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'St. John the Baptist Parish',
    value: 'ST.',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'St. Landry Parish',
    value: 'ST.',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'St. Martin Parish',
    value: 'ST.',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'St. Mary Parish',
    value: 'ST.',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'St. Tammany Parish',
    value: 'ST.',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Tangipahoa Parish',
    value: 'TANGIPAHOA',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Tensas Parish',
    value: 'TENSAS',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Terrebonne Parish',
    value: 'TERREBONNE',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Union Parish',
    value: 'UNION',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Vermilion Parish',
    value: 'VERMILION',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Vernon Parish',
    value: 'VERNON',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Washington Parish',
    value: 'WASHINGTON',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Webster Parish',
    value: 'WEBSTER',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'West Baton Rouge Parish',
    value: 'WEST',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'West Carroll Parish',
    value: 'WEST',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'West Feliciana Parish',
    value: 'WEST',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Winn Parish',
    value: 'WINN',
    // State: "Louisiana",
    State: 'LA',
  },
  {
    County: 'Androscoggin County',
    value: 'ANDROSCOGGIN',
    // State: "Maine",
    State: 'ME',
  },
  {
    County: 'Aroostook County',
    value: 'AROOSTOOK',
    // State: "Maine",
    State: 'ME',
  },
  {
    County: 'Cumberland County',
    value: 'CUMBERLAND',
    // State: "Maine",
    State: 'ME',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Maine",
    State: 'ME',
  },
  {
    County: 'Hancock County',
    value: 'HANCOCK',
    // State: "Maine",
    State: 'ME',
  },
  {
    County: 'Kennebec County',
    value: 'KENNEBEC',
    // State: "Maine",
    State: 'ME',
  },
  {
    County: 'Knox County',
    value: 'KNOX',
    // State: "Maine",
    State: 'ME',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Maine",
    State: 'ME',
  },
  {
    County: 'Oxford County',
    value: 'OXFORD',
    // State: "Maine",
    State: 'ME',
  },
  {
    County: 'Penobscot County',
    value: 'PENOBSCOT',
    // State: "Maine",
    State: 'ME',
  },
  {
    County: 'Piscataquis County',
    value: 'PISCATAQUIS',
    // State: "Maine",
    State: 'ME',
  },
  {
    County: 'Sagadahoc County',
    value: 'SAGADAHOC',
    // State: "Maine",
    State: 'ME',
  },
  {
    County: 'Somerset County',
    value: 'SOMERSET',
    // State: "Maine",
    State: 'ME',
  },
  {
    County: 'Waldo County',
    value: 'WALDO',
    // State: "Maine",
    State: 'ME',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Maine",
    State: 'ME',
  },
  {
    County: 'York County',
    value: 'YORK',
    // State: "Maine",
    State: 'ME',
  },
  {
    County: 'Allegany County',
    value: 'ALLEGANY',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Anne Arundel County',
    value: 'ANNE',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Baltimore County',
    value: 'BALTIMORE',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Calvert County',
    value: 'CALVERT',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Caroline County',
    value: 'CAROLINE',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Carroll County',
    value: 'CARROLL',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Cecil County',
    value: 'CECIL',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Charles County',
    value: 'CHARLES',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Dorchester County',
    value: 'DORCHESTER',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Frederick County',
    value: 'FREDERICK',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Garrett County',
    value: 'GARRETT',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Harford County',
    value: 'HARFORD',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Howard County',
    value: 'HOWARD',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Kent County',
    value: 'KENT',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Montgomery County',
    value: 'MONTGOMERY',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: "Prince George's County",
    value: 'PRINCE',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: "Queen Anne's County",
    value: 'QUEEN',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: "St. Mary's County",
    value: 'ST.',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Somerset County',
    value: 'SOMERSET',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Talbot County',
    value: 'TALBOT',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Wicomico County',
    value: 'WICOMICO',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Worcester County',
    value: 'WORCESTER',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Baltimore, City of',
    value: 'BALTIMORE,',
    // State: "Maryland",
    State: 'MD',
  },
  {
    County: 'Barnstable County',
    value: 'BARNSTABLE',
    // State: "Massachusetts",
    State: 'MA',
  },
  {
    County: 'Berkshire County',
    value: 'BERKSHIRE',
    // State: "Massachusetts",
    State: 'MA',
  },
  {
    County: 'Bristol County',
    value: 'BRISTOL',
    // State: "Massachusetts",
    State: 'MA',
  },
  {
    County: 'Dukes County',
    value: 'DUKES',
    // State: "Massachusetts",
    State: 'MA',
  },
  {
    County: 'Essex County',
    value: 'ESSEX',
    // State: "Massachusetts",
    State: 'MA',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Massachusetts",
    State: 'MA',
  },
  {
    County: 'Hampden County',
    value: 'HAMPDEN',
    // State: "Massachusetts",
    State: 'MA',
  },
  {
    County: 'Hampshire County',
    value: 'HAMPSHIRE',
    // State: "Massachusetts",
    State: 'MA',
  },
  {
    County: 'Middlesex County',
    value: 'MIDDLESEX',
    // State: "Massachusetts",
    State: 'MA',
  },
  {
    County: 'Nantucket, Town and County of',
    value: 'NANTUCKET,',
    // State: "Massachusetts",
    State: 'MA',
  },
  {
    County: 'Norfolk County',
    value: 'NORFOLK',
    // State: "Massachusetts",
    State: 'MA',
  },
  {
    County: 'Plymouth County',
    value: 'PLYMOUTH',
    // State: "Massachusetts",
    State: 'MA',
  },
  {
    County: 'Suffolk County',
    value: 'SUFFOLK',
    // State: "Massachusetts",
    State: 'MA',
  },
  {
    County: 'Worcester County',
    value: 'WORCESTER',
    // State: "Massachusetts",
    State: 'MA',
  },
  {
    County: 'Alcona County',
    value: 'ALCONA',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Alger County',
    value: 'ALGER',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Allegan County',
    value: 'ALLEGAN',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Alpena County',
    value: 'ALPENA',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Antrim County',
    value: 'ANTRIM',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Arenac County',
    value: 'ARENAC',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Baraga County',
    value: 'BARAGA',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Barry County',
    value: 'BARRY',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Bay County',
    value: 'BAY',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Benzie County',
    value: 'BENZIE',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Berrien County',
    value: 'BERRIEN',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Branch County',
    value: 'BRANCH',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Calhoun County',
    value: 'CALHOUN',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Cass County',
    value: 'CASS',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Charlevoix County',
    value: 'CHARLEVOIX',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Cheboygan County',
    value: 'CHEBOYGAN',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Chippewa County',
    value: 'CHIPPEWA',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Clare County',
    value: 'CLARE',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Clinton County',
    value: 'CLINTON',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Crawford County',
    value: 'CRAWFORD',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Delta County',
    value: 'DELTA',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Dickinson County',
    value: 'DICKINSON',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Eaton County',
    value: 'EATON',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Emmet County',
    value: 'EMMET',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Genesee County',
    value: 'GENESEE',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Gladwin County',
    value: 'GLADWIN',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Gogebic County',
    value: 'GOGEBIC',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Grand Traverse County',
    value: 'GRAND',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Gratiot County',
    value: 'GRATIOT',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Hillsdale County',
    value: 'HILLSDALE',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Houghton County',
    value: 'HOUGHTON',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Huron County',
    value: 'HURON',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Ingham County',
    value: 'INGHAM',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Ionia County',
    value: 'IONIA',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Iosco County',
    value: 'IOSCO',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Iron County',
    value: 'IRON',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Isabella County',
    value: 'ISABELLA',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Kalamazoo County',
    value: 'KALAMAZOO',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Kalkaska County',
    value: 'KALKASKA',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Kent County',
    value: 'KENT',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Keweenaw County',
    value: 'KEWEENAW',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Lake County',
    value: 'LAKE',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Lapeer County',
    value: 'LAPEER',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Leelanau County',
    value: 'LEELANAU',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Lenawee County',
    value: 'LENAWEE',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Livingston County',
    value: 'LIVINGSTON',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Luce County',
    value: 'LUCE',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Mackinac County',
    value: 'MACKINAC',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Macomb County',
    value: 'MACOMB',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Manistee County',
    value: 'MANISTEE',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Marquette County',
    value: 'MARQUETTE',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Mason County',
    value: 'MASON',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Mecosta County',
    value: 'MECOSTA',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Menominee County',
    value: 'MENOMINEE',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Midland County',
    value: 'MIDLAND',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Missaukee County',
    value: 'MISSAUKEE',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Monroe County',
    value: 'MONROE',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Montcalm County',
    value: 'MONTCALM',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Montmorency County',
    value: 'MONTMORENCY',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Muskegon County',
    value: 'MUSKEGON',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Newaygo County',
    value: 'NEWAYGO',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Oakland County',
    value: 'OAKLAND',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Oceana County',
    value: 'OCEANA',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Ogemaw County',
    value: 'OGEMAW',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Ontonagon County',
    value: 'ONTONAGON',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Osceola County',
    value: 'OSCEOLA',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Oscoda County',
    value: 'OSCODA',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Otsego County',
    value: 'OTSEGO',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Ottawa County',
    value: 'OTTAWA',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Presque Isle County',
    value: 'PRESQUE',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Roscommon County',
    value: 'ROSCOMMON',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Saginaw County',
    value: 'SAGINAW',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'St. Clair County',
    value: 'ST.',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'St. Joseph County',
    value: 'ST.',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Sanilac County',
    value: 'SANILAC',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Schoolcraft County',
    value: 'SCHOOLCRAFT',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Shiawassee County',
    value: 'SHIAWASSEE',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Tuscola County',
    value: 'TUSCOLA',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Van Buren County',
    value: 'VAN',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Washtenaw County',
    value: 'WASHTENAW',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Wayne County',
    value: 'WAYNE',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Wexford County',
    value: 'WEXFORD',
    // State: "Michigan",
    State: 'MI',
  },
  {
    County: 'Aitkin County',
    value: 'AITKIN',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Anoka County',
    value: 'ANOKA',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Becker County',
    value: 'BECKER',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Beltrami County',
    value: 'BELTRAMI',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Benton County',
    value: 'BENTON',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Big Stone County',
    value: 'BIG',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Blue Earth County',
    value: 'BLUE',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Brown County',
    value: 'BROWN',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Carlton County',
    value: 'CARLTON',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Carver County',
    value: 'CARVER',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Cass County',
    value: 'CASS',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Chippewa County',
    value: 'CHIPPEWA',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Chisago County',
    value: 'CHISAGO',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Clay County',
    value: 'CLAY',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Clearwater County',
    value: 'CLEARWATER',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Cook County',
    value: 'COOK',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Cottonwood County',
    value: 'COTTONWOOD',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Crow Wing County',
    value: 'CROW',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Dakota County',
    value: 'DAKOTA',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Dodge County',
    value: 'DODGE',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Douglas County',
    value: 'DOUGLAS',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Faribault County',
    value: 'FARIBAULT',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Fillmore County',
    value: 'FILLMORE',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Freeborn County',
    value: 'FREEBORN',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Goodhue County',
    value: 'GOODHUE',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Grant County',
    value: 'GRANT',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Hennepin County',
    value: 'HENNEPIN',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Houston County',
    value: 'HOUSTON',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Hubbard County',
    value: 'HUBBARD',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Isanti County',
    value: 'ISANTI',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Itasca County',
    value: 'ITASCA',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Kanabec County',
    value: 'KANABEC',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Kandiyohi County',
    value: 'KANDIYOHI',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Kittson County',
    value: 'KITTSON',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Koochiching County',
    value: 'KOOCHICHING',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Lac qui Parle County',
    value: 'LAC',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Lake County',
    value: 'LAKE',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Lake of the Woods County',
    value: 'LAKE',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Le Sueur County',
    value: 'LE',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Lyon County',
    value: 'LYON',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'McLeod County',
    value: 'MCLEOD',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Mahnomen County',
    value: 'MAHNOMEN',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Marshall County',
    value: 'MARSHALL',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Martin County',
    value: 'MARTIN',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Meeker County',
    value: 'MEEKER',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Mille Lacs County',
    value: 'MILLE',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Morrison County',
    value: 'MORRISON',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Mower County',
    value: 'MOWER',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Murray County',
    value: 'MURRAY',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Nicollet County',
    value: 'NICOLLET',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Nobles County',
    value: 'NOBLES',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Norman County',
    value: 'NORMAN',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Olmsted County',
    value: 'OLMSTED',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Otter Tail County',
    value: 'OTTER',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Pennington County',
    value: 'PENNINGTON',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Pine County',
    value: 'PINE',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Pipestone County',
    value: 'PIPESTONE',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Polk County',
    value: 'POLK',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Pope County',
    value: 'POPE',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Ramsey County',
    value: 'RAMSEY',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Red Lake County',
    value: 'RED',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Redwood County',
    value: 'REDWOOD',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Renville County',
    value: 'RENVILLE',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Rice County',
    value: 'RICE',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Rock County',
    value: 'ROCK',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Roseau County',
    value: 'ROSEAU',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'St. Louis County',
    value: 'ST.',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Scott County',
    value: 'SCOTT',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Sherburne County',
    value: 'SHERBURNE',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Sibley County',
    value: 'SIBLEY',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Stearns County',
    value: 'STEARNS',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Steele County',
    value: 'STEELE',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Stevens County',
    value: 'STEVENS',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Swift County',
    value: 'SWIFT',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Todd County',
    value: 'TODD',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Traverse County',
    value: 'TRAVERSE',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Wabasha County',
    value: 'WABASHA',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Wadena County',
    value: 'WADENA',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Waseca County',
    value: 'WASECA',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Watonwan County',
    value: 'WATONWAN',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Wilkin County',
    value: 'WILKIN',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Winona County',
    value: 'WINONA',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Wright County',
    value: 'WRIGHT',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Yellow Medicine County',
    value: 'YELLOW',
    // State: "Minnesota",
    State: 'MN',
  },
  {
    County: 'Adams County',
    value: 'ADAMS',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Alcorn County',
    value: 'ALCORN',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Amite County',
    value: 'AMITE',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Attala County',
    value: 'ATTALA',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Benton County',
    value: 'BENTON',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Bolivar County',
    value: 'BOLIVAR',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Calhoun County',
    value: 'CALHOUN',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Carroll County',
    value: 'CARROLL',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Chickasaw County',
    value: 'CHICKASAW',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Choctaw County',
    value: 'CHOCTAW',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Claiborne County',
    value: 'CLAIBORNE',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Clarke County',
    value: 'CLARKE',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Clay County',
    value: 'CLAY',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Coahoma County',
    value: 'COAHOMA',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Copiah County',
    value: 'COPIAH',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Covington County',
    value: 'COVINGTON',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'DeSoto County',
    value: 'DESOTO',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Forrest County',
    value: 'FORREST',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'George County',
    value: 'GEORGE',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Greene County',
    value: 'GREENE',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Grenada County',
    value: 'GRENADA',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Hancock County',
    value: 'HANCOCK',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Harrison County',
    value: 'HARRISON',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Hinds County',
    value: 'HINDS',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Holmes County',
    value: 'HOLMES',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Humphreys County',
    value: 'HUMPHREYS',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Issaquena County',
    value: 'ISSAQUENA',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Itawamba County',
    value: 'ITAWAMBA',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Jasper County',
    value: 'JASPER',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Jefferson Davis County',
    value: 'JEFFERSON',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Jones County',
    value: 'JONES',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Kemper County',
    value: 'KEMPER',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Lafayette County',
    value: 'LAFAYETTE',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Lamar County',
    value: 'LAMAR',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Lauderdale County',
    value: 'LAUDERDALE',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Lawrence County',
    value: 'LAWRENCE',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Leake County',
    value: 'LEAKE',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Lee County',
    value: 'LEE',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Leflore County',
    value: 'LEFLORE',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Lowndes County',
    value: 'LOWNDES',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Marion County',
    value: 'MARION',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Marshall County',
    value: 'MARSHALL',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Monroe County',
    value: 'MONROE',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Montgomery County',
    value: 'MONTGOMERY',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Neshoba County',
    value: 'NESHOBA',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Newton County',
    value: 'NEWTON',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Noxubee County',
    value: 'NOXUBEE',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Oktibbeha County',
    value: 'OKTIBBEHA',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Panola County',
    value: 'PANOLA',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Pearl River County',
    value: 'PEARL',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Perry County',
    value: 'PERRY',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Pike County',
    value: 'PIKE',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Pontotoc County',
    value: 'PONTOTOC',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Prentiss County',
    value: 'PRENTISS',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Quitman County',
    value: 'QUITMAN',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Rankin County',
    value: 'RANKIN',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Scott County',
    value: 'SCOTT',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Sharkey County',
    value: 'SHARKEY',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Simpson County',
    value: 'SIMPSON',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Smith County',
    value: 'SMITH',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Stone County',
    value: 'STONE',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Sunflower County',
    value: 'SUNFLOWER',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Tallahatchie County',
    value: 'TALLAHATCHIE',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Tate County',
    value: 'TATE',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Tippah County',
    value: 'TIPPAH',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Tishomingo County',
    value: 'TISHOMINGO',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Tunica County',
    value: 'TUNICA',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Union County',
    value: 'UNION',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Walthall County',
    value: 'WALTHALL',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Warren County',
    value: 'WARREN',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Wayne County',
    value: 'WAYNE',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Webster County',
    value: 'WEBSTER',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Wilkinson County',
    value: 'WILKINSON',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Winston County',
    value: 'WINSTON',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Yalobusha County',
    value: 'YALOBUSHA',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Yazoo County',
    value: 'YAZOO',
    // State: "Mississippi",
    State: 'MS',
  },
  {
    County: 'Adair County',
    value: 'ADAIR',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Andrew County',
    value: 'ANDREW',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Atchison County',
    value: 'ATCHISON',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Audrain County',
    value: 'AUDRAIN',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Barry County',
    value: 'BARRY',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Barton County',
    value: 'BARTON',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Bates County',
    value: 'BATES',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Benton County',
    value: 'BENTON',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Bollinger County',
    value: 'BOLLINGER',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Boone County',
    value: 'BOONE',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Buchanan County',
    value: 'BUCHANAN',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Butler County',
    value: 'BUTLER',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Caldwell County',
    value: 'CALDWELL',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Callaway County',
    value: 'CALLAWAY',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Camden County',
    value: 'CAMDEN',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Cape Girardeau County',
    value: 'CAPE',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Carroll County',
    value: 'CARROLL',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Carter County',
    value: 'CARTER',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Cass County',
    value: 'CASS',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Cedar County',
    value: 'CEDAR',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Chariton County',
    value: 'CHARITON',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Christian County',
    value: 'CHRISTIAN',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Clark County',
    value: 'CLARK',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Clay County',
    value: 'CLAY',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Clinton County',
    value: 'CLINTON',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Cole County',
    value: 'COLE',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Cooper County',
    value: 'COOPER',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Crawford County',
    value: 'CRAWFORD',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Dade County',
    value: 'DADE',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Dallas County',
    value: 'DALLAS',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Daviess County',
    value: 'DAVIESS',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'DeKalb County',
    value: 'DEKALB',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Dent County',
    value: 'DENT',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Douglas County',
    value: 'DOUGLAS',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Dunklin County',
    value: 'DUNKLIN',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Gasconade County',
    value: 'GASCONADE',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Gentry County',
    value: 'GENTRY',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Greene County',
    value: 'GREENE',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Grundy County',
    value: 'GRUNDY',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Harrison County',
    value: 'HARRISON',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Henry County',
    value: 'HENRY',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Hickory County',
    value: 'HICKORY',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Holt County',
    value: 'HOLT',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Howard County',
    value: 'HOWARD',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Howell County',
    value: 'HOWELL',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Iron County',
    value: 'IRON',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Jasper County',
    value: 'JASPER',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Johnson County',
    value: 'JOHNSON',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Knox County',
    value: 'KNOX',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Laclede County',
    value: 'LACLEDE',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Lafayette County',
    value: 'LAFAYETTE',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Lawrence County',
    value: 'LAWRENCE',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Lewis County',
    value: 'LEWIS',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Linn County',
    value: 'LINN',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Livingston County',
    value: 'LIVINGSTON',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'McDonald County',
    value: 'MCDONALD',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Macon County',
    value: 'MACON',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Maries County',
    value: 'MARIES',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Marion County',
    value: 'MARION',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Mercer County',
    value: 'MERCER',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Miller County',
    value: 'MILLER',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Mississippi County',
    value: 'MISSISSIPPI',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Moniteau County',
    value: 'MONITEAU',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Monroe County',
    value: 'MONROE',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Montgomery County',
    value: 'MONTGOMERY',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Morgan County',
    value: 'MORGAN',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'New Madrid County',
    value: 'NEW',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Newton County',
    value: 'NEWTON',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Nodaway County',
    value: 'NODAWAY',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Oregon County',
    value: 'OREGON',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Osage County',
    value: 'OSAGE',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Ozark County',
    value: 'OZARK',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Pemiscot County',
    value: 'PEMISCOT',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Perry County',
    value: 'PERRY',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Pettis County',
    value: 'PETTIS',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Phelps County',
    value: 'PHELPS',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Pike County',
    value: 'PIKE',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Platte County',
    value: 'PLATTE',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Polk County',
    value: 'POLK',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Pulaski County',
    value: 'PULASKI',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Putnam County',
    value: 'PUTNAM',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Ralls County',
    value: 'RALLS',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Randolph County',
    value: 'RANDOLPH',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Ray County',
    value: 'RAY',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Reynolds County',
    value: 'REYNOLDS',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Ripley County',
    value: 'RIPLEY',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'St. Charles County',
    value: 'ST.',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'St. Clair County',
    value: 'ST.',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Ste. Genevieve County',
    value: 'STE.',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'St. Francois County',
    value: 'ST.',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'St. Louis County',
    value: 'ST.',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Saline County',
    value: 'SALINE',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Schuyler County',
    value: 'SCHUYLER',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Scotland County',
    value: 'SCOTLAND',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Scott County',
    value: 'SCOTT',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Shannon County',
    value: 'SHANNON',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Shelby County',
    value: 'SHELBY',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Stoddard County',
    value: 'STODDARD',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Stone County',
    value: 'STONE',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Sullivan County',
    value: 'SULLIVAN',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Taney County',
    value: 'TANEY',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Texas County',
    value: 'TEXAS',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Vernon County',
    value: 'VERNON',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Warren County',
    value: 'WARREN',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Wayne County',
    value: 'WAYNE',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Webster County',
    value: 'WEBSTER',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Worth County',
    value: 'WORTH',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Wright County',
    value: 'WRIGHT',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'St. Louis, City of',
    value: 'ST.',
    // State: "Missouri",
    State: 'MO',
  },
  {
    County: 'Beaverhead County',
    value: 'BEAVERHEAD',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Big Horn County',
    value: 'BIG',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Blaine County',
    value: 'BLAINE',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Broadwater County',
    value: 'BROADWATER',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Carbon County',
    value: 'CARBON',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Carter County',
    value: 'CARTER',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Cascade County',
    value: 'CASCADE',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Chouteau County',
    value: 'CHOUTEAU',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Custer County',
    value: 'CUSTER',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Daniels County',
    value: 'DANIELS',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Dawson County',
    value: 'DAWSON',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Deer Lodge County',
    value: 'DEER',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Fallon County',
    value: 'FALLON',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Fergus County',
    value: 'FERGUS',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Flathead County',
    value: 'FLATHEAD',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Gallatin County',
    value: 'GALLATIN',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Garfield County',
    value: 'GARFIELD',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Glacier County',
    value: 'GLACIER',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Golden Valley County',
    value: 'GOLDEN',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Granite County',
    value: 'GRANITE',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Hill County',
    value: 'HILL',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Judith Basin County',
    value: 'JUDITH',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Lake County',
    value: 'LAKE',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Lewis and Clark County',
    value: 'LEWIS',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Liberty County',
    value: 'LIBERTY',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'McCone County',
    value: 'MCCONE',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Meagher County',
    value: 'MEAGHER',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Mineral County',
    value: 'MINERAL',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Missoula County',
    value: 'MISSOULA',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Musselshell County',
    value: 'MUSSELSHELL',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Park County',
    value: 'PARK',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Petroleum County',
    value: 'PETROLEUM',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Phillips County',
    value: 'PHILLIPS',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Pondera County',
    value: 'PONDERA',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Powder River County',
    value: 'POWDER',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Powell County',
    value: 'POWELL',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Prairie County',
    value: 'PRAIRIE',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Ravalli County',
    value: 'RAVALLI',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Richland County',
    value: 'RICHLAND',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Roosevelt County',
    value: 'ROOSEVELT',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Rosebud County',
    value: 'ROSEBUD',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Sanders County',
    value: 'SANDERS',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Sheridan County',
    value: 'SHERIDAN',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Silver Bow County',
    value: 'SILVER',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Stillwater County',
    value: 'STILLWATER',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Sweet Grass County',
    value: 'SWEET',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Teton County',
    value: 'TETON',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Toole County',
    value: 'TOOLE',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Treasure County',
    value: 'TREASURE',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Valley County',
    value: 'VALLEY',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Wheatland County',
    value: 'WHEATLAND',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Wibaux County',
    value: 'WIBAUX',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Yellowstone County',
    value: 'YELLOWSTONE',
    // State: "Montana",
    State: 'MT',
  },
  {
    County: 'Adams County',
    value: 'ADAMS',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Antelope County',
    value: 'ANTELOPE',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Arthur County',
    value: 'ARTHUR',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Banner County',
    value: 'BANNER',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Blaine County',
    value: 'BLAINE',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Boone County',
    value: 'BOONE',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Box Butte County',
    value: 'BOX',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Boyd County',
    value: 'BOYD',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Brown County',
    value: 'BROWN',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Buffalo County',
    value: 'BUFFALO',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Burt County',
    value: 'BURT',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Butler County',
    value: 'BUTLER',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Cass County',
    value: 'CASS',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Cedar County',
    value: 'CEDAR',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Chase County',
    value: 'CHASE',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Cherry County',
    value: 'CHERRY',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Cheyenne County',
    value: 'CHEYENNE',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Clay County',
    value: 'CLAY',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Colfax County',
    value: 'COLFAX',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Cuming County',
    value: 'CUMING',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Custer County',
    value: 'CUSTER',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Dakota County',
    value: 'DAKOTA',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Dawes County',
    value: 'DAWES',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Dawson County',
    value: 'DAWSON',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Deuel County',
    value: 'DEUEL',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Dixon County',
    value: 'DIXON',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Dodge County',
    value: 'DODGE',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Douglas County',
    value: 'DOUGLAS',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Dundy County',
    value: 'DUNDY',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Fillmore County',
    value: 'FILLMORE',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Frontier County',
    value: 'FRONTIER',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Furnas County',
    value: 'FURNAS',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Gage County',
    value: 'GAGE',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Garden County',
    value: 'GARDEN',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Garfield County',
    value: 'GARFIELD',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Gosper County',
    value: 'GOSPER',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Grant County',
    value: 'GRANT',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Greeley County',
    value: 'GREELEY',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Hall County',
    value: 'HALL',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Hamilton County',
    value: 'HAMILTON',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Harlan County',
    value: 'HARLAN',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Hayes County',
    value: 'HAYES',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Hitchcock County',
    value: 'HITCHCOCK',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Holt County',
    value: 'HOLT',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Hooker County',
    value: 'HOOKER',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Howard County',
    value: 'HOWARD',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Johnson County',
    value: 'JOHNSON',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Kearney County',
    value: 'KEARNEY',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Keith County',
    value: 'KEITH',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Keya Paha County',
    value: 'KEYA',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Kimball County',
    value: 'KIMBALL',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Knox County',
    value: 'KNOX',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Lancaster County',
    value: 'LANCASTER',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Logan County',
    value: 'LOGAN',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Loup County',
    value: 'LOUP',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'McPherson County',
    value: 'MCPHERSON',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Merrick County',
    value: 'MERRICK',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Morrill County',
    value: 'MORRILL',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Nance County',
    value: 'NANCE',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Nemaha County',
    value: 'NEMAHA',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Nuckolls County',
    value: 'NUCKOLLS',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Otoe County',
    value: 'OTOE',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Pawnee County',
    value: 'PAWNEE',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Perkins County',
    value: 'PERKINS',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Phelps County',
    value: 'PHELPS',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Pierce County',
    value: 'PIERCE',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Platte County',
    value: 'PLATTE',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Polk County',
    value: 'POLK',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Red Willow County',
    value: 'RED',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Richardson County',
    value: 'RICHARDSON',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Rock County',
    value: 'ROCK',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Saline County',
    value: 'SALINE',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Sarpy County',
    value: 'SARPY',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Saunders County',
    value: 'SAUNDERS',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Scotts Bluff County',
    value: 'SCOTTS',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Seward County',
    value: 'SEWARD',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Sheridan County',
    value: 'SHERIDAN',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Sherman County',
    value: 'SHERMAN',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Sioux County',
    value: 'SIOUX',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Stanton County',
    value: 'STANTON',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Thayer County',
    value: 'THAYER',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Thomas County',
    value: 'THOMAS',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Thurston County',
    value: 'THURSTON',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Valley County',
    value: 'VALLEY',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Wayne County',
    value: 'WAYNE',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Webster County',
    value: 'WEBSTER',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Wheeler County',
    value: 'WHEELER',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'York County',
    value: 'YORK',
    // State: "Nebraska",
    State: 'NE',
  },
  {
    County: 'Churchill County',
    value: 'CHURCHILL',
    // State: "Nevada",
    State: 'NV',
  },
  {
    County: 'Clark County',
    value: 'CLARK',
    // State: "Nevada",
    State: 'NV',
  },
  {
    County: 'Douglas County',
    value: 'DOUGLAS',
    // State: "Nevada",
    State: 'NV',
  },
  {
    County: 'Elko County',
    value: 'ELKO',
    // State: "Nevada",
    State: 'NV',
  },
  {
    County: 'Esmeralda County',
    value: 'ESMERALDA',
    // State: "Nevada",
    State: 'NV',
  },
  {
    County: 'Eureka County',
    value: 'EUREKA',
    // State: "Nevada",
    State: 'NV',
  },
  {
    County: 'Humboldt County',
    value: 'HUMBOLDT',
    // State: "Nevada",
    State: 'NV',
  },
  {
    County: 'Lander County',
    value: 'LANDER',
    // State: "Nevada",
    State: 'NV',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Nevada",
    State: 'NV',
  },
  {
    County: 'Lyon County',
    value: 'LYON',
    // State: "Nevada",
    State: 'NV',
  },
  {
    County: 'Mineral County',
    value: 'MINERAL',
    // State: "Nevada",
    State: 'NV',
  },
  {
    County: 'Nye County',
    value: 'NYE',
    // State: "Nevada",
    State: 'NV',
  },
  {
    County: 'Pershing County',
    value: 'PERSHING',
    // State: "Nevada",
    State: 'NV',
  },
  {
    County: 'Storey County',
    value: 'STOREY',
    // State: "Nevada",
    State: 'NV',
  },
  {
    County: 'Washoe County',
    value: 'WASHOE',
    // State: "Nevada",
    State: 'NV',
  },
  {
    County: 'White Pine County',
    value: 'WHITE',
    // State: "Nevada",
    State: 'NV',
  },
  {
    County: 'Carson City, Consolidated Municipality of',
    value: 'CARSON',
    // State: "Nevada",
    State: 'NV',
  },
  {
    County: 'Belknap County',
    value: 'BELKNAP',
    // State: "New Hampshire",
    State: 'NH',
  },
  {
    County: 'Carroll County',
    value: 'CARROLL',
    // State: "New Hampshire",
    State: 'NH',
  },
  {
    County: 'Cheshire County',
    value: 'CHESHIRE',
    // State: "New Hampshire",
    State: 'NH',
  },
  {
    County: 'Coos County',
    value: 'COOS',
    // State: "New Hampshire",
    State: 'NH',
  },
  {
    County: 'Grafton County',
    value: 'GRAFTON',
    // State: "New Hampshire",
    State: 'NH',
  },
  {
    County: 'Hillsborough County',
    value: 'HILLSBOROUGH',
    // State: "New Hampshire",
    State: 'NH',
  },
  {
    County: 'Merrimack County',
    value: 'MERRIMACK',
    // State: "New Hampshire",
    State: 'NH',
  },
  {
    County: 'Rockingham County',
    value: 'ROCKINGHAM',
    // State: "New Hampshire",
    State: 'NH',
  },
  {
    County: 'Strafford County',
    value: 'STRAFFORD',
    // State: "New Hampshire",
    State: 'NH',
  },
  {
    County: 'Sullivan County',
    value: 'SULLIVAN',
    // State: "New Hampshire",
    State: 'NH',
  },
  {
    County: 'Atlantic County',
    value: 'ATLANTIC',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Bergen County',
    value: 'BERGEN',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Burlington County',
    value: 'BURLINGTON',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Camden County',
    value: 'CAMDEN',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Cape May County',
    value: 'CAPE',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Cumberland County',
    value: 'CUMBERLAND',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Essex County',
    value: 'ESSEX',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Gloucester County',
    value: 'GLOUCESTER',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Hudson County',
    value: 'HUDSON',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Hunterdon County',
    value: 'HUNTERDON',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Mercer County',
    value: 'MERCER',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Middlesex County',
    value: 'MIDDLESEX',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Monmouth County',
    value: 'MONMOUTH',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Morris County',
    value: 'MORRIS',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Ocean County',
    value: 'OCEAN',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Passaic County',
    value: 'PASSAIC',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Salem County',
    value: 'SALEM',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Somerset County',
    value: 'SOMERSET',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Sussex County',
    value: 'SUSSEX',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Union County',
    value: 'UNION',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Warren County',
    value: 'WARREN',
    // State: "New Jersey",
    State: 'NJ',
  },
  {
    County: 'Bernalillo County',
    value: 'BERNALILLO',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Catron County',
    value: 'CATRON',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Chaves County',
    value: 'CHAVES',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Cibola County',
    value: 'CIBOLA',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Colfax County',
    value: 'COLFAX',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Curry County',
    value: 'CURRY',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'De Baca County',
    value: 'DE',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Do�a Ana County',
    value: 'DO�A',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Eddy County',
    value: 'EDDY',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Grant County',
    value: 'GRANT',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Guadalupe County',
    value: 'GUADALUPE',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Harding County',
    value: 'HARDING',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Hidalgo County',
    value: 'HIDALGO',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Lea County',
    value: 'LEA',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Los Alamos County',
    value: 'LOS',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Luna County',
    value: 'LUNA',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'McKinley County',
    value: 'MCKINLEY',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Mora County',
    value: 'MORA',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Otero County',
    value: 'OTERO',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Quay County',
    value: 'QUAY',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Rio Arriba County',
    value: 'RIO',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Roosevelt County',
    value: 'ROOSEVELT',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Sandoval County',
    value: 'SANDOVAL',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'San Juan County',
    value: 'SAN',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'San Miguel County',
    value: 'SAN',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Santa Fe County',
    value: 'SANTA',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Sierra County',
    value: 'SIERRA',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Socorro County',
    value: 'SOCORRO',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Taos County',
    value: 'TAOS',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Torrance County',
    value: 'TORRANCE',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Union County',
    value: 'UNION',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Valencia County',
    value: 'VALENCIA',
    // State: "New Mexico",
    State: 'NM',
  },
  {
    County: 'Albany County',
    value: 'ALBANY',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Allegany County',
    value: 'ALLEGANY',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Bronx County',
    value: 'BRONX',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Broome County',
    value: 'BROOME',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Cattaraugus County',
    value: 'CATTARAUGUS',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Cayuga County',
    value: 'CAYUGA',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Chautauqua County',
    value: 'CHAUTAUQUA',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Chemung County',
    value: 'CHEMUNG',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Chenango County',
    value: 'CHENANGO',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Clinton County',
    value: 'CLINTON',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Columbia County',
    value: 'COLUMBIA',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Cortland County',
    value: 'CORTLAND',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Delaware County',
    value: 'DELAWARE',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Dutchess County',
    value: 'DUTCHESS',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Erie County',
    value: 'ERIE',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Essex County',
    value: 'ESSEX',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Fulton County',
    value: 'FULTON',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Genesee County',
    value: 'GENESEE',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Greene County',
    value: 'GREENE',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Hamilton County',
    value: 'HAMILTON',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Herkimer County',
    value: 'HERKIMER',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Kings County',
    value: 'KINGS',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Lewis County',
    value: 'LEWIS',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Livingston County',
    value: 'LIVINGSTON',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Monroe County',
    value: 'MONROE',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Montgomery County',
    value: 'MONTGOMERY',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Nassau County',
    value: 'NASSAU',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'New York County',
    value: 'NEW',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Niagara County',
    value: 'NIAGARA',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Oneida County',
    value: 'ONEIDA',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Onondaga County',
    value: 'ONONDAGA',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Ontario County',
    value: 'ONTARIO',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Orange County',
    value: 'ORANGE',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Orleans County',
    value: 'ORLEANS',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Oswego County',
    value: 'OSWEGO',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Otsego County',
    value: 'OTSEGO',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Putnam County',
    value: 'PUTNAM',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Queens County',
    value: 'QUEENS',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Rensselaer County',
    value: 'RENSSELAER',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Richmond County',
    value: 'RICHMOND',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Rockland County',
    value: 'ROCKLAND',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'St. Lawrence County',
    value: 'ST.',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Saratoga County',
    value: 'SARATOGA',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Schenectady County',
    value: 'SCHENECTADY',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Schoharie County',
    value: 'SCHOHARIE',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Schuyler County',
    value: 'SCHUYLER',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Seneca County',
    value: 'SENECA',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Steuben County',
    value: 'STEUBEN',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Suffolk County',
    value: 'SUFFOLK',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Sullivan County',
    value: 'SULLIVAN',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Tioga County',
    value: 'TIOGA',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Tompkins County',
    value: 'TOMPKINS',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Ulster County',
    value: 'ULSTER',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Warren County',
    value: 'WARREN',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Wayne County',
    value: 'WAYNE',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Westchester County',
    value: 'WESTCHESTER',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Wyoming County',
    value: 'WYOMING',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Yates County',
    value: 'YATES',
    // State: "New York",
    State: 'NY',
  },
  {
    County: 'Alamance County',
    value: 'ALAMANCE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Alexander County',
    value: 'ALEXANDER',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Alleghany County',
    value: 'ALLEGHANY',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Anson County',
    value: 'ANSON',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Ashe County',
    value: 'ASHE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Avery County',
    value: 'AVERY',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Beaufort County',
    value: 'BEAUFORT',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Bertie County',
    value: 'BERTIE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Bladen County',
    value: 'BLADEN',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Brunswick County',
    value: 'BRUNSWICK',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Buncombe County',
    value: 'BUNCOMBE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Burke County',
    value: 'BURKE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Cabarrus County',
    value: 'CABARRUS',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Caldwell County',
    value: 'CALDWELL',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Camden County',
    value: 'CAMDEN',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Carteret County',
    value: 'CARTERET',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Caswell County',
    value: 'CASWELL',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Catawba County',
    value: 'CATAWBA',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Chatham County',
    value: 'CHATHAM',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Cherokee County',
    value: 'CHEROKEE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Chowan County',
    value: 'CHOWAN',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Clay County',
    value: 'CLAY',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Cleveland County',
    value: 'CLEVELAND',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Columbus County',
    value: 'COLUMBUS',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Craven County',
    value: 'CRAVEN',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Cumberland County',
    value: 'CUMBERLAND',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Currituck County',
    value: 'CURRITUCK',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Dare County',
    value: 'DARE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Davidson County',
    value: 'DAVIDSON',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Davie County',
    value: 'DAVIE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Duplin County',
    value: 'DUPLIN',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Durham County',
    value: 'DURHAM',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Edgecombe County',
    value: 'EDGECOMBE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Forsyth County',
    value: 'FORSYTH',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Gaston County',
    value: 'GASTON',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Gates County',
    value: 'GATES',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Graham County',
    value: 'GRAHAM',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Granville County',
    value: 'GRANVILLE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Greene County',
    value: 'GREENE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Guilford County',
    value: 'GUILFORD',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Halifax County',
    value: 'HALIFAX',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Harnett County',
    value: 'HARNETT',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Haywood County',
    value: 'HAYWOOD',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Henderson County',
    value: 'HENDERSON',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Hertford County',
    value: 'HERTFORD',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Hoke County',
    value: 'HOKE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Hyde County',
    value: 'HYDE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Iredell County',
    value: 'IREDELL',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Johnston County',
    value: 'JOHNSTON',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Jones County',
    value: 'JONES',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Lee County',
    value: 'LEE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Lenoir County',
    value: 'LENOIR',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'McDowell County',
    value: 'MCDOWELL',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Macon County',
    value: 'MACON',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Martin County',
    value: 'MARTIN',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Mecklenburg County',
    value: 'MECKLENBURG',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Mitchell County',
    value: 'MITCHELL',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Montgomery County',
    value: 'MONTGOMERY',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Moore County',
    value: 'MOORE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Nash County',
    value: 'NASH',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'New Hanover County',
    value: 'NEW',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Northampton County',
    value: 'NORTHAMPTON',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Onslow County',
    value: 'ONSLOW',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Orange County',
    value: 'ORANGE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Pamlico County',
    value: 'PAMLICO',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Pasquotank County',
    value: 'PASQUOTANK',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Pender County',
    value: 'PENDER',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Perquimans County',
    value: 'PERQUIMANS',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Person County',
    value: 'PERSON',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Pitt County',
    value: 'PITT',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Polk County',
    value: 'POLK',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Randolph County',
    value: 'RANDOLPH',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Richmond County',
    value: 'RICHMOND',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Robeson County',
    value: 'ROBESON',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Rockingham County',
    value: 'ROCKINGHAM',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Rowan County',
    value: 'ROWAN',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Rutherford County',
    value: 'RUTHERFORD',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Sampson County',
    value: 'SAMPSON',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Scotland County',
    value: 'SCOTLAND',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Stanly County',
    value: 'STANLY',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Stokes County',
    value: 'STOKES',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Surry County',
    value: 'SURRY',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Swain County',
    value: 'SWAIN',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Transylvania County',
    value: 'TRANSYLVANIA',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Tyrrell County',
    value: 'TYRRELL',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Union County',
    value: 'UNION',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Vance County',
    value: 'VANCE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Wake County',
    value: 'WAKE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Warren County',
    value: 'WARREN',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Watauga County',
    value: 'WATAUGA',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Wayne County',
    value: 'WAYNE',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Wilkes County',
    value: 'WILKES',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Wilson County',
    value: 'WILSON',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Yadkin County',
    value: 'YADKIN',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Yancey County',
    value: 'YANCEY',
    // State: "North Carolina",
    State: 'NC',
  },
  {
    County: 'Adams County',
    value: 'ADAMS',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Barnes County',
    value: 'BARNES',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Benson County',
    value: 'BENSON',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Billings County',
    value: 'BILLINGS',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Bottineau County',
    value: 'BOTTINEAU',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Bowman County',
    value: 'BOWMAN',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Burke County',
    value: 'BURKE',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Burleigh County',
    value: 'BURLEIGH',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Cass County',
    value: 'CASS',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Cavalier County',
    value: 'CAVALIER',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Dickey County',
    value: 'DICKEY',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Divide County',
    value: 'DIVIDE',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Dunn County',
    value: 'DUNN',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Eddy County',
    value: 'EDDY',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Emmons County',
    value: 'EMMONS',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Foster County',
    value: 'FOSTER',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Golden Valley County',
    value: 'GOLDEN',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Grand Forks County',
    value: 'GRAND',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Grant County',
    value: 'GRANT',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Griggs County',
    value: 'GRIGGS',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Hettinger County',
    value: 'HETTINGER',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Kidder County',
    value: 'KIDDER',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'LaMoure County',
    value: 'LAMOURE',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Logan County',
    value: 'LOGAN',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'McHenry County',
    value: 'MCHENRY',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'McIntosh County',
    value: 'MCINTOSH',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'McKenzie County',
    value: 'MCKENZIE',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'McLean County',
    value: 'MCLEAN',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Mercer County',
    value: 'MERCER',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Morton County',
    value: 'MORTON',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Mountrail County',
    value: 'MOUNTRAIL',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Nelson County',
    value: 'NELSON',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Oliver County',
    value: 'OLIVER',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Pembina County',
    value: 'PEMBINA',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Pierce County',
    value: 'PIERCE',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Ramsey County',
    value: 'RAMSEY',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Ransom County',
    value: 'RANSOM',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Renville County',
    value: 'RENVILLE',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Richland County',
    value: 'RICHLAND',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Rolette County',
    value: 'ROLETTE',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Sargent County',
    value: 'SARGENT',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Sheridan County',
    value: 'SHERIDAN',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Sioux County',
    value: 'SIOUX',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Slope County',
    value: 'SLOPE',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Stark County',
    value: 'STARK',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Steele County',
    value: 'STEELE',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Stutsman County',
    value: 'STUTSMAN',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Towner County',
    value: 'TOWNER',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Traill County',
    value: 'TRAILL',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Walsh County',
    value: 'WALSH',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Ward County',
    value: 'WARD',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Wells County',
    value: 'WELLS',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Williams County',
    value: 'WILLIAMS',
    // State: "North Dakota",
    State: 'ND',
  },
  {
    County: 'Adams County',
    value: 'ADAMS',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Allen County',
    value: 'ALLEN',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Ashland County',
    value: 'ASHLAND',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Ashtabula County',
    value: 'ASHTABULA',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Athens County',
    value: 'ATHENS',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Auglaize County',
    value: 'AUGLAIZE',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Belmont County',
    value: 'BELMONT',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Brown County',
    value: 'BROWN',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Butler County',
    value: 'BUTLER',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Carroll County',
    value: 'CARROLL',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Champaign County',
    value: 'CHAMPAIGN',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Clark County',
    value: 'CLARK',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Clermont County',
    value: 'CLERMONT',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Clinton County',
    value: 'CLINTON',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Columbiana County',
    value: 'COLUMBIANA',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Coshocton County',
    value: 'COSHOCTON',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Crawford County',
    value: 'CRAWFORD',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Cuyahoga County',
    value: 'CUYAHOGA',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Darke County',
    value: 'DARKE',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Defiance County',
    value: 'DEFIANCE',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Delaware County',
    value: 'DELAWARE',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Erie County',
    value: 'ERIE',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Fairfield County',
    value: 'FAIRFIELD',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Fayette County',
    value: 'FAYETTE',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Fulton County',
    value: 'FULTON',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Gallia County',
    value: 'GALLIA',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Geauga County',
    value: 'GEAUGA',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Greene County',
    value: 'GREENE',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Guernsey County',
    value: 'GUERNSEY',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Hamilton County',
    value: 'HAMILTON',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Hancock County',
    value: 'HANCOCK',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Hardin County',
    value: 'HARDIN',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Harrison County',
    value: 'HARRISON',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Henry County',
    value: 'HENRY',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Highland County',
    value: 'HIGHLAND',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Hocking County',
    value: 'HOCKING',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Holmes County',
    value: 'HOLMES',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Huron County',
    value: 'HURON',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Knox County',
    value: 'KNOX',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Lake County',
    value: 'LAKE',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Lawrence County',
    value: 'LAWRENCE',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Licking County',
    value: 'LICKING',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Logan County',
    value: 'LOGAN',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Lorain County',
    value: 'LORAIN',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Lucas County',
    value: 'LUCAS',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Mahoning County',
    value: 'MAHONING',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Marion County',
    value: 'MARION',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Medina County',
    value: 'MEDINA',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Meigs County',
    value: 'MEIGS',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Mercer County',
    value: 'MERCER',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Miami County',
    value: 'MIAMI',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Monroe County',
    value: 'MONROE',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Montgomery County',
    value: 'MONTGOMERY',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Morgan County',
    value: 'MORGAN',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Morrow County',
    value: 'MORROW',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Muskingum County',
    value: 'MUSKINGUM',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Noble County',
    value: 'NOBLE',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Ottawa County',
    value: 'OTTAWA',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Paulding County',
    value: 'PAULDING',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Perry County',
    value: 'PERRY',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Pickaway County',
    value: 'PICKAWAY',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Pike County',
    value: 'PIKE',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Portage County',
    value: 'PORTAGE',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Preble County',
    value: 'PREBLE',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Putnam County',
    value: 'PUTNAM',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Richland County',
    value: 'RICHLAND',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Ross County',
    value: 'ROSS',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Sandusky County',
    value: 'SANDUSKY',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Scioto County',
    value: 'SCIOTO',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Seneca County',
    value: 'SENECA',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Shelby County',
    value: 'SHELBY',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Stark County',
    value: 'STARK',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Summit County',
    value: 'SUMMIT',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Trumbull County',
    value: 'TRUMBULL',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Tuscarawas County',
    value: 'TUSCARAWAS',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Union County',
    value: 'UNION',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Van Wert County',
    value: 'VAN',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Vinton County',
    value: 'VINTON',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Warren County',
    value: 'WARREN',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Wayne County',
    value: 'WAYNE',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Williams County',
    value: 'WILLIAMS',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Wood County',
    value: 'WOOD',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Wyandot County',
    value: 'WYANDOT',
    // State: "Ohio",
    State: 'OH',
  },
  {
    County: 'Adair County',
    value: 'ADAIR',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Alfalfa County',
    value: 'ALFALFA',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Atoka County',
    value: 'ATOKA',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Beaver County',
    value: 'BEAVER',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Beckham County',
    value: 'BECKHAM',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Blaine County',
    value: 'BLAINE',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Bryan County',
    value: 'BRYAN',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Caddo County',
    value: 'CADDO',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Canadian County',
    value: 'CANADIAN',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Carter County',
    value: 'CARTER',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Cherokee County',
    value: 'CHEROKEE',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Choctaw County',
    value: 'CHOCTAW',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Cimarron County',
    value: 'CIMARRON',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Cleveland County',
    value: 'CLEVELAND',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Coal County',
    value: 'COAL',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Comanche County',
    value: 'COMANCHE',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Cotton County',
    value: 'COTTON',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Craig County',
    value: 'CRAIG',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Creek County',
    value: 'CREEK',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Custer County',
    value: 'CUSTER',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Delaware County',
    value: 'DELAWARE',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Dewey County',
    value: 'DEWEY',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Ellis County',
    value: 'ELLIS',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Garfield County',
    value: 'GARFIELD',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Garvin County',
    value: 'GARVIN',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Grady County',
    value: 'GRADY',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Grant County',
    value: 'GRANT',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Greer County',
    value: 'GREER',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Harmon County',
    value: 'HARMON',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Harper County',
    value: 'HARPER',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Haskell County',
    value: 'HASKELL',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Hughes County',
    value: 'HUGHES',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Johnston County',
    value: 'JOHNSTON',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Kay County',
    value: 'KAY',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Kingfisher County',
    value: 'KINGFISHER',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Kiowa County',
    value: 'KIOWA',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Latimer County',
    value: 'LATIMER',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Le Flore County',
    value: 'LE',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Logan County',
    value: 'LOGAN',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Love County',
    value: 'LOVE',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'McClain County',
    value: 'MCCLAIN',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'McCurtain County',
    value: 'MCCURTAIN',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'McIntosh County',
    value: 'MCINTOSH',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Major County',
    value: 'MAJOR',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Marshall County',
    value: 'MARSHALL',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Mayes County',
    value: 'MAYES',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Murray County',
    value: 'MURRAY',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Muskogee County',
    value: 'MUSKOGEE',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Noble County',
    value: 'NOBLE',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Nowata County',
    value: 'NOWATA',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Okfuskee County',
    value: 'OKFUSKEE',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Oklahoma County',
    value: 'OKLAHOMA',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Okmulgee County',
    value: 'OKMULGEE',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Osage County',
    value: 'OSAGE',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Ottawa County',
    value: 'OTTAWA',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Pawnee County',
    value: 'PAWNEE',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Payne County',
    value: 'PAYNE',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Pittsburg County',
    value: 'PITTSBURG',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Pontotoc County',
    value: 'PONTOTOC',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Pottawatomie County',
    value: 'POTTAWATOMIE',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Pushmataha County',
    value: 'PUSHMATAHA',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Roger Mills County',
    value: 'ROGER',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Rogers County',
    value: 'ROGERS',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Seminole County',
    value: 'SEMINOLE',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Sequoyah County',
    value: 'SEQUOYAH',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Stephens County',
    value: 'STEPHENS',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Texas County',
    value: 'TEXAS',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Tillman County',
    value: 'TILLMAN',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Tulsa County',
    value: 'TULSA',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Wagoner County',
    value: 'WAGONER',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Washita County',
    value: 'WASHITA',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Woods County',
    value: 'WOODS',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Woodward County',
    value: 'WOODWARD',
    // State: "Oklahoma",
    State: 'OK',
  },
  {
    County: 'Baker County',
    value: 'BAKER',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Benton County',
    value: 'BENTON',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Clackamas County',
    value: 'CLACKAMAS',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Clatsop County',
    value: 'CLATSOP',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Columbia County',
    value: 'COLUMBIA',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Coos County',
    value: 'COOS',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Crook County',
    value: 'CROOK',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Curry County',
    value: 'CURRY',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Deschutes County',
    value: 'DESCHUTES',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Douglas County',
    value: 'DOUGLAS',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Gilliam County',
    value: 'GILLIAM',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Grant County',
    value: 'GRANT',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Harney County',
    value: 'HARNEY',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Hood River County',
    value: 'HOOD',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Josephine County',
    value: 'JOSEPHINE',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Klamath County',
    value: 'KLAMATH',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Lake County',
    value: 'LAKE',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Lane County',
    value: 'LANE',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Linn County',
    value: 'LINN',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Malheur County',
    value: 'MALHEUR',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Marion County',
    value: 'MARION',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Morrow County',
    value: 'MORROW',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Multnomah County',
    value: 'MULTNOMAH',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Polk County',
    value: 'POLK',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Sherman County',
    value: 'SHERMAN',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Tillamook County',
    value: 'TILLAMOOK',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Umatilla County',
    value: 'UMATILLA',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Union County',
    value: 'UNION',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Wallowa County',
    value: 'WALLOWA',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Wasco County',
    value: 'WASCO',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Wheeler County',
    value: 'WHEELER',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Yamhill County',
    value: 'YAMHILL',
    // State: "Oregon",
    State: 'OR',
  },
  {
    County: 'Adams County',
    value: 'ADAMS',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Allegheny County',
    value: 'ALLEGHENY',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Armstrong County',
    value: 'ARMSTRONG',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Beaver County',
    value: 'BEAVER',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Bedford County',
    value: 'BEDFORD',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Berks County',
    value: 'BERKS',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Blair County',
    value: 'BLAIR',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Bradford County',
    value: 'BRADFORD',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Bucks County',
    value: 'BUCKS',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Butler County',
    value: 'BUTLER',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Cambria County',
    value: 'CAMBRIA',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Cameron County',
    value: 'CAMERON',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Carbon County',
    value: 'CARBON',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Centre County',
    value: 'CENTRE',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Chester County',
    value: 'CHESTER',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Clarion County',
    value: 'CLARION',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Clearfield County',
    value: 'CLEARFIELD',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Clinton County',
    value: 'CLINTON',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Columbia County',
    value: 'COLUMBIA',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Crawford County',
    value: 'CRAWFORD',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Cumberland County',
    value: 'CUMBERLAND',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Dauphin County',
    value: 'DAUPHIN',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Delaware County',
    value: 'DELAWARE',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Elk County',
    value: 'ELK',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Erie County',
    value: 'ERIE',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Fayette County',
    value: 'FAYETTE',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Forest County',
    value: 'FOREST',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Fulton County',
    value: 'FULTON',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Greene County',
    value: 'GREENE',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Huntingdon County',
    value: 'HUNTINGDON',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Indiana County',
    value: 'INDIANA',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Juniata County',
    value: 'JUNIATA',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Lackawanna County',
    value: 'LACKAWANNA',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Lancaster County',
    value: 'LANCASTER',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Lawrence County',
    value: 'LAWRENCE',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Lebanon County',
    value: 'LEBANON',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Lehigh County',
    value: 'LEHIGH',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Luzerne County',
    value: 'LUZERNE',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Lycoming County',
    value: 'LYCOMING',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'McKean County',
    value: 'MCKEAN',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Mercer County',
    value: 'MERCER',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Mifflin County',
    value: 'MIFFLIN',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Monroe County',
    value: 'MONROE',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Montgomery County',
    value: 'MONTGOMERY',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Montour County',
    value: 'MONTOUR',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Northampton County',
    value: 'NORTHAMPTON',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Northumberland County',
    value: 'NORTHUMBERLAND',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Perry County',
    value: 'PERRY',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Philadelphia County',
    value: 'PHILADELPHIA',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Pike County',
    value: 'PIKE',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Potter County',
    value: 'POTTER',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Schuylkill County',
    value: 'SCHUYLKILL',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Snyder County',
    value: 'SNYDER',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Somerset County',
    value: 'SOMERSET',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Sullivan County',
    value: 'SULLIVAN',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Susquehanna County',
    value: 'SUSQUEHANNA',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Tioga County',
    value: 'TIOGA',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Union County',
    value: 'UNION',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Venango County',
    value: 'VENANGO',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Warren County',
    value: 'WARREN',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Wayne County',
    value: 'WAYNE',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Westmoreland County',
    value: 'WESTMORELAND',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Wyoming County',
    value: 'WYOMING',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'York County',
    value: 'YORK',
    // State: "Pennsylvania",
    State: 'PA',
  },
  {
    County: 'Bristol County',
    value: 'BRISTOL',
    // State: "Rhode Island",
    State: 'RI',
  },
  {
    County: 'Kent County',
    value: 'KENT',
    // State: "Rhode Island",
    State: 'RI',
  },
  {
    County: 'Newport County',
    value: 'NEWPORT',
    // State: "Rhode Island",
    State: 'RI',
  },
  {
    County: 'Providence County',
    value: 'PROVIDENCE',
    // State: "Rhode Island",
    State: 'RI',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Rhode Island",
    State: 'RI',
  },
  {
    County: 'Abbeville County',
    value: 'ABBEVILLE',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Aiken County',
    value: 'AIKEN',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Allendale County',
    value: 'ALLENDALE',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Anderson County',
    value: 'ANDERSON',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Bamberg County',
    value: 'BAMBERG',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Barnwell County',
    value: 'BARNWELL',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Beaufort County',
    value: 'BEAUFORT',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Berkeley County',
    value: 'BERKELEY',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Calhoun County',
    value: 'CALHOUN',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Charleston County',
    value: 'CHARLESTON',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Cherokee County',
    value: 'CHEROKEE',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Chester County',
    value: 'CHESTER',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Chesterfield County',
    value: 'CHESTERFIELD',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Clarendon County',
    value: 'CLARENDON',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Colleton County',
    value: 'COLLETON',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Darlington County',
    value: 'DARLINGTON',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Dillon County',
    value: 'DILLON',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Dorchester County',
    value: 'DORCHESTER',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Edgefield County',
    value: 'EDGEFIELD',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Fairfield County',
    value: 'FAIRFIELD',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Florence County',
    value: 'FLORENCE',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Georgetown County',
    value: 'GEORGETOWN',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Greenville County',
    value: 'GREENVILLE',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Greenwood County',
    value: 'GREENWOOD',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Hampton County',
    value: 'HAMPTON',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Horry County',
    value: 'HORRY',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Jasper County',
    value: 'JASPER',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Kershaw County',
    value: 'KERSHAW',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Lancaster County',
    value: 'LANCASTER',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Laurens County',
    value: 'LAURENS',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Lee County',
    value: 'LEE',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Lexington County',
    value: 'LEXINGTON',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'McCormick County',
    value: 'MCCORMICK',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Marion County',
    value: 'MARION',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Marlboro County',
    value: 'MARLBORO',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Newberry County',
    value: 'NEWBERRY',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Oconee County',
    value: 'OCONEE',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Orangeburg County',
    value: 'ORANGEBURG',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Pickens County',
    value: 'PICKENS',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Richland County',
    value: 'RICHLAND',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Saluda County',
    value: 'SALUDA',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Spartanburg County',
    value: 'SPARTANBURG',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Sumter County',
    value: 'SUMTER',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Union County',
    value: 'UNION',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Williamsburg County',
    value: 'WILLIAMSBURG',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'York County',
    value: 'YORK',
    // State: "South Carolina",
    State: 'SC',
  },
  {
    County: 'Aurora County',
    value: 'AURORA',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Beadle County',
    value: 'BEADLE',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Bennett County',
    value: 'BENNETT',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Bon Homme County',
    value: 'BON',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Brookings County',
    value: 'BROOKINGS',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Brown County',
    value: 'BROWN',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Brule County',
    value: 'BRULE',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Buffalo County',
    value: 'BUFFALO',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Butte County',
    value: 'BUTTE',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Campbell County',
    value: 'CAMPBELL',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Charles Mix County',
    value: 'CHARLES',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Clark County',
    value: 'CLARK',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Clay County',
    value: 'CLAY',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Codington County',
    value: 'CODINGTON',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Corson County',
    value: 'CORSON',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Custer County',
    value: 'CUSTER',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Davison County',
    value: 'DAVISON',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Day County',
    value: 'DAY',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Deuel County',
    value: 'DEUEL',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Dewey County',
    value: 'DEWEY',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Douglas County',
    value: 'DOUGLAS',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Edmunds County',
    value: 'EDMUNDS',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Fall River County',
    value: 'FALL',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Faulk County',
    value: 'FAULK',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Grant County',
    value: 'GRANT',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Gregory County',
    value: 'GREGORY',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Haakon County',
    value: 'HAAKON',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Hamlin County',
    value: 'HAMLIN',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Hand County',
    value: 'HAND',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Hanson County',
    value: 'HANSON',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Harding County',
    value: 'HARDING',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Hughes County',
    value: 'HUGHES',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Hutchinson County',
    value: 'HUTCHINSON',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Hyde County',
    value: 'HYDE',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Jerauld County',
    value: 'JERAULD',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Jones County',
    value: 'JONES',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Kingsbury County',
    value: 'KINGSBURY',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Lake County',
    value: 'LAKE',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Lawrence County',
    value: 'LAWRENCE',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Lyman County',
    value: 'LYMAN',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'McCook County',
    value: 'MCCOOK',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'McPherson County',
    value: 'MCPHERSON',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Marshall County',
    value: 'MARSHALL',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Meade County',
    value: 'MEADE',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Mellette County',
    value: 'MELLETTE',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Miner County',
    value: 'MINER',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Minnehaha County',
    value: 'MINNEHAHA',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Moody County',
    value: 'MOODY',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Pennington County',
    value: 'PENNINGTON',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Perkins County',
    value: 'PERKINS',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Potter County',
    value: 'POTTER',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Roberts County',
    value: 'ROBERTS',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Sanborn County',
    value: 'SANBORN',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Shannon County',
    value: 'SHANNON',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Spink County',
    value: 'SPINK',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Stanley County',
    value: 'STANLEY',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Sully County',
    value: 'SULLY',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Todd County',
    value: 'TODD',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Tripp County',
    value: 'TRIPP',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Turner County',
    value: 'TURNER',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Union County',
    value: 'UNION',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Walworth County',
    value: 'WALWORTH',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Yankton County',
    value: 'YANKTON',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Ziebach County',
    value: 'ZIEBACH',
    // State: "South Dakota",
    State: 'SD',
  },
  {
    County: 'Anderson County',
    value: 'ANDERSON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Bedford County',
    value: 'BEDFORD',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Benton County',
    value: 'BENTON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Bledsoe County',
    value: 'BLEDSOE',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Blount County',
    value: 'BLOUNT',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Bradley County',
    value: 'BRADLEY',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Campbell County',
    value: 'CAMPBELL',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Cannon County',
    value: 'CANNON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Carroll County',
    value: 'CARROLL',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Carter County',
    value: 'CARTER',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Cheatham County',
    value: 'CHEATHAM',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Chester County',
    value: 'CHESTER',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Claiborne County',
    value: 'CLAIBORNE',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Clay County',
    value: 'CLAY',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Cocke County',
    value: 'COCKE',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Coffee County',
    value: 'COFFEE',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Crockett County',
    value: 'CROCKETT',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Cumberland County',
    value: 'CUMBERLAND',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Davidson County',
    value: 'DAVIDSON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Decatur County',
    value: 'DECATUR',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'DeKalb County',
    value: 'DEKALB',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Dickson County',
    value: 'DICKSON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Dyer County',
    value: 'DYER',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Fayette County',
    value: 'FAYETTE',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Fentress County',
    value: 'FENTRESS',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Gibson County',
    value: 'GIBSON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Giles County',
    value: 'GILES',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Grainger County',
    value: 'GRAINGER',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Greene County',
    value: 'GREENE',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Grundy County',
    value: 'GRUNDY',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Hamblen County',
    value: 'HAMBLEN',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Hamilton County',
    value: 'HAMILTON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Hancock County',
    value: 'HANCOCK',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Hardeman County',
    value: 'HARDEMAN',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Hardin County',
    value: 'HARDIN',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Hawkins County',
    value: 'HAWKINS',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Haywood County',
    value: 'HAYWOOD',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Henderson County',
    value: 'HENDERSON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Henry County',
    value: 'HENRY',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Hickman County',
    value: 'HICKMAN',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Houston County',
    value: 'HOUSTON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Humphreys County',
    value: 'HUMPHREYS',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Johnson County',
    value: 'JOHNSON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Knox County',
    value: 'KNOX',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Lake County',
    value: 'LAKE',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Lauderdale County',
    value: 'LAUDERDALE',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Lawrence County',
    value: 'LAWRENCE',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Lewis County',
    value: 'LEWIS',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Loudon County',
    value: 'LOUDON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'McMinn County',
    value: 'MCMINN',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'McNairy County',
    value: 'MCNAIRY',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Macon County',
    value: 'MACON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Marion County',
    value: 'MARION',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Marshall County',
    value: 'MARSHALL',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Maury County',
    value: 'MAURY',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Meigs County',
    value: 'MEIGS',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Monroe County',
    value: 'MONROE',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Montgomery County',
    value: 'MONTGOMERY',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Moore County',
    value: 'MOORE',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Morgan County',
    value: 'MORGAN',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Obion County',
    value: 'OBION',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Overton County',
    value: 'OVERTON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Perry County',
    value: 'PERRY',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Pickett County',
    value: 'PICKETT',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Polk County',
    value: 'POLK',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Putnam County',
    value: 'PUTNAM',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Rhea County',
    value: 'RHEA',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Roane County',
    value: 'ROANE',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Robertson County',
    value: 'ROBERTSON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Rutherford County',
    value: 'RUTHERFORD',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Scott County',
    value: 'SCOTT',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Sequatchie County',
    value: 'SEQUATCHIE',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Sevier County',
    value: 'SEVIER',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Shelby County',
    value: 'SHELBY',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Smith County',
    value: 'SMITH',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Stewart County',
    value: 'STEWART',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Sullivan County',
    value: 'SULLIVAN',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Sumner County',
    value: 'SUMNER',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Tipton County',
    value: 'TIPTON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Trousdale County',
    value: 'TROUSDALE',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Unicoi County',
    value: 'UNICOI',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Union County',
    value: 'UNION',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Van Buren County',
    value: 'VAN',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Warren County',
    value: 'WARREN',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Wayne County',
    value: 'WAYNE',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Weakley County',
    value: 'WEAKLEY',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'White County',
    value: 'WHITE',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Williamson County',
    value: 'WILLIAMSON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Wilson County',
    value: 'WILSON',
    // State: "Tennessee",
    State: 'TN',
  },
  {
    County: 'Anderson County',
    value: 'ANDERSON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Andrews County',
    value: 'ANDREWS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Angelina County',
    value: 'ANGELINA',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Aransas County',
    value: 'ARANSAS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Archer County',
    value: 'ARCHER',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Armstrong County',
    value: 'ARMSTRONG',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Atascosa County',
    value: 'ATASCOSA',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Austin County',
    value: 'AUSTIN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Bailey County',
    value: 'BAILEY',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Bandera County',
    value: 'BANDERA',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Bastrop County',
    value: 'BASTROP',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Baylor County',
    value: 'BAYLOR',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Bee County',
    value: 'BEE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Bell County',
    value: 'BELL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Bexar County',
    value: 'BEXAR',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Blanco County',
    value: 'BLANCO',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Borden County',
    value: 'BORDEN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Bosque County',
    value: 'BOSQUE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Bowie County',
    value: 'BOWIE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Brazoria County',
    value: 'BRAZORIA',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Brazos County',
    value: 'BRAZOS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Brewster County',
    value: 'BREWSTER',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Briscoe County',
    value: 'BRISCOE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Brooks County',
    value: 'BROOKS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Brown County',
    value: 'BROWN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Burleson County',
    value: 'BURLESON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Burnet County',
    value: 'BURNET',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Caldwell County',
    value: 'CALDWELL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Calhoun County',
    value: 'CALHOUN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Callahan County',
    value: 'CALLAHAN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Cameron County',
    value: 'CAMERON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Camp County',
    value: 'CAMP',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Carson County',
    value: 'CARSON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Cass County',
    value: 'CASS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Castro County',
    value: 'CASTRO',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Chambers County',
    value: 'CHAMBERS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Cherokee County',
    value: 'CHEROKEE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Childress County',
    value: 'CHILDRESS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Clay County',
    value: 'CLAY',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Cochran County',
    value: 'COCHRAN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Coke County',
    value: 'COKE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Coleman County',
    value: 'COLEMAN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Collin County',
    value: 'COLLIN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Collingsworth County',
    value: 'COLLINGSWORTH',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Colorado County',
    value: 'COLORADO',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Comal County',
    value: 'COMAL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Comanche County',
    value: 'COMANCHE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Concho County',
    value: 'CONCHO',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Cooke County',
    value: 'COOKE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Coryell County',
    value: 'CORYELL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Cottle County',
    value: 'COTTLE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Crane County',
    value: 'CRANE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Crockett County',
    value: 'CROCKETT',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Crosby County',
    value: 'CROSBY',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Culberson County',
    value: 'CULBERSON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Dallam County',
    value: 'DALLAM',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Dallas County',
    value: 'DALLAS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Dawson County',
    value: 'DAWSON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Deaf Smith County',
    value: 'DEAF',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Delta County',
    value: 'DELTA',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Denton County',
    value: 'DENTON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'DeWitt County',
    value: 'DEWITT',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Dickens County',
    value: 'DICKENS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Dimmit County',
    value: 'DIMMIT',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Donley County',
    value: 'DONLEY',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Duval County',
    value: 'DUVAL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Eastland County',
    value: 'EASTLAND',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Ector County',
    value: 'ECTOR',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Edwards County',
    value: 'EDWARDS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Ellis County',
    value: 'ELLIS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'El Paso County',
    value: 'EL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Erath County',
    value: 'ERATH',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Falls County',
    value: 'FALLS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Fannin County',
    value: 'FANNIN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Fayette County',
    value: 'FAYETTE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Fisher County',
    value: 'FISHER',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Floyd County',
    value: 'FLOYD',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Foard County',
    value: 'FOARD',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Fort Bend County',
    value: 'FORT',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Freestone County',
    value: 'FREESTONE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Frio County',
    value: 'FRIO',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Gaines County',
    value: 'GAINES',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Galveston County',
    value: 'GALVESTON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Garza County',
    value: 'GARZA',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Gillespie County',
    value: 'GILLESPIE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Glasscock County',
    value: 'GLASSCOCK',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Goliad County',
    value: 'GOLIAD',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Gonzales County',
    value: 'GONZALES',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Gray County',
    value: 'GRAY',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Grayson County',
    value: 'GRAYSON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Gregg County',
    value: 'GREGG',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Grimes County',
    value: 'GRIMES',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Guadalupe County',
    value: 'GUADALUPE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Hale County',
    value: 'HALE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Hall County',
    value: 'HALL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Hamilton County',
    value: 'HAMILTON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Hansford County',
    value: 'HANSFORD',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Hardeman County',
    value: 'HARDEMAN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Hardin County',
    value: 'HARDIN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Harris County',
    value: 'HARRIS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Harrison County',
    value: 'HARRISON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Hartley County',
    value: 'HARTLEY',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Haskell County',
    value: 'HASKELL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Hays County',
    value: 'HAYS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Hemphill County',
    value: 'HEMPHILL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Henderson County',
    value: 'HENDERSON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Hidalgo County',
    value: 'HIDALGO',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Hill County',
    value: 'HILL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Hockley County',
    value: 'HOCKLEY',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Hood County',
    value: 'HOOD',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Hopkins County',
    value: 'HOPKINS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Houston County',
    value: 'HOUSTON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Howard County',
    value: 'HOWARD',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Hudspeth County',
    value: 'HUDSPETH',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Hunt County',
    value: 'HUNT',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Hutchinson County',
    value: 'HUTCHINSON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Irion County',
    value: 'IRION',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Jack County',
    value: 'JACK',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Jasper County',
    value: 'JASPER',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Jeff Davis County',
    value: 'JEFF',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Jim Hogg County',
    value: 'JIM',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Jim Wells County',
    value: 'JIM',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Johnson County',
    value: 'JOHNSON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Jones County',
    value: 'JONES',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Karnes County',
    value: 'KARNES',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Kaufman County',
    value: 'KAUFMAN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Kendall County',
    value: 'KENDALL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Kenedy County',
    value: 'KENEDY',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Kent County',
    value: 'KENT',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Kerr County',
    value: 'KERR',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Kimble County',
    value: 'KIMBLE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'King County',
    value: 'KING',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Kinney County',
    value: 'KINNEY',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Kleberg County',
    value: 'KLEBERG',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Knox County',
    value: 'KNOX',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Lamar County',
    value: 'LAMAR',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Lamb County',
    value: 'LAMB',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Lampasas County',
    value: 'LAMPASAS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'La Salle County',
    value: 'LA',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Lavaca County',
    value: 'LAVACA',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Lee County',
    value: 'LEE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Leon County',
    value: 'LEON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Liberty County',
    value: 'LIBERTY',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Limestone County',
    value: 'LIMESTONE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Lipscomb County',
    value: 'LIPSCOMB',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Live Oak County',
    value: 'LIVE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Llano County',
    value: 'LLANO',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Loving County',
    value: 'LOVING',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Lubbock County',
    value: 'LUBBOCK',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Lynn County',
    value: 'LYNN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'McCulloch County',
    value: 'MCCULLOCH',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'McLennan County',
    value: 'MCLENNAN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'McMullen County',
    value: 'MCMULLEN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Marion County',
    value: 'MARION',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Martin County',
    value: 'MARTIN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Mason County',
    value: 'MASON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Matagorda County',
    value: 'MATAGORDA',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Maverick County',
    value: 'MAVERICK',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Medina County',
    value: 'MEDINA',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Menard County',
    value: 'MENARD',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Midland County',
    value: 'MIDLAND',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Milam County',
    value: 'MILAM',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Mills County',
    value: 'MILLS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Mitchell County',
    value: 'MITCHELL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Montague County',
    value: 'MONTAGUE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Montgomery County',
    value: 'MONTGOMERY',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Moore County',
    value: 'MOORE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Morris County',
    value: 'MORRIS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Motley County',
    value: 'MOTLEY',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Nacogdoches County',
    value: 'NACOGDOCHES',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Navarro County',
    value: 'NAVARRO',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Newton County',
    value: 'NEWTON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Nolan County',
    value: 'NOLAN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Nueces County',
    value: 'NUECES',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Ochiltree County',
    value: 'OCHILTREE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Oldham County',
    value: 'OLDHAM',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Orange County',
    value: 'ORANGE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Palo Pinto County',
    value: 'PALO',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Panola County',
    value: 'PANOLA',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Parker County',
    value: 'PARKER',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Parmer County',
    value: 'PARMER',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Pecos County',
    value: 'PECOS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Polk County',
    value: 'POLK',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Potter County',
    value: 'POTTER',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Presidio County',
    value: 'PRESIDIO',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Rains County',
    value: 'RAINS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Randall County',
    value: 'RANDALL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Reagan County',
    value: 'REAGAN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Real County',
    value: 'REAL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Red River County',
    value: 'RED',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Reeves County',
    value: 'REEVES',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Refugio County',
    value: 'REFUGIO',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Roberts County',
    value: 'ROBERTS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Robertson County',
    value: 'ROBERTSON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Rockwall County',
    value: 'ROCKWALL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Runnels County',
    value: 'RUNNELS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Rusk County',
    value: 'RUSK',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Sabine County',
    value: 'SABINE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'San Augustine County',
    value: 'SAN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'San Jacinto County',
    value: 'SAN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'San Patricio County',
    value: 'SAN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'San Saba County',
    value: 'SAN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Schleicher County',
    value: 'SCHLEICHER',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Scurry County',
    value: 'SCURRY',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Shackelford County',
    value: 'SHACKELFORD',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Shelby County',
    value: 'SHELBY',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Sherman County',
    value: 'SHERMAN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Smith County',
    value: 'SMITH',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Somervell County',
    value: 'SOMERVELL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Starr County',
    value: 'STARR',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Stephens County',
    value: 'STEPHENS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Sterling County',
    value: 'STERLING',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Stonewall County',
    value: 'STONEWALL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Sutton County',
    value: 'SUTTON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Swisher County',
    value: 'SWISHER',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Tarrant County',
    value: 'TARRANT',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Taylor County',
    value: 'TAYLOR',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Terrell County',
    value: 'TERRELL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Terry County',
    value: 'TERRY',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Throckmorton County',
    value: 'THROCKMORTON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Titus County',
    value: 'TITUS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Tom Green County',
    value: 'TOM',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Travis County',
    value: 'TRAVIS',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Trinity County',
    value: 'TRINITY',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Tyler County',
    value: 'TYLER',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Upshur County',
    value: 'UPSHUR',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Upton County',
    value: 'UPTON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Uvalde County',
    value: 'UVALDE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Val Verde County',
    value: 'VAL',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Van Zandt County',
    value: 'VAN',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Victoria County',
    value: 'VICTORIA',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Walker County',
    value: 'WALKER',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Waller County',
    value: 'WALLER',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Ward County',
    value: 'WARD',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Webb County',
    value: 'WEBB',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Wharton County',
    value: 'WHARTON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Wheeler County',
    value: 'WHEELER',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Wichita County',
    value: 'WICHITA',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Wilbarger County',
    value: 'WILBARGER',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Willacy County',
    value: 'WILLACY',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Williamson County',
    value: 'WILLIAMSON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Wilson County',
    value: 'WILSON',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Winkler County',
    value: 'WINKLER',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Wise County',
    value: 'WISE',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Wood County',
    value: 'WOOD',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Yoakum County',
    value: 'YOAKUM',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Young County',
    value: 'YOUNG',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Zapata County',
    value: 'ZAPATA',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Zavala County',
    value: 'ZAVALA',
    // State: "Texas",
    State: 'TX',
  },
  {
    County: 'Beaver County',
    value: 'BEAVER',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Box Elder County',
    value: 'BOX',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Cache County',
    value: 'CACHE',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Carbon County',
    value: 'CARBON',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Daggett County',
    value: 'DAGGETT',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Davis County',
    value: 'DAVIS',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Duchesne County',
    value: 'DUCHESNE',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Emery County',
    value: 'EMERY',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Garfield County',
    value: 'GARFIELD',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Grand County',
    value: 'GRAND',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Iron County',
    value: 'IRON',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Juab County',
    value: 'JUAB',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Kane County',
    value: 'KANE',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Millard County',
    value: 'MILLARD',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Morgan County',
    value: 'MORGAN',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Piute County',
    value: 'PIUTE',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Rich County',
    value: 'RICH',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Salt Lake County',
    value: 'SALT',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'San Juan County',
    value: 'SAN',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Sanpete County',
    value: 'SANPETE',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Sevier County',
    value: 'SEVIER',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Summit County',
    value: 'SUMMIT',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Tooele County',
    value: 'TOOELE',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Uintah County',
    value: 'UINTAH',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Utah County',
    value: 'UTAH',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Wasatch County',
    value: 'WASATCH',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Wayne County',
    value: 'WAYNE',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Weber County',
    value: 'WEBER',
    // State: "Utah",
    State: 'UT',
  },
  {
    County: 'Addison County',
    value: 'ADDISON',
    // State: "Vermont",
    State: 'VT',
  },
  {
    County: 'Bennington County',
    value: 'BENNINGTON',
    // State: "Vermont",
    State: 'VT',
  },
  {
    County: 'Caledonia County',
    value: 'CALEDONIA',
    // State: "Vermont",
    State: 'VT',
  },
  {
    County: 'Chittenden County',
    value: 'CHITTENDEN',
    // State: "Vermont",
    State: 'VT',
  },
  {
    County: 'Essex County',
    value: 'ESSEX',
    // State: "Vermont",
    State: 'VT',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Vermont",
    State: 'VT',
  },
  {
    County: 'Grand Isle County',
    value: 'GRAND',
    // State: "Vermont",
    State: 'VT',
  },
  {
    County: 'Lamoille County',
    value: 'LAMOILLE',
    // State: "Vermont",
    State: 'VT',
  },
  {
    County: 'Orange County',
    value: 'ORANGE',
    // State: "Vermont",
    State: 'VT',
  },
  {
    County: 'Orleans County',
    value: 'ORLEANS',
    // State: "Vermont",
    State: 'VT',
  },
  {
    County: 'Rutland County',
    value: 'RUTLAND',
    // State: "Vermont",
    State: 'VT',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Vermont",
    State: 'VT',
  },
  {
    County: 'Windham County',
    value: 'WINDHAM',
    // State: "Vermont",
    State: 'VT',
  },
  {
    County: 'Windsor County',
    value: 'WINDSOR',
    // State: "Vermont",
    State: 'VT',
  },
  {
    County: 'Accomack County',
    value: 'ACCOMACK',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Albemarle County',
    value: 'ALBEMARLE',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Alleghany County',
    value: 'ALLEGHANY',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Amelia County',
    value: 'AMELIA',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Amherst County',
    value: 'AMHERST',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Appomattox County',
    value: 'APPOMATTOX',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Arlington County',
    value: 'ARLINGTON',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Augusta County',
    value: 'AUGUSTA',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Bath County',
    value: 'BATH',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Bedford County',
    value: 'BEDFORD',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Bland County',
    value: 'BLAND',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Botetourt County',
    value: 'BOTETOURT',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Brunswick County',
    value: 'BRUNSWICK',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Buchanan County',
    value: 'BUCHANAN',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Buckingham County',
    value: 'BUCKINGHAM',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Campbell County',
    value: 'CAMPBELL',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Caroline County',
    value: 'CAROLINE',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Carroll County',
    value: 'CARROLL',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Charles City County',
    value: 'CHARLES',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Charlotte County',
    value: 'CHARLOTTE',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Chesterfield County',
    value: 'CHESTERFIELD',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Clarke County',
    value: 'CLARKE',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Craig County',
    value: 'CRAIG',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Culpeper County',
    value: 'CULPEPER',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Cumberland County',
    value: 'CUMBERLAND',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Dickenson County',
    value: 'DICKENSON',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Dinwiddie County',
    value: 'DINWIDDIE',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Essex County',
    value: 'ESSEX',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Fairfax County',
    value: 'FAIRFAX',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Fauquier County',
    value: 'FAUQUIER',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Floyd County',
    value: 'FLOYD',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Fluvanna County',
    value: 'FLUVANNA',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Frederick County',
    value: 'FREDERICK',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Giles County',
    value: 'GILES',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Gloucester County',
    value: 'GLOUCESTER',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Goochland County',
    value: 'GOOCHLAND',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Grayson County',
    value: 'GRAYSON',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Greene County',
    value: 'GREENE',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Greensville County',
    value: 'GREENSVILLE',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Halifax County',
    value: 'HALIFAX',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Hanover County',
    value: 'HANOVER',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Henrico County',
    value: 'HENRICO',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Henry County',
    value: 'HENRY',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Highland County',
    value: 'HIGHLAND',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Isle of Wight County',
    value: 'ISLE',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'James City County',
    value: 'JAMES',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'King and Queen County',
    value: 'KING',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'King George County',
    value: 'KING',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'King William County',
    value: 'KING',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Lancaster County',
    value: 'LANCASTER',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Lee County',
    value: 'LEE',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Loudoun County',
    value: 'LOUDOUN',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Louisa County',
    value: 'LOUISA',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Lunenburg County',
    value: 'LUNENBURG',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Madison County',
    value: 'MADISON',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Mathews County',
    value: 'MATHEWS',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Mecklenburg County',
    value: 'MECKLENBURG',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Middlesex County',
    value: 'MIDDLESEX',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Montgomery County',
    value: 'MONTGOMERY',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Nelson County',
    value: 'NELSON',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'New Kent County',
    value: 'NEW',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Northampton County',
    value: 'NORTHAMPTON',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Northumberland County',
    value: 'NORTHUMBERLAND',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Nottoway County',
    value: 'NOTTOWAY',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Orange County',
    value: 'ORANGE',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Page County',
    value: 'PAGE',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Patrick County',
    value: 'PATRICK',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Pittsylvania County',
    value: 'PITTSYLVANIA',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Powhatan County',
    value: 'POWHATAN',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Prince Edward County',
    value: 'PRINCE',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Prince George County',
    value: 'PRINCE',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Prince William County',
    value: 'PRINCE',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Pulaski County',
    value: 'PULASKI',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Rappahannock County',
    value: 'RAPPAHANNOCK',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Richmond County',
    value: 'RICHMOND',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Roanoke County',
    value: 'ROANOKE',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Rockbridge County',
    value: 'ROCKBRIDGE',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Rockingham County',
    value: 'ROCKINGHAM',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Russell County',
    value: 'RUSSELL',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Scott County',
    value: 'SCOTT',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Shenandoah County',
    value: 'SHENANDOAH',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Smyth County',
    value: 'SMYTH',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Southampton County',
    value: 'SOUTHAMPTON',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Spotsylvania County',
    value: 'SPOTSYLVANIA',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Stafford County',
    value: 'STAFFORD',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Surry County',
    value: 'SURRY',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Sussex County',
    value: 'SUSSEX',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Tazewell County',
    value: 'TAZEWELL',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Warren County',
    value: 'WARREN',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Westmoreland County',
    value: 'WESTMORELAND',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Wise County',
    value: 'WISE',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Wythe County',
    value: 'WYTHE',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'York County',
    value: 'YORK',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Alexandria, City of',
    value: 'ALEXANDRIA,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Bedford, City of',
    value: 'BEDFORD,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Bristol, City of',
    value: 'BRISTOL,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Buena Vista, City of',
    value: 'BUENA',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Charlottesville, City of',
    value: 'CHARLOTTESVILLE,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Chesapeake, City of',
    value: 'CHESAPEAKE,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Colonial Heights, City of',
    value: 'COLONIAL',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Covington, City of',
    value: 'COVINGTON,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Danville, City of',
    value: 'DANVILLE,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Emporia, City of',
    value: 'EMPORIA,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Fairfax, City of',
    value: 'FAIRFAX,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Falls Church, City of',
    value: 'FALLS',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Franklin, City of',
    value: 'FRANKLIN,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Fredericksburg, City of',
    value: 'FREDERICKSBURG,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Galax, City of',
    value: 'GALAX,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Hampton, City of',
    value: 'HAMPTON,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Harrisonburg, City of',
    value: 'HARRISONBURG,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Hopewell, City of',
    value: 'HOPEWELL,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Lexington, City of',
    value: 'LEXINGTON,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Lynchburg, City of',
    value: 'LYNCHBURG,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Manassas, City of',
    value: 'MANASSAS,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Manassas Park, City of',
    value: 'MANASSAS',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Martinsville, City of',
    value: 'MARTINSVILLE,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Newport News, City of',
    value: 'NEWPORT',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Norfolk, City of',
    value: 'NORFOLK,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Norton, City of',
    value: 'NORTON,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Petersburg, City of',
    value: 'PETERSBURG,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Poquoson, City of',
    value: 'POQUOSON,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Portsmouth, City of',
    value: 'PORTSMOUTH,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Radford, City of',
    value: 'RADFORD,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Richmond, City of',
    value: 'RICHMOND,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Roanoke, City of',
    value: 'ROANOKE,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Salem, City of',
    value: 'SALEM,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Staunton, City of',
    value: 'STAUNTON,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Suffolk, City of',
    value: 'SUFFOLK,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Virginia Beach, City of',
    value: 'VIRGINIA',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Waynesboro, City of',
    value: 'WAYNESBORO,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Williamsburg, City of',
    value: 'WILLIAMSBURG,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Winchester, City of',
    value: 'WINCHESTER,',
    // State: "Virginia",
    State: 'VA',
  },
  {
    County: 'Adams County',
    value: 'ADAMS',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Asotin County',
    value: 'ASOTIN',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Benton County',
    value: 'BENTON',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Chelan County',
    value: 'CHELAN',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Clallam County',
    value: 'CLALLAM',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Clark County',
    value: 'CLARK',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Columbia County',
    value: 'COLUMBIA',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Cowlitz County',
    value: 'COWLITZ',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Douglas County',
    value: 'DOUGLAS',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Ferry County',
    value: 'FERRY',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Franklin County',
    value: 'FRANKLIN',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Garfield County',
    value: 'GARFIELD',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Grant County',
    value: 'GRANT',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Grays Harbor County',
    value: 'GRAYS',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Island County',
    value: 'ISLAND',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'King County',
    value: 'KING',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Kitsap County',
    value: 'KITSAP',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Kittitas County',
    value: 'KITTITAS',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Klickitat County',
    value: 'KLICKITAT',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Lewis County',
    value: 'LEWIS',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Mason County',
    value: 'MASON',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Okanogan County',
    value: 'OKANOGAN',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Pacific County',
    value: 'PACIFIC',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Pend Oreille County',
    value: 'PEND',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Pierce County',
    value: 'PIERCE',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'San Juan County',
    value: 'SAN',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Skagit County',
    value: 'SKAGIT',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Skamania County',
    value: 'SKAMANIA',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Snohomish County',
    value: 'SNOHOMISH',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Spokane County',
    value: 'SPOKANE',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Stevens County',
    value: 'STEVENS',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Thurston County',
    value: 'THURSTON',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Wahkiakum County',
    value: 'WAHKIAKUM',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Walla Walla County',
    value: 'WALLA',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Whatcom County',
    value: 'WHATCOM',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Whitman County',
    value: 'WHITMAN',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Yakima County',
    value: 'YAKIMA',
    // State: "Washington",
    State: 'WA',
  },
  {
    County: 'Barbour County',
    value: 'BARBOUR',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Berkeley County',
    value: 'BERKELEY',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Boone County',
    value: 'BOONE',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Braxton County',
    value: 'BRAXTON',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Brooke County',
    value: 'BROOKE',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Cabell County',
    value: 'CABELL',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Calhoun County',
    value: 'CALHOUN',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Clay County',
    value: 'CLAY',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Doddridge County',
    value: 'DODDRIDGE',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Fayette County',
    value: 'FAYETTE',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Gilmer County',
    value: 'GILMER',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Grant County',
    value: 'GRANT',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Greenbrier County',
    value: 'GREENBRIER',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Hampshire County',
    value: 'HAMPSHIRE',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Hancock County',
    value: 'HANCOCK',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Hardy County',
    value: 'HARDY',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Harrison County',
    value: 'HARRISON',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Kanawha County',
    value: 'KANAWHA',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Lewis County',
    value: 'LEWIS',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Logan County',
    value: 'LOGAN',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'McDowell County',
    value: 'MCDOWELL',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Marion County',
    value: 'MARION',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Marshall County',
    value: 'MARSHALL',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Mason County',
    value: 'MASON',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Mercer County',
    value: 'MERCER',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Mineral County',
    value: 'MINERAL',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Mingo County',
    value: 'MINGO',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Monongalia County',
    value: 'MONONGALIA',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Monroe County',
    value: 'MONROE',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Morgan County',
    value: 'MORGAN',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Nicholas County',
    value: 'NICHOLAS',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Ohio County',
    value: 'OHIO',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Pendleton County',
    value: 'PENDLETON',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Pleasants County',
    value: 'PLEASANTS',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Pocahontas County',
    value: 'POCAHONTAS',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Preston County',
    value: 'PRESTON',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Putnam County',
    value: 'PUTNAM',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Raleigh County',
    value: 'RALEIGH',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Randolph County',
    value: 'RANDOLPH',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Ritchie County',
    value: 'RITCHIE',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Roane County',
    value: 'ROANE',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Summers County',
    value: 'SUMMERS',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Taylor County',
    value: 'TAYLOR',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Tucker County',
    value: 'TUCKER',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Tyler County',
    value: 'TYLER',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Upshur County',
    value: 'UPSHUR',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Wayne County',
    value: 'WAYNE',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Webster County',
    value: 'WEBSTER',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Wetzel County',
    value: 'WETZEL',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Wirt County',
    value: 'WIRT',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Wood County',
    value: 'WOOD',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Wyoming County',
    value: 'WYOMING',
    // State: "West Virginia",
    State: 'WV',
  },
  {
    County: 'Adams County',
    value: 'ADAMS',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Ashland County',
    value: 'ASHLAND',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Barron County',
    value: 'BARRON',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Bayfield County',
    value: 'BAYFIELD',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Brown County',
    value: 'BROWN',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Buffalo County',
    value: 'BUFFALO',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Burnett County',
    value: 'BURNETT',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Calumet County',
    value: 'CALUMET',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Chippewa County',
    value: 'CHIPPEWA',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Clark County',
    value: 'CLARK',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Columbia County',
    value: 'COLUMBIA',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Crawford County',
    value: 'CRAWFORD',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Dane County',
    value: 'DANE',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Dodge County',
    value: 'DODGE',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Door County',
    value: 'DOOR',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Douglas County',
    value: 'DOUGLAS',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Dunn County',
    value: 'DUNN',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Eau Claire County',
    value: 'EAU',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Florence County',
    value: 'FLORENCE',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Fond du Lac County',
    value: 'FOND',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Forest County',
    value: 'FOREST',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Grant County',
    value: 'GRANT',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Green County',
    value: 'GREEN',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Green Lake County',
    value: 'GREEN',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Iowa County',
    value: 'IOWA',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Iron County',
    value: 'IRON',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Jackson County',
    value: 'JACKSON',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Jefferson County',
    value: 'JEFFERSON',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Juneau County',
    value: 'JUNEAU',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Kenosha County',
    value: 'KENOSHA',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Kewaunee County',
    value: 'KEWAUNEE',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'La Crosse County',
    value: 'LA',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Lafayette County',
    value: 'LAFAYETTE',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Langlade County',
    value: 'LANGLADE',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Manitowoc County',
    value: 'MANITOWOC',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Marathon County',
    value: 'MARATHON',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Marinette County',
    value: 'MARINETTE',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Marquette County',
    value: 'MARQUETTE',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Menominee County',
    value: 'MENOMINEE',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Milwaukee County',
    value: 'MILWAUKEE',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Monroe County',
    value: 'MONROE',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Oconto County',
    value: 'OCONTO',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Oneida County',
    value: 'ONEIDA',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Outagamie County',
    value: 'OUTAGAMIE',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Ozaukee County',
    value: 'OZAUKEE',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Pepin County',
    value: 'PEPIN',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Pierce County',
    value: 'PIERCE',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Polk County',
    value: 'POLK',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Portage County',
    value: 'PORTAGE',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Price County',
    value: 'PRICE',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Racine County',
    value: 'RACINE',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Richland County',
    value: 'RICHLAND',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Rock County',
    value: 'ROCK',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Rusk County',
    value: 'RUSK',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'St. Croix County',
    value: 'ST.',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Sauk County',
    value: 'SAUK',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Sawyer County',
    value: 'SAWYER',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Shawano County',
    value: 'SHAWANO',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Sheboygan County',
    value: 'SHEBOYGAN',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Taylor County',
    value: 'TAYLOR',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Trempealeau County',
    value: 'TREMPEALEAU',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Vernon County',
    value: 'VERNON',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Vilas County',
    value: 'VILAS',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Walworth County',
    value: 'WALWORTH',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Washburn County',
    value: 'WASHBURN',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Washington County',
    value: 'WASHINGTON',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Waukesha County',
    value: 'WAUKESHA',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Waupaca County',
    value: 'WAUPACA',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Waushara County',
    value: 'WAUSHARA',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Winnebago County',
    value: 'WINNEBAGO',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Wood County',
    value: 'WOOD',
    // State: "Wisconsin",
    State: 'WI',
  },
  {
    County: 'Albany County',
    value: 'ALBANY',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Big Horn County',
    value: 'BIG',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Campbell County',
    value: 'CAMPBELL',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Carbon County',
    value: 'CARBON',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Converse County',
    value: 'CONVERSE',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Crook County',
    value: 'CROOK',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Fremont County',
    value: 'FREMONT',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Goshen County',
    value: 'GOSHEN',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Hot Springs County',
    value: 'HOT',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Johnson County',
    value: 'JOHNSON',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Laramie County',
    value: 'LARAMIE',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Lincoln County',
    value: 'LINCOLN',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Natrona County',
    value: 'NATRONA',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Niobrara County',
    value: 'NIOBRARA',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Park County',
    value: 'PARK',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Platte County',
    value: 'PLATTE',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Sheridan County',
    value: 'SHERIDAN',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Sublette County',
    value: 'SUBLETTE',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Sweetwater County',
    value: 'SWEETWATER',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Teton County',
    value: 'TETON',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Uinta County',
    value: 'UINTA',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Washakie County',
    value: 'WASHAKIE',
    // State: "Wyoming",
    State: 'WY',
  },
  {
    County: 'Weston County',
    value: 'WESTON',
    // State: "Wyoming",
    State: 'WY',
  },
];

const getCounties = (state: string) => {
  return counties
    .filter((county) => county.State === state)
    .map((county) => ({
      ...county,
      value: county.value,
      label: county.County,
    }));
};

export default getCounties;