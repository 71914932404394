import React, { useEffect } from "react";
import MapView from "@arcgis/core/views/MapView";
import CheckboxGroup from "../../../molecules/CheckboxGroup";
import { useForm } from "react-hook-form";
import Mortgage from "../../../../assets/images/Mortgage.png";
import UCC from "../../../../assets/images/reduced_risk_due_to_levee.png";
import {
  mortgageLayer,
  mortgageReleaseLayer,
  uccLayer,
} from "../../../../layers/Layers";

interface FormValues {
  checkboxes: string[];
}

const options = [
  {
    id: 1,
    label: (
      <div className="w-[100px] flex h-5">
        <img className="mr-2" src={Mortgage} alt="Mortgage" />
        Mortgage{" "}
      </div>
    ),
    value: "ParcelMortgagelayer",
  },
  {
    id: 2,
    label: (
      <div className="w-[100px] flex h-5">
        <img className="mr-2" src={UCC} alt="UCC" />
        UCC{" "}
      </div>
    ),
    value: "ParcelUCClayer",
  },
];

const FinancialServices = ({ view }: { view: MapView }) => {
  const { control, setValue, watch } = useForm<FormValues>({
    defaultValues: { checkboxes: [] },
  });

  const selectedValues = watch("checkboxes");

  useEffect(() => {
    mortgageLayer.visible = selectedValues.includes("ParcelMortgagelayer");
    mortgageReleaseLayer.visible = selectedValues.includes(
      "ParcelMortgagelayer"
    );
    uccLayer.visible = selectedValues.includes("ParcelUCClayer");
  }, [selectedValues]);

  return (
    <div className="w-[270px] bg-boundsPurple-700  p-3 rounded-[5px] flex gap-6 justify-between">
      <CheckboxGroup
        control={control}
        name="checkboxes"
        options={options}
        setValue={setValue}
        showAll={false}
        className="!flex-row"
      />
    </div>
  );
};

export default FinancialServices;
