import React from 'react';
import { OptionProps } from 'react-select';
import CheckIcon from '../../icons/Check.Icon';
import SquareIcon from '../../icons/Square.icon';
import { IOption } from '../../../types/search';

const CheckboxOption: React.FC<OptionProps<IOption, boolean>> = ({ innerProps, ...props }) => {
  return (
    <div className="flex items-center gap-2 px-4 py-1 w-full flex-1" {...innerProps}>
      {props.isMulti && (
        <div className="w-[18px]">
          {props.isDisabled ? null : props.isSelected
            ? <CheckIcon width={18} height={18} />
            : <SquareIcon width={18} height={18} />
          }
        </div>
      )}
      <span className="overflow-hidden text-ellipsis whitespace-nowrap max-w-full cursor-pointer">
        {props.label}
      </span>
    </div>
  );
};

export default CheckboxOption;
