import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IOption } from "../../../../types/search";
import { SelectComponent } from "../../../atoms/Select";
import useBoundsUI from "../../../../hooks/useBoundsUI";
import { Subscription } from "react-hook-form/dist/utils/createSubject";
import { Bar } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'; 
import { Tooltip } from "react-tooltip";
interface CropYieldData {
	YoY_change: number;
	crop: string;
	uom: string;
	value: number
	year: number;
	rgb?: string;
  }
  

const Yield = () => {
	const { control, watch, reset  } = useForm<{ crop: IOption }>();
	const [watcher, setWatcher] = useState<Subscription>();
	const [crop, setCrop] = useState<CropYieldData[] | null>();
	const [cropsArray, setCropsArray ] = useState<IOption[]>([]);
	
	const {
		insightData: { data, insightError },
		selection: {parcels}
	  } = useBoundsUI();
	  const isLoadingData = data === null;
	  let max = 0;
	  useEffect(() => {
		reset();
		setCrop(null);
		setCropsArray([]);
		watcher?.unsubscribe();
		if (data) {
			const crops = Array.from(new Set(data.map(item => item.crop)))
			crops.forEach((x)=> (
				setCropsArray(prevItems => [...prevItems, { 
					value: x,
					label: x?.charAt(0).toUpperCase() + x?.slice(1).toLowerCase()
				}])
			))
			setWatcher(
				watch((val) => {
				if (val.crop && val.crop.value) {
					const crop = val.crop.value;
					const entry = data?.filter((x) => x.crop === crop);
					if (entry) {
					setCrop(entry);
					entry.forEach((x) => {
						max = x.value > max ? x.value : max
					})
					} else {
					setCrop(null);
					}
				}
				})
			);
		}
	  }, [data]);

	return (
		<div className="min-w-[285px]">
			<div className="flex flex-row items-center ">
				<h2 className="text-[32px] mr-1">Yield</h2>
				<FontAwesomeIcon className="question-mark" icon={faCircleQuestion} />
				<Tooltip anchorSelect=".question-mark" place="bottom" offset={10}>
					Source: USDA
				</Tooltip>
			</div>	
			{insightError ?
				<p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6">
					{insightError}
				</p>
			: isLoadingData ? (
				<p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6">
				  Loading...
				</p>
			) : (data && data.length > 0 && parcels[parcels.length-1]?.county) ? 
				<div>
					<p className="border-t-[1px] border-boundsYellow-50 py-5 mt-6">
						{parcels[parcels.length-1].county ? parcels[parcels.length-1].county.charAt(0).toUpperCase() + parcels[parcels.length-1].county.slice(1).toLowerCase() : ''}, KS
					</p>
					<SelectComponent
						className={"mt-6"}
						name={"crop"}
						control={control}
						label={"Crop"}
						options={cropsArray}
						placeholder={"--Select one--"}
						closeMenuOnSelect={true}
					/>
				</div>
			: 
				<p className="border-t-[1px] border-boundsYellow-50 py-5 mt-6">
					Select an area to view data
				</p>
			}
			{crop && parcels[parcels.length-1]?.county ?
			<div>
				<div style={{ width: '400px', height: '300px' }}>
					<p style={{ padding: '20px' }}>{crop[0].uom ? crop[0].uom : ""} per harvest</p>
					<Bar
						style={{minHeight: "300px"}}
						data={{
							labels: crop?.map(item => item.year).sort((a, b) => a - b), 
							datasets: [
							{
								data: crop?.sort((a, b) => a.year - b.year).map(item => item.value),
								backgroundColor: crop[0].rgb ? "rgb(" + crop[0].rgb + ")" : "#FDD792",
								barPercentage: 0.35
							}
							]
						}}
						options={{
							maintainAspectRatio: false,
							layout: {
							padding: {
								right: 5
							}
							},
							plugins: {
								legend: {
									display: false
								},
								datalabels: {
									display: false 
								}
							},
							scales: {
							x: {
								title: {
								color: "white"
								},
								ticks: {
								color: "white",
								padding: 25,
								autoSkip: false
								}
							},
							y: {
								title: {
								color: "white"
								},
								beginAtZero: true,
								ticks: {
								color: "white",
								padding: 15,
								maxTicksLimit: 5
								},
								grace: max * 0.2
							},
							},
						}}
						/>
					</div>
					<div className="bg-boundsPurple-150 rounded-md py-2 px-4 mt-20">
						<table style = {{width: '100%', textAlign: 'center'}} className="table-auto">
							<thead>
							<tr>
								<th className="pr-3">Year</th>
								<th className="pr-3">{crop[0].uom ? crop[0].uom : ""}</th>
								<th className="pr-3">YOY % change</th>
							</tr>
							</thead>
							<tbody>
							{crop?.sort((a, b) => b.year - a.year).map((x, i) => (
								<tr key={i}>
									<td>{x.year}</td>
									<td>{Math.round(x.value * 10) / 10}</td>
									<td>{Math.round(x.YoY_change)}%</td>
								</tr> 
							)
							)}
							</tbody>
						</table>
						</div>
					</div>
					: '' }
				</div>
	);
};

export default Yield;
